export type FileFormat = "PDF" | "CSV" | "WORD" | "EXCEL" | "JSON"

export const extensionFor = (fileFormat: FileFormat): string => {
    switch (fileFormat) {
        case "PDF":
            return "pdf";
        case "WORD":
            return "docx";
        case "JSON":
            return "json";
        case "CSV":
            return "csv";
        case "EXCEL":
            return "xlsx";
        default:
            throw new Error("File type: " + fileFormat + " not recognised!");
    }
}