import {Tabs} from "antd";
import React, {useEffect, useState} from "react";
import {ViewOrder} from "./Tabs/ViewOrder/viewOrder";
import {CreateOrder} from "./Tabs/CreateOrder/createOrder";
import {Link, useParams} from "react-router-dom";
import {CreateMenuOrder} from "./Tabs/CreateMenuOrder/createMenuOrder";

export const Orders = (): React.JSX.Element => {

    const params = useParams()
    const [activeTab, setActiveTab] = useState<string>()
    const [refreshViewTab, setRefreshViewTab] = useState<boolean>(true)

    useEffect(() => {
        let action = params.action;
        if (action !== undefined) {
            if (action === "view") {
                setActiveTab("1")
            } else if (action === "create") {
                setActiveTab("2")
            } else if (action === "create-menu") {
                setActiveTab("3")
            }
        }
    }, [params])

    const tab1 = {
        label:
            <Link to={"/orders/view"} style={{color: "inherit", textDecoration: "none"}}>
                <span className={"h2"}>
                    Vizualizare comenzi
                </span>
            </Link>,
        key: "1",
        children: <ViewOrder refresh={refreshViewTab}/>
    }

    const tab2 = {
        label:
            <Link to={"/orders/create"} style={{color: "inherit", textDecoration: "none"}}>
                <span className={"h2"}>
                    Creare comenzi
                </span>
            </Link>
        ,
        key: "2",
        children: <CreateOrder/>
    }

    const tab3 = {
        label:
            <Link to={"/orders/create-menu"} style={{color: "inherit", textDecoration: "none"}}>
                <span className={"h2"}>
                    Comandă meniu
                </span>
            </Link>
        ,
        key: "3",
        children: <CreateMenuOrder/>
    }

    return (
        <div id={"orders"}
             className={"padding-responsive"}
        >
            <Tabs activeKey={activeTab}
                  items={
                      [tab1, tab2, tab3]
                  }
                  animated={{inkBar: true, tabPane: false}}
                  onChange={(tab) => {
                      if (tab === "1") {
                          setRefreshViewTab(!refreshViewTab)
                      }
                  }}
            />
        </div>
    )
}