import './styles.scss';
import React, {useContext, useEffect, useState} from "react";
import {Button, Divider, Empty, message, Popconfirm, Skeleton, Spin} from "antd";
import {ServiceContext, ServiceContextInstance} from "../../Core/serviceContext";
import {IFetchResult} from "../../Hooks/useFetch.types";
import {useFetch} from "../../Hooks/useFetch";
import {Provider} from "../../Models/backend/Provider";
import {useNavigate} from "react-router-dom";
import {ShowProviderDetailsModal} from "./showProviderDetailsModal";
import {DeletionsForProductResponse} from "../../Models/model/DeletionsForProductResponse";

export const Providers = (): React.JSX.Element => {
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [providers, setProviders] = useState<Provider[]>([]);
    const [isProviderModalOpen, setIsProviderModalOpen] = useState(false);

    const [loadedData, setLoadedData] = useState(false)

    const navigate = useNavigate()
    const providersData: IFetchResult<Provider[]> = useFetch<Provider[]>(() => services.ProviderService.getAll(), []);

    const [selectedProvider, setSelectedProvider] = useState<Provider>()

    const [possibleDeletions, setPossibleDeletions] = useState<DeletionsForProductResponse | undefined>();

    useEffect(() => {
        if (providersData.isLoading) {
            return;
        }
        if (providersData.errors !== '' ||
            providersData.data === null ||
            providersData.data?.Error !== undefined ||
            providersData.data?.Data === undefined) {
            return;
        }
        let data = providersData.data.Data;
        setProviders(data)
        setLoadedData(true);
    }, [providersData])


    const openNewProviderModal = () => {
        setIsProviderModalOpen(true);
    };


    async function deleteProvider(id: string) {
        let response = await services.ProviderService.deleteProvider(id);
        if (response.Error !== undefined) {
            message.warning("Furnizorul nu a putut fi sters")
            return
        }
        message.success("Furnizorul a fost sters cu succes")
        let providers1 = providers.filter(p => p.id! !== id);
        setProviders(providers1)
    }

    async function findPossibleDeletions(id: string): Promise<void> {
        let response = await services.ProviderService.itemsThatMightGetDeleted(id);
        if (response.Error !== undefined) {
            message.warning("Nu s-au putut găsi produsele posibile de sters")
            return
        }
        setPossibleDeletions(response.Data)
    }

    function goTo(providerId: string) {
        navigate("/items/" + providerId)
    }

    function showProviderDetails(provider: Provider) {
        setSelectedProvider(provider)
        setIsProviderModalOpen(true)
    }

    useEffect(() => {
        if (!isProviderModalOpen) {
            setSelectedProvider(undefined)
        }
    }, [isProviderModalOpen])

    return (
        <>
            {
                !loadedData ? (
                        <div className={"padding-responsive"}>
                            <Skeleton paragraph={{rows: 6}} active/>
                            <Skeleton paragraph={{rows: 3}} active/>
                            <Skeleton paragraph={{rows: 4}} active/>
                        </div>
                    ) :
                    (
                        <div
                            id={"providers"}
                            className={"padding-responsive"}
                        >
                            <div className={"d-flex align-providers-center justify-content-between mb-3"}>
                                <div className={"h2 me-3"}>
                                    Furnizori
                                </div>
                                <Button type={"primary"} size={"large"} onClick={openNewProviderModal}>
                                    Adaugă <i className="fa-solid fa-plus ms-2"></i>
                                </Button>
                            </div>
                            <Divider className={"mt-0"}/>
                            <div>
                                <div className={"d-flex bg-white mb-2 text-center"} style={{fontSize: "1.2rem"}}>
                                    <div className={"col-md-1 p-2 border"}>

                                    </div>
                                    <div className={"col-md-4 p-2 border"}>
                                        Nume
                                    </div>
                                    <div className={"col-md-2 p-2 border"}>
                                        Număr produse
                                    </div>
                                    <div className={"col-md-4 p-2 border"}>
                                        Ultima comandă
                                    </div>
                                    <div className={"col-md-1 p-2 border"}>
                                        Șterge
                                    </div>
                                </div>
                                {
                                    providers.length === 0 && <Empty description={"Nu sunt furnizori adăugați"}/>
                                }

                                <div className={"provider-content"}>
                                    {
                                        providers.length > 0 && providers.map((p, index) => (
                                            <div key={index}
                                                 className={"d-flex rounded bg-white provider-item"}
                                            >
                                                <div className={"wrapper"}>
                                                    <div className={"sign"}></div>
                                                </div>
                                                <div className={"col-md-1 border pointer"} style={{overflow: "hidden"}}>
                                                    <div onClick={() => goTo(p.id!)}
                                                         style={{width: "100%", height: "100%"}}
                                                         className={"d-flex align-items-center justify-content-center hover-item"}
                                                    >
                                                        <i className="fa-solid fa-right-long fa-2x"></i>
                                                    </div>
                                                </div>
                                                <div className={"col-md-4 p-2 border pointer"}
                                                     onDoubleClick={() => showProviderDetails(p)}
                                                >
                                                    {p.name} <i className="fa-solid fa-arrow-up-right-from-square"
                                                                onClick={() => showProviderDetails(p)}
                                                                title={"Informații furnizor"}></i>
                                                </div>
                                                <div className={"col-md-2 p-2 border"}>
                                                    {p.products?.length}
                                                </div>
                                                <div className={"col-md-4 p-2 border"}>
                                                    {p.lastOrderTimestamp ?? "<n/a>"}
                                                </div>
                                                <div className={"col-md-1 p-2 border pointer"}>
                                                    <Popconfirm
                                                        placement={"topLeft"}
                                                        title="Stergere furnizor"
                                                        description={
                                                            <div>
                                                                <div>
                                                                    Vrei să ștergi {p.name}?
                                                                </div>
                                                                <div style={{width: "350px"}}>
                                                                    <div>
                                                                        {possibleDeletions === undefined && <Spin/>}
                                                                        {possibleDeletions !== undefined && (possibleDeletions.menuProducts.length > 0 || possibleDeletions.menus.length > 0) && (
                                                                            <div>
                                                                                <div>
                                                                                    Vor fi șterse produsele, preparatele
                                                                                    create cu aceste produse și meniurile
                                                                                    respective.
                                                                                </div>
                                                                                <div>
                                                                                    Preparatele
                                                                                </div>
                                                                                <div>
                                                                                    <ul>
                                                                                        {possibleDeletions.menuProducts.map((menuProduct, index) => (
                                                                                            <li key={index}>{menuProduct}</li>
                                                                                        ))}
                                                                                    </ul>
                                                                                </div>
                                                                                <div>
                                                                                    Meniurile
                                                                                </div>
                                                                                <div>
                                                                                    <ul>
                                                                                        {possibleDeletions.menus.map((menu, index) => (
                                                                                            <li key={index}>{menu}</li>
                                                                                        ))}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        }
                                                        onConfirm={() => deleteProvider(p.id!)}
                                                        onCancel={() => {
                                                        }}
                                                        okText="Da"
                                                        cancelText="Nu"
                                                        onOpenChange={(open) => {
                                                            if (!open) {
                                                                setPossibleDeletions(undefined)
                                                            } else {
                                                                findPossibleDeletions(p.id!)
                                                            }
                                                        }}
                                                    >
                                                        <div style={{width: "100%"}} className={"hover-item"}>
                                                            <i className="fa-solid fa-trash"></i>
                                                        </div>
                                                    </Popconfirm>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={"mt-3"}>
                                    <i className="fa-solid fa-circle-info fa-lg"></i>
                                    <span className={"ms-2"}>
                                        Dublu click pe numele furnizorului pentru a modifica informațiile.
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
            }
            <ShowProviderDetailsModal setIsModalOpen={setIsProviderModalOpen}
                                      isModalOpen={isProviderModalOpen}
                                      providers={providers}
                                      provider={selectedProvider}
            />
        </>

    )
}