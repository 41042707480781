export function twoDecimals(num: number | undefined): number {
    if (num === undefined || num === null) {
        return 0.00
    }
    return Number(num.toFixed(2))
}

export function generateRandomNumber(n: number): number {
    const min = Math.pow(10, n - 1);
    const max = Math.pow(10, n) - 1;
    return Math.floor(Math.random() * (max - min + 1)) + min;
}