import {ServiceContext, ServiceContextInstance} from "../../../../Core/serviceContext";
import React, {useContext, useEffect, useState} from "react";
import {IFetchResult} from "../../../../Hooks/useFetch.types";
import {useFetch} from "../../../../Hooks/useFetch";
import {Provider} from "../../../../Models/backend/Provider";
import {Select} from "antd";
import {TableWithCart} from "./tableWithCart";

export const CreateOrder = (): JSX.Element => {
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const providersData: IFetchResult<Provider[]> = useFetch<Provider[]>(() => services.ProviderService.getAll(), []);
    const [providers, setProviders] = useState<Provider[]>([])
    const [selectedProviderId, setSelectedProviderId] = useState<string>()
    const [provider, setProvider] = useState<Provider>()

    useEffect(() => {
        if (providersData.isLoading) {
            return;
        }
        if (providersData.errors !== '' ||
            providersData.data === null ||
            providersData.data?.Error !== undefined ||
            providersData.data?.Data === undefined) {
            return;
        }
        let data = providersData.data.Data;
        setProviders(data)
    }, [providersData])

    useEffect(() => {
        let selectedProvider = providers.filter(p => p.id === selectedProviderId);
        setProvider(selectedProvider[0])
    }, [selectedProviderId])

    return (
        <div id={"create-order-div"}>
            <div className={"d-flex align-items-center mb-3"}>
                <Select options={providers.map(p => {
                    return {
                        label: p.name,
                        value: p.id
                    }
                })}
                        placeholder={"Selecteaza furnizorul"}
                        style={{width: "500px"}}
                        onChange={(value, option) => {
                            setSelectedProviderId(value)
                        }}
                />
            </div>
            <TableWithCart order={
                {
                    nir: 0,
                    providerName: provider?.name!,
                    primeProducts: [],
                    name: "",
                    hasReceipt: false
                }
            }
                           allProducts={provider?.products}
            />
        </div>
    )
}
