import {APIHelper} from "../Configuration/apiHelper";
import {IResponse} from "../Models/IResponse";
import {MenuProduct} from "../Models/backend/MenuProduct";
import {StringTuple} from "../Models/Tuple";
import {MenuProductsSortOrder} from "../Enums/MenuProductsSortOrder";
import {DeletionsForProductResponse} from "../Models/model/DeletionsForProductResponse";

export default class MenuProductService {

    constructor(protected readonly _endpoint: string) {

    };

    async getAll(sortBy?: MenuProductsSortOrder): Promise<IResponse<MenuProduct[]>> {
        let params = '';
        if (sortBy !== undefined) {
            const name = MenuProductsSortOrder[sortBy];
            params = `?sort=${name}`
        }
        return await APIHelper.request(`${this._endpoint}${params}`, 'GET')
    };

    async create(menuProduct: MenuProduct): Promise<IResponse<MenuProduct>> {
        return await APIHelper.request(
            `${this._endpoint}`,
            'POST',
            JSON.stringify(menuProduct),
            false
        )
    };


    async update(menuProduct: MenuProduct): Promise<IResponse<MenuProduct>> {
        let primeProducts = menuProduct.primeProducts;
        let list: StringTuple[] = primeProducts.map(c => {
            return {
                first: c.orderProduct!.id!,
                second: c.quantity!.toString()
            }
        });
        const request = {
            name: menuProduct.name,
            description: menuProduct.description,
            price: menuProduct.price,
            media: menuProduct.media,
            primeProducts: list

        }
        return await APIHelper.request(
            `${this._endpoint}/${menuProduct.id}`,
            'PUT',
            JSON.stringify(request),
            false
        )
    };


    async delete(id: string): Promise<IResponse<void>> {
        return await APIHelper.request(
            `${this._endpoint}/${id}`,
            'DELETE')
    }

    async findPossibleMenuDeletions(id: string): Promise<IResponse<DeletionsForProductResponse>> {
        return await APIHelper.request(
            `${this._endpoint}/related-to/${id}`,
            'GET')
    }
};