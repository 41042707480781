import {Menu} from "../../../Models/backend/Menu";
import {Card, message, Popconfirm, Tooltip} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {MenuCardContent} from "./menuCardContent";
import {Dispatch, SetStateAction, useContext} from "react";
import {ServiceContext, ServiceContextInstance} from "../../../Core/serviceContext";

export const MenuCard = (props: {
    menu: Menu;
    index: number;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    setOperation: Dispatch<SetStateAction<(menu: Menu) => Promise<boolean>>>;
    setModalType: Dispatch<SetStateAction<"create" | "update">>;
    setSelectedMenuToUpdate: Dispatch<SetStateAction<Menu | undefined>>;
    refreshMenus: () => void;
}): JSX.Element => {
    const services: ServiceContext = useContext(ServiceContextInstance);

    async function deleteMenu() {
        let response = await services.MenuService.delete(props.menu.id!);
        if (response.Error !== undefined || response.Data === undefined) {
            message.error("Nu s-a putut sterge meniul")
            return
        }
        message.success("Meniul a fost sters!")
        props.refreshMenus()
    }

    async function updateMenu(menu: Menu):Promise<boolean> {
        let response = await services.MenuService.update(menu);
        if (response.Error !== undefined || response.Data === undefined) {
            message.error("Nu s-a putut modifica meniul")
            return false
        }
        message.success("Meniul a fost modificat!")
        return true
    }

    function f() {
        props.setSelectedMenuToUpdate(props.menu)
    }

    return (
        <Tooltip
            title={
                tooltipTitle(
                    props.setShowModal,
                    deleteMenu,
                    props.setOperation,
                    services,
                    props.setModalType,
                    f,
                    updateMenu
                )
            }
        >
            <Card size="small"
                  title={
                      <div>
                          <span style={{fontSize: "1.2rem"}}>
                              {props.menu.name}
                          </span>
                      </div>
                  }
                  extra={<span>pret: {props.menu.totalPrice} ron</span>}
                  className={"shadow"}
            >
                <MenuCardContent menu={props.menu} index={props.index}/>
            </Card>
        </Tooltip>
    )
}

function tooltipTitle(
    setShowModal: (value: (((prevState: boolean) => boolean) | boolean)) => void,
    deleteItem: () => Promise<void>,
    setOperation: Dispatch<SetStateAction<(menu: Menu) => Promise<boolean>>>,
    services: ServiceContext,
    setModalType: Dispatch<SetStateAction<"create" | "update">>,
    selectProduct: () => void,
    updateMenu: (menu: Menu) => Promise<boolean>
) {

    function showModal() {
        setOperation(
            () => {
                return (menu: Menu): Promise<boolean> => {
                    return updateMenu(menu)
                }
            }
        )
        setModalType("update")
        selectProduct()
        setShowModal(true)
    }


    return <div>
        <i className="fa-solid fa-square-pen fa-2x pointer me-3 hover-btn-primary"
           onClick={showModal}></i>
        <Popconfirm
            title="Sterge meniul"
            description="Vrei să ștergi meniul?"
            icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
            okText={"Da"}
            cancelText={"Nu"}
            onConfirm={deleteItem}
        >
            <i className="fa-solid fa-trash fa-2x pointer hover-btn-danger"></i>
        </Popconfirm>
    </div>;
}