import {APIHelper} from "../Configuration/apiHelper";
import {IResponse} from "../Models/IResponse";
import {LoginResponse} from "../Models/model/LoginResponse";
import bcrypt from 'bcryptjs';

export default class AuthService {

    constructor(protected readonly _endpoint: string) {

    };

    async checkAuth(): Promise<IResponse<LoginResponse>> {
        return await APIHelper.request(`${this._endpoint}/token/refresh`, 'GET', "", false, localStorage.getItem("refresh_token")!)
    };

    async changePassword(oldPasswordPlain: string, newPasswordPlain: string): Promise<IResponse<string>> {
        const formData: FormData = new FormData();
        let salt = bcrypt.genSaltSync(10);
        let encodeNew = bcrypt.hashSync(newPasswordPlain, salt);
        formData.append("old", oldPasswordPlain)
        formData.append("new", encodeNew)
        return await APIHelper.request(`${this._endpoint}/changePassword`, 'PUT', formData, true)
    };

};