import {MenuProduct} from "../../../Models/backend/MenuProduct";
import {MenuProductsSortOrder} from "../../../Enums/MenuProductsSortOrder";

export const sortType = [
    {
        label: "dupa nume",
        value: MenuProductsSortOrder.NAME
    }, {
        label: "dupa pret crescator",
        value: MenuProductsSortOrder.PRICE_ASC
    },
    {
        label: "dupa pret descrescator",
        value: MenuProductsSortOrder.PRICE_DESC
    }
]

export function calculateProductsPrice(selectedMenuProducts: MenuProduct[]) {
    if (selectedMenuProducts && selectedMenuProducts.length > 0) {
        return selectedMenuProducts
            .map(el => el.price)
            .reduce((accumulator, currentValue) => accumulator + currentValue)
    }
    return 0
}