import {Button, Tag} from "antd";

export const PricingCard = (props: {
    features: string[],
    price: number,
    currency: string,
    monthly?: boolean,
    typeTitle: string,
    onClick?: () => void
}): JSX.Element => {
    return (
        <div className="pricing-table">
            <div className="pricing-table-inner">
                <div className="pricing-table-main">
                    <div className="pricing-table-header is-revealing mb-3">
                        <div className="pricing-table-title mt-12 mb-8">{props.typeTitle}</div>
                        <div className="pricing-table-price mb-32 pb-24">
                            <span className="pricing-table-price-amount h2">{props.price} <span
                                className=" h4">{props.currency}</span></span>{props.monthly && "/lună"}
                        </div>
                    </div>
                    <ul className="pricing-table-features list-reset text-xs">
                        {
                            props.features.map((feat, index) => (
                                <li className="is-revealing" key={index}>
                                    <span className="list-icon">
                                        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="#87d068" fillRule="nonzero"
                                                  d="M5.6 8.4L1.6 6 0 7.6 5.6 14 16 3.6 14.4 2z"/>
                                        </svg>
                                    </span>
                                    <span>{feat}</span>
                                </li>
                            ))
                        }
                        <li className="is-revealing">
                            <Tag color="#87d068">în curând</Tag> <span>versiune pentru telefon</span>
                        </li>
                    </ul>
                </div>
                <Button size={"large"}
                        type={"primary"}
                        className={"w-100 mt-auto"}
                        onClick={props.onClick}
                >
                    Contactează-ne
                </Button>
            </div>
        </div>
    )
}