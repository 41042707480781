import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {MenuProduct} from "../../../Models/backend/MenuProduct";
import {Button, Card, Input, InputNumber, Modal, Select, Skeleton, Spin, Tooltip} from "antd";
import Checkbox from "antd/es/checkbox/Checkbox";
import {ServiceContext, ServiceContextInstance} from "../../../Core/serviceContext";
import {IFetchResult} from "../../../Hooks/useFetch.types";
import {useFetch} from "../../../Hooks/useFetch";
import {Menu} from "../../../Models/backend/Menu";
import {calculateProductsPrice, sortType} from "./utils";
import {MenuProductsSortOrder} from "../../../Enums/MenuProductsSortOrder";

const {TextArea} = Input;
export const MenuModalOperation = (
    props: {
        showModal: boolean;
        setShowModal: Dispatch<SetStateAction<boolean>>;
        menu?: Menu;
        type: "create" | "update";
        operation: (menu: Menu) => Promise<boolean>;
        setMenu: Dispatch<SetStateAction<Menu | undefined>>;
        refreshMenus: () => void;
    }
): JSX.Element => {

    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [btnLoading, setBtnLoading] = useState<boolean>(false)

    const [menuPrice, setMenuPrice] = useState<number>()
    const [sortBy, setSortBy] = useState<MenuProductsSortOrder>(MenuProductsSortOrder.NAME)
    const [enabledButtonCrateMenu, setEnabledButtonCrateMenu] = useState<boolean>(false)
    const [numberOfProducts, setNumberOfProducts] = useState<number>(0)

    const [selectedMenuProducts, setSelectedMenuProducts] = useState<MenuProduct[]>([])

    const [menuName, setMenuName] = useState<string>()
    const [menuDescription, setMenuDescription] = useState<string>()

    const menuProductsData: IFetchResult<MenuProduct[]> = useFetch<MenuProduct[]>(() => services.MenuProductService.getAll(sortBy), [sortBy]);
    const [menuProducts, setMenuProducts] = useState<MenuProduct[]>([])
    const [loadingProducts, setLoadingProducts] = useState<boolean>(true)

    function setUpMenu() {
        if (props.menu === undefined) {
            return
        }
        // update selected menu items
        setSelectedMenuProducts(props.menu.items ?? [])

        // updated values in modal
        setMenuName(props.menu.name)
        setMenuDescription(props.menu.description)
        setMenuPrice(props.menu.totalPrice ?? 0)
    }

    useEffect(() => {
        setUpMenu();
    }, [props.menu])

    function resetAndClose() {
        setMenuName(undefined)
        setMenuDescription(undefined)
        setMenuPrice(undefined)
        setSelectedMenuProducts([])
        props.setShowModal(false)
        if (props.setMenu !== undefined) {
            props.setMenu(undefined)
        }
    }

    useEffect(() => {
        if (menuProductsData.isLoading) {
            return;
        }
        if (menuProductsData.errors !== '' ||
            menuProductsData.data === null ||
            menuProductsData.data?.Error !== undefined ||
            menuProductsData.data?.Data === undefined) {
            return;
        }
        let data = menuProductsData.data.Data;
        setMenuProducts(data)
        setLoadingProducts(false)
        setNumberOfProducts(data.length)
    }, [menuProductsData])

    useEffect(() => {
        setMenuPrice(calculateProductsPrice(selectedMenuProducts))
    }, [selectedMenuProducts])

    function manageElementFromMenuProducts(el: MenuProduct) {
        let menuProducts2 = [];
        if (selectedMenuProducts.map(el => el.id).includes(el.id)) {
            menuProducts2 = selectedMenuProducts.filter((el1) => el1.id !== el.id);
        } else {
            menuProducts2 = [...selectedMenuProducts, el];
        }
        setMenuPrice(calculateProductsPrice(selectedMenuProducts))
        setSelectedMenuProducts(menuProducts2)
    }


    useEffect(() => {
        if (menuName && menuName.trim().length > 0 &&
            menuDescription && menuDescription.trim().length > 0 &&
            (menuPrice ?? 0) > 0) {
            setEnabledButtonCrateMenu(true)
        } else {
            setEnabledButtonCrateMenu(false)
        }
    }, [menuName, menuDescription, menuPrice])

    function computeMenu(): Menu {
        let menu: Menu = {
            name: menuName!,
            description: menuDescription!,
            totalPrice: Number(menuPrice),
            items: selectedMenuProducts
        };
        if (props.type === "create") {
            return menu
        } else {
            menu.id = props.menu?.id
            return menu
        }
    }

    return (
        <Modal title={`${props.type === "create" ? "Creare meniu" : "Actualizare meniu"}`}
               open={props.showModal}
               onOk={() => resetAndClose()}
               onCancel={() => resetAndClose()}
               footer={null}
               width={"85%"}
        >
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <Tooltip title={"Numele meniului"}
                             placement={"topRight"}
                    >
                        <Input placeholder={"Nume meniu"}
                               className={"mb-3"}
                               value={menuName}
                               onChange={(e) => setMenuName(e.target.value)}
                        />
                    </Tooltip>
                    <Tooltip title={"Descrierea meniului"}
                             placement={"topRight"}
                    >
                        <TextArea placeholder={"Descriere meniu"}
                                  rows={4}
                                  value={menuDescription}
                                  onChange={(e) => setMenuDescription(e.target.value)}
                                  className={"mb-3"}
                        />
                    </Tooltip>
                    <Tooltip title={"Pretul meniului"}
                             placement={"topRight"}
                    >
                        <InputNumber placeholder={"Pretul meniului"}
                                     className={"w-100"}
                                     type={"number"}
                                     min={0}
                                     readOnly={true}
                                     value={menuPrice}
                                     onChange={(e) => setMenuPrice(e!)}
                        />
                    </Tooltip>
                    <div className={"mt-3"}>Produse selectate:</div>
                    <ul>
                        {
                            selectedMenuProducts.map((el, index) => (
                                <li key={index}>{el.name}</li>
                            ))
                        }
                    </ul>
                    <Tooltip title={
                        !enabledButtonCrateMenu &&
                        "Completeaza toate datele si selecteaza cel putin un preparat!"
                    }
                    >
                        <Button type={"primary"}
                                loading={btnLoading}
                                onClick={async () => {
                                    setBtnLoading(true)
                                    let success = await props.operation(computeMenu());
                                    setBtnLoading(false)
                                    if (success) {
                                        props.refreshMenus()
                                        resetAndClose()
                                    }
                                }}
                                disabled={!enabledButtonCrateMenu}
                        >
                            {props.type === "create" ? "Creeaza " : "Actualizeaza"} meniul
                        </Button>
                    </Tooltip>

                </div>
                <div className={"col-md-6"}>
                    <div className={"d-flex align-items-center mb-3"}>
                        <div className={"me-3"}>
                            Sortare:
                        </div>
                        <Select
                            options={sortType}
                            value={sortBy}
                            style={{width: "200px"}}
                            onChange={(e) => {
                                setSortBy(e)
                                setLoadingProducts(true)
                            }}
                        />
                    </div>
                    {
                        !loadingProducts &&
                        menuProducts.map((el, index) => (
                            <Card title={
                                <div className={"d-flex justify-content-between"}>
                                    <div className={"d-flex align-items-center"}>
                                        <Checkbox className={"me-2"}
                                                  onClick={() => manageElementFromMenuProducts(el)}
                                                  checked={selectedMenuProducts.map(el => el.id!).includes(el.id!)}
                                        />
                                        <div>{el.name}</div>
                                    </div>
                                    <div>
                                        pret: {el.price}
                                    </div>
                                </div>
                            }
                                  size={"small"}
                                  key={index}
                                  className={"border border-1 mb-2"}
                                  bodyStyle={{padding: "0rem 0rem 0rem 1rem"}}
                            >
                                <div>
                                    <TextArea
                                        id={`text-area-for-display-items-${el.id}`}
                                        defaultValue={el.description}
                                        style={{
                                            marginTop: "1px",
                                            border: "none",
                                            overflowY: "auto"
                                        }}
                                        readOnly
                                    />
                                </div>
                            </Card>
                        ))
                    }
                    {
                        loadingProducts && (
                            Array.from({length: numberOfProducts}).map((el, index) => (
                                <Skeleton active key={index} paragraph={{rows: 1}} className={"mb-3"}/>
                            ))
                        )
                    }
                </div>
            </div>
        </Modal>
    )
}