export const LOGIN_LINK: string = "/login";
export const HOME_LINK: string = "/";
export const TERMS_AND_CONDITIONS: string = "/termenii-si-conditiile";
export const CONFIDENTIALITY_POLICY: string = "/politica-de-confidentialitate";
export const DASHBOARD_LINK: string = "/dashboard";
export const ITEMS_LINK: string = "/items";
export const PRODUCTS_LINK: string = "/items/:providerId";
export const MENUS_LINK: string = "/menus";
export const MENU_PRODUCTS_LINK: string = "/menus/products";
export const ORDERS_LINK: string = "/orders";
export const RECEIPTS_LINK: string = "/receipts";
export const STATS_LINK: string = "/stats";
export const PASSWORD_RESET_LINK: string = "/password-reset";
export const CONTACT_LINK: string = "/contact";
export const EXPIRED_ACCOUNT_LINK: string = "/expired-account"
