import './scss/style.scss'
//@ts-ignore
import logoPng from './../../Assets/Images/logo-no-background.png';
//@ts-ignore
import logoWhitePng from './../../Assets/Images/logo-white.png';
//@ts-ignore
import monitor from './../../Assets/Images/monitor.png';
import {Button, Form, Input, notification, Space} from "antd";
import {Contact} from "../Contact/contact";
import {PricingCard} from "./Pricing/pricingCard";
import priceTexts from "./pricingTexts.json";
import {FeatureCard} from "./Features/featureCard";
import {useContext, useState} from "react";
import {ServiceContext, ServiceContextInstance} from "../../Core/serviceContext";
import {mergeStyles} from "@fluentui/react";
import {CONFIDENTIALITY_POLICY, LOGIN_LINK, TERMS_AND_CONDITIONS} from "../../Enums/pagesUrls";

export const HomePage = (): React.JSX.Element => {

    const [form] = Form.useForm();

    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [contactMessage, setContactMessage] = useState<string>()
    const [testEmail, setTestEmail] = useState<string>()
    const [createDemoAccLoading, setCreateDemoAccLoading] = useState<boolean>(false)

    const [api, contextHolder] = notification.useNotification();

    async function sendEmailToCustomer() {
        if (testEmail === undefined || testEmail.trim() === "") {
            return
        }
        services.UserService.createDemoAccount(testEmail);
        api.open({
            message: 'Ai aplicat cu succes',
            description: 'Urmeaza sa primesti un email cu datele de conectare. Daca nu il primesti in 5 minute, te rugam sa ne contactezi.',
        });
        setTestEmail("")
        form.resetFields()
    }

    const styleApplicationField = mergeStyles({
        width: "500px",
        '@media screen and (max-width: 768px)': {
            width: "100%",
            margin: "0px !important",
        },
    });
    const styleImg = mergeStyles({
        '@media screen and (max-width: 768px)': {
            margin: "auto"
        },
    });

    return (
        <div id={"home-page"}>
            {contextHolder}
            <div className="body-wrap boxed-container">
                <header className="site-header">
                    <div className="container">
                        <div className="site-header-inner">
                            <div className="brand header-brand">
                                <h1 className="m-0">
                                    <a href="#">
                                        <img src={logoPng} alt="" style={{width: "150px"}}/>
                                    </a>
                                </h1>
                            </div>
                        </div>
                    </div>
                </header>

                <main>
                    <section className="hero">
                        <div className="container">
                            <div className="hero-inner">
                                <div className="hero-copy">
                                    <h1 className="hero-title h2-mobile mt-0 is-revealing">
                                        Gestionarea comenzilor
                                    </h1>
                                    <p className="hero-paragraph is-revealing">
                                        Gestioneaza comenzile către furnizori într-un mediu digital, obține comenzi
                                        pentru meniuri în câteva click-uri.
                                    </p>
                                    <div className={"d-flex align-items-center justify-content-evenly"}>
                                        <Button size={"large"}
                                                type={"primary"}
                                                className={"shadow px-3"}
                                        >
                                            <a href={"#about"} style={{textDecoration: "none"}}>
                                                Descoperă aplicația
                                            </a>
                                        </Button>
                                        <Button size={"large"}
                                                type={"primary"}
                                                className={"shadow px-3"}
                                        >
                                            <a href={"#free-test"} style={{textDecoration: "none"}}>
                                                Testează gratuit
                                            </a>
                                        </Button>
                                    </div>
                                </div>
                                <div className="hero-illustration is-revealing">
                                    <svg width="528" height="413" viewBox="0 0 528 413"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <linearGradient x1="50%" y1="100%" x2="50%" y2="0%"
                                                            id="hero-illustration-a">
                                                <stop stopColor="#A7FDE8" stopOpacity="0" offset="0%"/>
                                                <stop stopColor="#6EFACC" offset="100%"/>
                                            </linearGradient>
                                            <linearGradient x1="100%" y1="50%" x2="0%" y2="50%"
                                                            id="hero-illustration-b">
                                                <stop stopColor="#FF4F7A" stopOpacity="0" offset="0%"/>
                                                <stop stopColor="#FF4F7A" offset="100%"/>
                                            </linearGradient>
                                            <radialGradient fx="50%" fy="50%" r="100%" id="hero-illustration-c">
                                                <stop stopColor="#6EFACC" offset="0%"/>
                                                <stop stopColor="#6EFACC" stopOpacity="0" offset="100%"/>
                                            </radialGradient>
                                            <linearGradient x1="0%" y1="100%" y2="0%" id="hero-illustration-d">
                                                <stop stopColor="#007CFE" stopOpacity="0" offset="0%"/>
                                                <stop stopColor="#007DFF" offset="100%"/>
                                            </linearGradient>
                                            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%"
                                                            id="hero-illustration-e">
                                                <stop stopColor="#FF4F7A" stopOpacity="0" offset="0%"/>
                                                <stop stopColor="#FF4F7A" offset="100%"/>
                                            </linearGradient>
                                            <filter x="-500%" y="-500%" width="1000%" height="1000%"
                                                    filterUnits="objectBoundingBox" id="dropshadow-1">
                                                <feOffset dy="16" in="SourceAlpha" result="shadowOffsetOuter"/>
                                                <feGaussianBlur stdDeviation="24" in="shadowOffsetOuter"
                                                                result="shadowBlurOuter"/>
                                                <feColorMatrix
                                                    values="0 0 0 0 0.12 0 0 0 0 0.17 0 0 0 0 0.21 0 0 0 0.2 0"
                                                    in="shadowBlurOuter"/>
                                            </filter>
                                        </defs>
                                        <g transform="translate(0 -1)" fill="none" fillRule="evenodd">
                                            <g transform="translate(128 290)" fill="url(#hero-illustration-a)">
                                                <path
                                                    d="M62.225 0l1.415 1.414L1.414 63.64 0 62.225zM66.468 4.243l1.414 1.414L5.657 67.882l-1.414-1.414zM70.71 8.485L72.126 9.9 9.899 72.125 8.485 70.71zM74.953 12.728l1.415 1.414-62.226 62.226-1.414-1.415zM79.196 16.97l1.414 1.415L18.385 80.61l-1.414-1.414zM83.439 21.213l1.414 1.414-62.226 62.226-1.414-1.414zM87.681 25.456l1.414 1.414L26.87 89.095l-1.414-1.414zM91.924 29.698l1.414 1.415-62.225 62.225-1.415-1.414z"/>
                                                <path
                                                    d="M91.924 29.698l1.414 1.415-62.225 62.225-1.415-1.414zM96.167 33.941l1.414 1.414-62.226 62.226-1.414-1.414zM100.41 38.184l1.413 1.414-62.225 62.225-1.414-1.414zM104.652 42.426l1.414 1.415-62.225 62.225-1.415-1.414zM108.894 46.669l1.415 1.414-62.226 62.226-1.414-1.415zM113.137 50.912l1.414 1.414-62.225 62.225-1.414-1.414zM117.38 55.154l1.414 1.415-62.225 62.225-1.415-1.414zM121.622 59.397l1.415 1.414-62.226 62.226-1.414-1.415z"/>
                                                <g>
                                                    <path
                                                        d="M100.811 0l-1.414 1.414 62.225 62.226 1.415-1.415zM96.569 4.243l-1.415 1.414 62.226 62.225 1.414-1.414zM92.326 8.485L90.912 9.9l62.225 62.226 1.414-1.414zM88.083 12.728l-1.414 1.414 62.225 62.226 1.415-1.415zM83.84 16.97l-1.414 1.415 62.226 62.225 1.414-1.414zM79.598 21.213l-1.414 1.414 62.225 62.226 1.414-1.414zM75.355 25.456l-1.414 1.414 62.226 62.225 1.414-1.414zM71.113 29.698l-1.415 1.415 62.226 62.225 1.414-1.414z"/>
                                                    <path
                                                        d="M71.113 29.698l-1.415 1.415 62.226 62.225 1.414-1.414zM66.87 33.941l-1.414 1.414 62.225 62.226 1.414-1.414zM62.627 38.184l-1.414 1.414 62.226 62.225 1.414-1.414zM58.385 42.426l-1.414 1.415 62.225 62.225 1.414-1.414zM54.142 46.669l-1.414 1.414 62.225 62.226 1.415-1.415zM49.9 50.912l-1.415 1.414 62.226 62.225 1.414-1.414zM45.657 55.154l-1.414 1.415 62.225 62.225 1.414-1.414zM41.414 59.397L40 60.811l62.225 62.226 1.415-1.415z"/>
                                                </g>
                                            </g>
                                            <g transform="matrix(1 0 0 -1 326 124)" fill="url(#hero-illustration-a)">
                                                <path
                                                    d="M62.225 0l1.415 1.414L1.414 63.64 0 62.225zM66.468 4.243l1.414 1.414L5.657 67.882l-1.414-1.414zM70.71 8.485L72.126 9.9 9.899 72.125 8.485 70.71zM74.953 12.728l1.415 1.414-62.226 62.226-1.414-1.415zM79.196 16.97l1.414 1.415L18.385 80.61l-1.414-1.414zM83.439 21.213l1.414 1.414-62.226 62.226-1.414-1.414zM87.681 25.456l1.414 1.414L26.87 89.095l-1.414-1.414zM91.924 29.698l1.414 1.415-62.225 62.225-1.415-1.414z"/>
                                                <path
                                                    d="M91.924 29.698l1.414 1.415-62.225 62.225-1.415-1.414zM96.167 33.941l1.414 1.414-62.226 62.226-1.414-1.414zM100.41 38.184l1.413 1.414-62.225 62.225-1.414-1.414zM104.652 42.426l1.414 1.415-62.225 62.225-1.415-1.414zM108.894 46.669l1.415 1.414-62.226 62.226-1.414-1.415zM113.137 50.912l1.414 1.414-62.225 62.225-1.414-1.414zM117.38 55.154l1.414 1.415-62.225 62.225-1.415-1.414zM121.622 59.397l1.415 1.414-62.226 62.226-1.414-1.415z"/>
                                                <g>
                                                    <path
                                                        d="M100.811 0l-1.414 1.414 62.225 62.226 1.415-1.415zM96.569 4.243l-1.415 1.414 62.226 62.225 1.414-1.414zM92.326 8.485L90.912 9.9l62.225 62.226 1.414-1.414zM88.083 12.728l-1.414 1.414 62.225 62.226 1.415-1.415zM83.84 16.97l-1.414 1.415 62.226 62.225 1.414-1.414zM79.598 21.213l-1.414 1.414 62.225 62.226 1.414-1.414zM75.355 25.456l-1.414 1.414 62.226 62.225 1.414-1.414zM71.113 29.698l-1.415 1.415 62.226 62.225 1.414-1.414z"/>
                                                    <path
                                                        d="M71.113 29.698l-1.415 1.415 62.226 62.225 1.414-1.414zM66.87 33.941l-1.414 1.414 62.225 62.226 1.414-1.414zM62.627 38.184l-1.414 1.414 62.226 62.225 1.414-1.414zM58.385 42.426l-1.414 1.415 62.225 62.225 1.414-1.414zM54.142 46.669l-1.414 1.414 62.225 62.226 1.415-1.415zM49.9 50.912l-1.415 1.414 62.226 62.225 1.414-1.414zM45.657 55.154l-1.414 1.415 62.225 62.225 1.414-1.414zM41.414 59.397L40 60.811l62.225 62.226 1.415-1.415z"/>
                                                </g>
                                            </g>
                                            <path d="M0 0h528v414H0z"/>
                                            <path fill="#FFF" d="M0 63h528v297H0z"
                                                  style={{mixBlendMode: "multiply", filter: "url(#dropshadow-1)"}}/>
                                            <path fill="#FFF" d="M0 63h528v297H0z"/>
                                            <path fill="url(#hero-illustration-b)" d="M408 230h80v48h-80z"/>
                                            <path
                                                d="M0 0h4v4H0V0zm0 12h4v4H0v-4zm0 12h4v4H0v-4zm0 12h4v4H0v-4zm0 12h4v4H0v-4zm0 12h4v4H0v-4zM12 0h4v4h-4V0zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zM24 0h4v4h-4V0zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zM36 0h4v4h-4V0zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zM48 0h4v4h-4V0zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zM60 0h4v4h-4V0zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4z"
                                                transform="rotate(45 -118.861 431.981)"
                                                fill="url(#hero-illustration-c)"/>
                                            <path fill="#FFF" d="M288 254h160v160H288z"
                                                  style={{mixBlendMode: "multiply", filter: "url(#dropshadow-1)"}}/>
                                            <path fill="#FFF" d="M288 254h160v160H288z"/>
                                            <path d="M248 79h88v24c0 35.346-28.654 64-64 64h-24V79z"
                                                  fill="url(#hero-illustration-d)"/>
                                            <path d="M348 132c0 26.51-21.49 48-48 48 0-26.51 21.49-48 48-48z"
                                                  fill="url(#hero-illustration-e)" transform="matrix(1 0 0 -1 0 312)"/>
                                            <path fill="#FFF" d="M200 31h88v88h-88z"
                                                  style={{mixBlendMode: "multiply", filter: "url(#dropshadow-1)"}}/>
                                            <path fill="#FFF" d="M200 31h88v88h-88z"/>
                                            <path fill="#F6F8FA" d="M24 248l88 88H24z"/>
                                            <rect fill="url(#hero-illustration-d)" x="335" y="242" width="32"
                                                  height="32" rx="16"/>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="features section">
                        <div className="container mt-5 pt-5">
                            <div className="features-inner section-inner pt-2 row">
                                <div className={"col-md-6"}>
                                    <img src={monitor} alt="" style={{width: "100%"}}/>
                                </div>
                                <div className={"col-md-6"} id={"about"}>
                                    <h2 className="section-title mt-0 mb-4 text-center">Despre aplicație</h2>
                                    <p style={{fontSize: "1.1rem"}}>
                                        MenuOrders este o aplicație web de gestiune a meniurilor și comenzilor de la
                                        furnizori, creeată pentru a ușura lucrul și a elimina managementul pe foile de
                                        hârtie, mutând lucrul în spațiul digital.
                                    </p>
                                    <p style={{fontSize: "1.1rem"}}>
                                        Aplicația este destinată sălilor de evenimente, pentru comandarea și recepția
                                        produselor necesare producerii de meniuri de eveniment, precum și pentru crearea
                                        unui rețatar digital.
                                    </p>
                                    <p style={{fontSize: "1.1rem"}}>
                                        Am dezvoltat această aplicație pentru a te ajuta să organizezi fluxurile din
                                        business-ul tău folosind instrumente simple, sigure și ușor de accesat. În plus,
                                        soluția de management online este adaptabilă nevoilor specifice afacerii tale.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="features section " style={{position: "relative"}}>
                        <div className="container">
                            <h2 className="section-title mt-0 text-center pt-5 has-top-divider">Funcționalități</h2>
                            <div className="features-inner section-inner pt-2 ">
                                <div className="features-wrap">
                                    <FeatureCard
                                        icon={<span className="material-symbols-outlined">database</span>}
                                        title={"Management bază de date"}
                                        features={[
                                            "unități de măsură gestiuni",
                                            "gestionare articole",
                                            "categorii produse",
                                            "rețetare/componente"
                                        ]}
                                    />
                                    <FeatureCard
                                        icon={<span className="material-symbols-outlined">storefront</span>}
                                        title={"Gestiune furnizori"}
                                        features={[
                                            "gestionare date furnizori",
                                            "înregistrare produse",
                                            "creare rețetar",
                                            "contacte",
                                            "trimitere e-mail-uri direct din sistem"
                                        ]}
                                    />
                                    <FeatureCard
                                        icon={<span className="material-symbols-outlined">receipt_long</span>}
                                        title={"Achiziții și vânzări"}
                                        features={[
                                            "comenzi marfă",
                                            "recepții marfă",
                                            "facturi",
                                            "avize",
                                            "rapoarte",
                                            "e-factura"
                                        ]}
                                    />
                                </div>
                                <div className={"mt-4 container"} style={{fontSize: "1.1rem"}}>
                                    * La cerere se pot adăuga funcționalități noi.
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="pricing section">
                        <div className="section-square"></div>
                        <div className="container">
                            <div className="pricing-inner section-inner has-top-divider">
                                <h2 className="section-title mt-0 text-center">Tarife</h2>
                                <div className="pricing-tables-wrap">
                                    <PricingCard features={priceTexts.SELF_HOSTING}
                                                 price={2200}
                                                 currency={"eur"}
                                                 typeTitle={"Self-hosting"}
                                                 onClick={() => {
                                                     setContactMessage("Ma intereseaza oferta de self-hosting, as vrea sa primesc mai multe detalii.")
                                                     setTimeout(() => {
                                                         let htmlAnchorElement = document.createElement("a");
                                                         htmlAnchorElement.href = "/#contact"
                                                         htmlAnchorElement.click()
                                                         htmlAnchorElement.remove()
                                                     }, 300)
                                                 }}
                                    />
                                    <PricingCard features={priceTexts.HOSTING}
                                                 typeTitle={"Hosting inclus"}
                                                 price={350}
                                                 currency={"ron"}
                                                 monthly={true}
                                                 onClick={() => {
                                                     setContactMessage("Ma intereseaza oferta de hosting inclus, as vrea sa primesc mai multe detalii.")
                                                     setTimeout(() => {
                                                         let htmlAnchorElement = document.createElement("a");
                                                         htmlAnchorElement.href = "/#contact"
                                                         htmlAnchorElement.click()
                                                         htmlAnchorElement.remove()
                                                     }, 300)
                                                 }}
                                    />
                                </div>
                                <div style={{color: "white"}} className={"container"}>
                                    *Preturile nu includ TVA
                                </div>
                            </div>
                        </div>
                    </section>

                    <div style={{backgroundColor: "#1F2B35"}} className={"py-5"} id={"free-test"}>
                        <div className={"container"}>
                            <h3 className="section-title m-0 text-center"
                                style={{color: "white", whiteSpace: "pre-wrap"}}
                            >
                                Încearcă gratuit 7 zile
                            </h3>
                            <p style={{color: "white"}} className={"m-0 text-center"}>
                                Încă nu te-ai lămurit? Testează gratuit timp de 7 zile! Aplică pentru un demo iar noi
                                iți vom răspunde pe mail cu datele de logare
                            </p>
                            <Form
                                form={form}
                                onFinish={sendEmailToCustomer}
                                className={"mt-3 d-flex justify-content-center"}
                                validateMessages={{
                                    types: {
                                        email: "Adresa de email nu este valida!"
                                    }
                                }}
                            >
                                <Space.Compact className={styleApplicationField}>
                                    <Form.Item name={["user", "email"]}
                                               rules={[{type: "email"}]}
                                               style={{width: "100%"}}
                                    >
                                        <Input size={"large"}
                                               placeholder="Adresa de email"
                                               value={testEmail}
                                               style={{padding: "12px"}}
                                               onChange={(e) => {
                                                   setTestEmail(e.currentTarget.value)
                                               }}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary"
                                                size={"large"}
                                                htmlType={"submit"}
                                                loading={createDemoAccLoading}
                                        >
                                            Aplică
                                        </Button>
                                    </Form.Item>
                                </Space.Compact>
                            </Form>
                        </div>
                    </div>
                </main>
                <div style={{backgroundColor: "#1F2B35"}} className={"pt-5"}>
                    <div className={"container"}>
                        <Contact message={contactMessage}/>
                    </div>
                </div>
                <footer className="site-footer text-light">
                    <div className="container">
                        <div className="has-top-divider py-4">
                            <div className={"row justify-content-between"}>
                                <div className={"col-md-4 mb-4"}>
                                    <div className={"pb-3"}>
                                        <img src={logoWhitePng} alt="" style={{width: "120px"}} className={styleImg}/>
                                    </div>
                                    <div className="footer-copyright">
                                        &copy; {new Date().getFullYear()} MenuOrders, all rights reserved
                                    </div>
                                </div>
                                <div style={{alignSelf: "center"}} className={"col-md-3 text-center  mb-4"}>
                                    <Button size={"large"}
                                            style={{fontSize: "1.3rem"}}
                                    >
                                        <b>
                                            <a href={LOGIN_LINK}
                                               style={{textDecoration: "none"}}
                                            >
                                                PORTAL
                                            </a>
                                        </b>
                                    </Button>
                                </div>
                                <div className={"col-md-5  mb-2"}>
                                    <ul style={{listStyle: "none", gap: "15px", fontSize: "0.9rem"}}
                                        className={"d-flex text-center"}>
                                        <li className={"pointer"}>FAQ</li>
                                        <li className={"pointer"}>
                                            <a href={TERMS_AND_CONDITIONS}>
                                                Termeni si conditii
                                            </a>
                                        </li>
                                        <li className={"pointer"}>
                                            <a href={CONFIDENTIALITY_POLICY}>
                                                Politica de confidentialitate
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}