import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {ServiceContext, ServiceContextInstance} from "../../../Core/serviceContext";
import {MenuProduct} from "../../../Models/backend/MenuProduct";
import {useFetch} from "../../../Hooks/useFetch";
import {IFetchResult} from "../../../Hooks/useFetch.types";
import {MenuProductCard} from "./menuProductCard";
import {MenuProductModalOperation} from "./menuProductModalOperation";
import {Empty, Input, message, Select, Skeleton} from "antd";
import {MenuProductsSortOrder} from "../../../Enums/MenuProductsSortOrder";

export const MenuProductsPage = (props: {
    setTitle: Dispatch<SetStateAction<string>>,
    setBtnText: Dispatch<SetStateAction<string>>,
    setBtnAction: Dispatch<SetStateAction<() => void>>
}): React.JSX.Element => {

    const services: ServiceContext = useContext(ServiceContextInstance);

    const [sorted, setSorted] = useState<MenuProductsSortOrder>();
    const [refreshProducts, setRefreshProducts] = useState<boolean>(false);
    const menuProductData: IFetchResult<MenuProduct[]> = useFetch<MenuProduct[]>(() => services.MenuProductService.getAll(sorted), [refreshProducts, sorted]);
    const [menuProducts, setMenuProducts] = useState<MenuProduct[]>([]);
    const [searchedMenuProducts, setSearchedMenuProducts] = useState<MenuProduct[]>([]);

    const [areProductsLoaded, setAreProductsLoaded] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalType, setModalType] = useState<"create" | "update">("create");
    const [operation, setOperation] = useState<(menuProduct: MenuProduct) => Promise<boolean>>(() => {
        return async () => {
            return false;
        }
    })
    const [selectedMenuProductToUpdate, setSelectedMenuProductToUpdate] = useState<MenuProduct | undefined>(undefined);

    const [searchValue, setSearchValue] = useState<string>();

    const sortOptions = [
        {
            label: "Sortează după nume",
            value: MenuProductsSortOrder.NAME
        },
        {
            label: "Sortează după preț crescător",
            value: MenuProductsSortOrder.PRICE_ASC
        },
        {
            label: "Sortează după preț descrescător",
            value: MenuProductsSortOrder.PRICE_DESC
        }
    ]

    useEffect(() => {
        if (menuProductData.isLoading) {
            return;
        }
        if (menuProductData.errors !== '' || menuProductData.data === null) {
            return;
        }
        if (menuProductData.data.Error !== undefined || menuProductData.data.Data === undefined) {
            return;
        }
        let data = menuProductData.data.Data;
        setMenuProducts(data);
        setAreProductsLoaded(true)
    }, [menuProductData])

    async function createProduct(product: MenuProduct):Promise<boolean> {
        let response = await services.MenuProductService.create(product);
        if (response.Status === 209) {
            message.warning("Există deja un preparat cu acest nume! Încercați altă denumire!")
            return false
        }
        if (response.Error !== undefined || response.Data === undefined) {
            message.error("Nu s-a putut crea preparatul")
            return false
        }
        message.success("Preparatul a fost creat!")
        return true
    }

    useEffect(() => {
        props.setBtnAction(() => {
                return () => {
                    setSelectedMenuProductToUpdate(undefined)
                    setOperation(
                        () => {
                            return async (product: MenuProduct): Promise<boolean> => {
                                let booleanPromise = await createProduct(product);
                                setRefreshProducts(!refreshProducts)
                                return booleanPromise
                            }
                        }
                    )
                    setModalType("create")
                    setShowModal(true)
                }
            }
        )
        props.setTitle("Feluri principale");
        props.setBtnText("+ Creează fel principal");
    }, [])

    useEffect(() => {
        setSearchedMenuProducts(
            menuProducts.filter(el => searchValue === undefined || el.name.toLowerCase().includes(searchValue.toLowerCase()))
        )
    }, [searchValue, menuProducts])

    return (
        <div id={"menu-product-p1-c1"}>
            <div className={"mb-3"}>
                <Input placeholder={"Caută după denumire"}
                       style={{width: "40%"}}
                       className={"me-3"}
                       value={searchValue}
                       onChange={(e) => {
                           setSearchValue(e.currentTarget.value)
                       }}
                />
                <Select placeholder={"Sortează după"}
                        style={{width: "20%"}}
                        options={sortOptions}
                        onChange={(value) => {
                            setSorted(value)
                            setAreProductsLoaded(false)
                        }}
                />
            </div>
            <div className={"row"}>
                {
                    areProductsLoaded ?
                        (
                            searchedMenuProducts.map((el, index) => (
                                <div key={index}
                                     className={"py-1 px-2 col-lg-4"}
                                >
                                    <MenuProductCard menuProduct={el}
                                                     index={index}
                                                     setShowModal={setShowModal}
                                                     setModalType={setModalType}
                                                     setOperation={setOperation}
                                                     searchText={searchValue}
                                                     setSelectedMenuProductToUpdate={setSelectedMenuProductToUpdate}
                                                     refreshProducts={() => setRefreshProducts(!refreshProducts)}
                                    />
                                </div>
                            ))
                        )
                        :
                        (
                            <>
                                <div className={"col-lg-6 mb-4"}
                                >
                                    <Skeleton active
                                              className={"px-4 py-3 border rounded"}
                                              style={{backgroundColor: "white"}}
                                    />
                                </div>
                                <div className={"col-lg-6 mb-4"}>
                                    <Skeleton active
                                              className={"px-4 py-3 border rounded"}
                                              style={{backgroundColor: "white"}}
                                    />
                                </div>
                                <div className={"col-lg-6 mb-4"}>
                                    <Skeleton active
                                              className={"px-4 py-3 border rounded"}
                                              style={{backgroundColor: "white"}}
                                    />
                                </div>
                                <div className={"col-lg-6 mb-4"}>
                                    <Skeleton active
                                              className={"px-4 py-3 border rounded"}
                                              style={{backgroundColor: "white"}}
                                    />
                                </div>
                            </>
                        )
                }
                {
                    areProductsLoaded && searchedMenuProducts.length === 0 &&
                    <Empty description={"Nu sunt preparate create"}/>
                }
            </div>
            <MenuProductModalOperation showModal={showModal}
                                       setShowModal={setShowModal}
                                       operation={operation}
                                       type={modalType}
                                       menuProduct={selectedMenuProductToUpdate}
                                       setMenuProduct={setSelectedMenuProductToUpdate}
                                       refreshProducts={() => setRefreshProducts(!refreshProducts)}
            />
        </div>
    )
}