import React, {useContext, useEffect, useRef, useState} from 'react';
import type {InputRef} from 'antd';
import {Button, Form, Input, Space, Table} from 'antd';
import type {FormInstance} from 'antd/es/form';
import {AnyObject} from "antd/es/_util/type";
import {PrimeProduct} from "../../../../Models/backend/PrimeProduct";
import {SearchOutlined} from "@ant-design/icons";
import {FilterConfirmProps} from "antd/es/table/interface";
//@ts-ignore
import Highlighter from 'react-highlight-words';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
    key: string;
    name: string;
    price: string;
    um: string;
}

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
                                                       title,
                                                       editable,
                                                       children,
                                                       dataIndex,
                                                       record,
                                                       handleSave,
                                                       ...restProps
                                                   }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({[dataIndex]: record[dataIndex]});
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({...record, ...values});
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{margin: 0}}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{paddingRight: 24}} onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface TableProductType {
    key: React.Key;
    name: string;
    price: string;
    um: string;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;


function productToDataType(p: PrimeProduct): Item {
    return {
        key: p.id + "",
        name: p.name!,
        price: String(p.price),
        um: p.quantityType as string
    }
}

const ProductsTableCreateOrder = (props: {
    provider?: string;
    products?: PrimeProduct[];
    addToCart: (key: React.Key) => void;
}): JSX.Element => {
    const [dataSource, setDataSource] = useState<TableProductType[]>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    useEffect(() => {
        let products = props.products;
        if (props.provider === undefined) {
            setDataSource([])
            return
        }
        if (products !== undefined) {
            let productsConvertedToDataType: Item[] = products.map((p, index) => {
                return productToDataType(p);
            })
                .sort((a, b) => {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                });
            setDataSource(productsConvertedToDataType)
        }
    }, [props.provider])

    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'Nume',
            dataIndex: 'name',
            width: "50%",
            sorter: (a: any, b: any) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
                <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        ref={searchInput}
                        placeholder={`Caută după denumire`}
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                            handleSearch([e.target.value], confirm, "name")
                        }}
                        onPressEnter={() => handleSearch(selectedKeys as string[], confirm, "name")}
                        style={{marginBottom: 8, display: 'block'}}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys as string[], confirm, "name")}
                            icon={<SearchOutlined/>}
                            size="small"
                            style={{width: 90}}
                        >
                            Cauta
                        </Button>
                        <Button
                            onClick={() => clearFilters && handleReset(clearFilters)}
                            size="small"
                            style={{width: 90}}
                        >
                            Reseteaza
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                close();
                            }}
                        >
                            inchide
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{color: filtered ? '#1677ff' : undefined}}/>
            ),
            onFilter: (value, record) =>
                record["name"]
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()),
            onFilterDropdownOpenChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
            render: (text) =>
                searchedColumn === "name" ? (
                    <Highlighter
                        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Pret',
            dataIndex: 'price',
        },
        {
            title: 'UM',
            dataIndex: 'um',
        },
        {
            title: '#',
            dataIndex: 'operation',
            render: (value, record: AnyObject, index) =>
                dataSource.length >= 1 ? (
                    <div className={"pointer underline-hover"} onClick={() => props.addToCart(record.key)}>adauga</div>
                ) : null,
        },
    ];

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: TableProductType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                // handleSave,
            }),
        };
    });

    type DataIndex = keyof Item;

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm({closeDropdown: false});
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    return (
        <Table
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            pagination={false}
            dataSource={dataSource}
            columns={columns as ColumnTypes}
        />
    );
};

export default ProductsTableCreateOrder;