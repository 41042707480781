import {message, Spin, Tabs} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {ServiceContext, ServiceContextInstance} from "../../../../Core/serviceContext";
import {TableWithCart} from "../CreateOrder/tableWithCart";
import {IFetchResult} from "../../../../Hooks/useFetch.types";
import {useFetch} from "../../../../Hooks/useFetch";
import {OrderWithProvider} from "../../../../Models/model/OrderWithProvider";

export const CreateMenuOrderProcess = (props: {
    menuId: string;
    people: number;
    dependency: string[];
}): JSX.Element => {

    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [tabs, setTabs] = useState<Tab[]>([])

    const ordersWithProvidersData: IFetchResult<OrderWithProvider[]> = useFetch<OrderWithProvider[]>(() => services.OrderService.getOrdersForMenu(props.menuId, props.people), [...props.dependency]);
    const [ordersWithProviders, setOrdersWithProviders] = useState<OrderWithProvider[]>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true)
    }, [...props.dependency])

    useEffect(() => {
        if (ordersWithProvidersData.isLoading) {
            return;
        }
        if (ordersWithProvidersData.errors !== '' ||
            ordersWithProvidersData.data === null ||
            ordersWithProvidersData.data?.Error !== undefined ||
            ordersWithProvidersData.data?.Data === undefined) {
            message.warning("Nu s-a putut crea comanda pentru meniu.")
            return;
        }
        let data = ordersWithProvidersData.data.Data;
        setOrdersWithProviders(data)
        setLoading(false)
    }, [ordersWithProvidersData])

    function setTabsWithOrders() {
        let index = 0;
        const theTabs: Tab[] = []
        for (let orderWithProvider of ordersWithProviders!) {
            index++;
            const leTab: Tab = {
                key: index + "",
                label: orderWithProvider.provider.name,
                children: <TableWithCart order={orderWithProvider.order}
                                         allProducts={orderWithProvider.provider.products}/>
            }
            theTabs.push(leTab)
        }
        setTabs(theTabs)
    }

    useEffect(() => {
        if (ordersWithProviders === undefined) {
            return
        }
        setTabsWithOrders()
    }, [ordersWithProviders])

    return (
        <>
            {
                loading ? (
                    <div style={{width: "100%"}}>
                        <Spin size={"large"} className={"mx-auto mt-5"} style={{display: "block"}}/>
                    </div>
                ) : (
                    <Tabs
                        defaultActiveKey="1"
                        type="card"
                        size={"large"}
                        items={tabs}
                    />
                )
            }
        </>


    )
}

interface Tab {
    key: string;
    label: React.ReactNode;
    children: React.ReactNode;
}