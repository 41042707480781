export function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // months are 0-based in JS
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function isDateBefore(date1: string, date2: string) {
    return new Date(date1) < new Date(date2);
}

export function isBeforeToday(date: string) {
    return isDateBefore(date, new Date().toDateString());
}