import {mergeStyles} from "@fluentui/react";

export const borderStyle: string = mergeStyles({
    border: "2px dashed rgba(50,50,50,0.2)",
    borderRadius: "10px",
    padding: "1rem 2rem",
    display: "flex",
    justifyContent: "center"
})

export const imageHover: string = mergeStyles({
    "&:hover:after": {}
})