import React from "react";
import {Divider} from "antd";

export const OrderNameWithProviderCard = ({name, providerName, onClick, onDoubleClick}: {
    name: string,
    providerName: string
    onClick: () => void
    onDoubleClick?: (e: React.MouseEvent) => void
}): JSX.Element => {
    return (
        <div className={"rounded shadow-sm border bg-white px-3 py-2 pointer"} onClick={onClick}
             onDoubleClick={onDoubleClick}>
            <div style={{fontSize: "18px"}}>
                {name}
            </div>
            <Divider style={{fontSize: "12px"}} className={"m-0"}>{providerName}</Divider>
        </div>
    )
}