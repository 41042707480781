import {APIHelper} from "../Configuration/apiHelper";
import {IResponse} from "../Models/IResponse";

export default class ContactService {

    constructor(protected readonly _endpoint: string) {

    };

    async sendEmailFromCustomer(name: string, email: string, phone: string | undefined, message: string): Promise<IResponse<void>> {
        const request = {
            name: name,
            email: email,
            phone: phone,
            message: message
        }
        return await APIHelper.request(`${this._endpoint}/email-from-customer`, 'POST',
            JSON.stringify(request), false)
    };

    async sendWhatsappMessage(content: string, contact: string): Promise<IResponse<void>> {
        const formData: FormData = new FormData();
        formData.append("content", content);
        formData.append("contact", contact);
        return await APIHelper.request(`${this._endpoint}/whatsapp`, 'POST', formData, true)
    };


    async resetPassword(email: string): Promise<IResponse<void>> {
        const formData: FormData = new FormData()
        formData.append("email", email);
        return await APIHelper.request(`${this._endpoint}/reset-password`, 'POST', formData, true)
    };


};