import {Input, message, Select, Tooltip} from "antd";
import {useEffect, useState} from "react";
import {PrimeProduct} from "../../../../Models/backend/PrimeProduct";
import {PrimeProductWithQuantity} from "../../../../Models/PrimeProductWithQuantity";
import {extractBasicQuantity, proportionToPercentage} from "../../../../Library/utils/quantityDetector";
import {twoDecimals} from "../../../../Library/utils/mathUtil";

export const MenuProductComputationCard = (props: {
    primeProducts: PrimeProduct[]; // all prime products
    index: number;
    itemKey: number;
    removeItem: (key: number) => void;
    updateItem: (key: number, value: PrimeProductWithQuantity) => void;
    value: PrimeProductWithQuantity;
}): JSX.Element => {
    const [options, setOptions] = useState<PrimeProductOption[]>([]);

    const [selectedProduct, setSelectedProduct] = useState<PrimeProduct>();
    const [selectedQuantity, setSelectedQuantity] = useState<string>();
    const [quantity, setQuantity] = useState<string>();
    const [percentage, setPercentage] = useState<number>();
    const [pieces, setPieces] = useState<number>();
    const [percentageAsQuantity, setPercentageAsQuantity] = useState<string>();

    useEffect(() => {
        let primeProduct = props.value.orderProduct;
        if (primeProduct !== undefined) {
            setSelectedProduct(primeProduct)
        }
        let quantity = props.value.quantity;
        if (quantity !== undefined) {
            setSelectedQuantity(quantity.toString() + "%")
            setQuantity(quantity.toString() + "%")
        }
    }, [props.value])

    useEffect(() => {
        const groupedProducts = new Map<string, PrimeProduct[]>();
        props.primeProducts.forEach((product) => {
            const provider = product.provider!;

            if (!groupedProducts.has(provider.name!)) {
                groupedProducts.set(provider.name!, []);
            }

            groupedProducts.get(provider.name!)?.push(product);
        });
        let keys = groupedProducts.keys();
        const theOptions: PrimeProductOption[] = []
        // @ts-ignore
        for (let key of keys) {
            const list: { label: string, value: string }[] = [];
            groupedProducts.get(key)?.forEach((product) => {
                list.push({
                    label: `${product.name!} (${product.price ?? ""} ron/${product.quantityType})`,
                    value: product.id!,
                })
            })
            theOptions.push({
                label: key,
                options: list
            })
            setOptions(theOptions)
        }
    }, [props.primeProducts])

    const primeProductChange = (value: string) => {
        let find = props.primeProducts.find((el) => el.id === value)!;
        setSelectedProduct(find);
    };

    function filterOptions(input: string, option: PrimeProductOption | undefined) {
        return (option?.label ?? '').includes(input.toLowerCase())
    }

    function filterSort(optionA: PrimeProductOption, optionB: PrimeProductOption) {
        return (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }

    useEffect(() => {
        if (selectedProduct === undefined) {
            return
        }
        if (selectedQuantity === undefined) {
            return
        }
        let minQuantity = extractBasicQuantity(selectedProduct);
        if (minQuantity === undefined) {
            message.warning("Nu s-a putut calcula proportia. Incercati va rog sa exprimati in procente (%).")
            return
        }
        let pert = proportionToPercentage(selectedQuantity, minQuantity);
        if (pert !== undefined && pert !== null) {
            setPercentage(twoDecimals(pert));
            let quantity1 = minQuantity.quantity;
            let number = (pert / 100.0) * quantity1;
            let type = minQuantity.type;
            if (type === "KG") {
                type = "GR";
                number = number * 1000;
            }
            if (type === "L") {
                type = "ML";
                number = number * 1000;
            }
            setPercentageAsQuantity(number + "" + type.toLowerCase());
            props.updateItem(props.itemKey, {orderProduct: selectedProduct, quantity: twoDecimals(pert)})
            if (pert > 100) {
                setPieces(twoDecimals(pert / 100.0))
            } else {
                setPieces(twoDecimals(100.0 / pert))
            }
        } else {
            message.warning("Nu s-a putut calcula proportia. Incercati va rog sa exprimati in procente (%).")
        }
    }, [selectedProduct, selectedQuantity])


    return (
        <div className={"col-lg-6 "}>
            <div className={"px-2 py-1 rounded d-flex align-items-center mb-3 shadow-sm"}
                 style={{border: "1px solid rgba(0,0,0,0.2)"}}>
                <div className={"me-3"}>
                    {props.index}
                </div>
                <div>
                    <div className={"mt-2 d-flex"}>
                        <div style={{width: "350px"}}>
                            <Select
                                placeholder={"Selecteaza un produs"}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOptions}
                                filterSort={filterSort}
                                style={{width: 320}}
                                onChange={primeProductChange}
                                value={selectedProduct?.id === "0" ? undefined : selectedProduct?.id}
                                options={options}
                            />
                            <div className={"d-flex align-items-center mt-2"}>
                                <span>
                                    proportie
                                    <Tooltip
                                        title={"exemple de proportii: 1/5 din 1kg, 20% din 1kg, 30g din 870g, 1=100%, 0.5=50% din intreg"}>
                                        <i className="fa-solid fa-circle-info fa-lg ms-1" style={{opacity: "50%"}}></i>
                                    </Tooltip>
                                </span>
                                <Input style={{width: "100px", textAlign: "center"}}
                                       className={"mx-2"}
                                       placeholder={"proportie"}
                                       value={quantity}
                                       onChange={(e) => {
                                           setQuantity(e.target.value)
                                       }}
                                       onBlur={(e) => {
                                           setSelectedQuantity(e.target.value)
                                       }}
                                       onPressEnter={(e) => {
                                           setSelectedQuantity(quantity)
                                       }}
                                />
                                <span>
                                    {
                                        ((percentage + "%") === (selectedQuantity ?? "").trim()) ?
                                            (
                                                <span>{"=> " + percentageAsQuantity}</span>
                                            ) : (
                                                <span>{"=> " + percentage}%</span>
                                            )
                                    }
                                </span>
                            </div>
                        </div>
                        <div className={""}>
                             <span>
                                {
                                    selectedQuantity !== undefined && selectedQuantity !== null &&
                                    (percentage ?? 0) <= 100 &&
                                    (
                                        `din intreg poti obtine ${pieces} astfel de bucati`
                                    )
                                }
                                 {
                                     selectedQuantity !== undefined && selectedQuantity !== null &&
                                     (percentage ?? 0) > 100 &&
                                     (
                                         `trebuiesc ${pieces} bucati de ${selectedProduct?.name}`
                                     )
                                 }
                            </span>
                        </div>
                    </div>
                </div>
                <div className={"align-self-start ms-auto"}>
                    <i className="fa-solid fa-rectangle-xmark fa-xl pointer"
                       style={{color: "red"}}
                       onClick={() => props.removeItem(props.itemKey)}></i>
                </div>
            </div>
        </div>
    )
}

interface PrimeProductOption {
    label: string;
    options: {
        label: string;
        value: string
    }[]
}