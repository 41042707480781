import {TERMS_AND_CONDITIONS} from "../../Enums/pagesUrls";
//@ts-ignore
import logoWhitePng from './../../Assets/Images/logo-white.png';
import {mergeStyles} from "@fluentui/react";
import {blue} from "../../Assets/Styles/variables";

export const ConfidentialityPolicy = (): JSX.Element => {

    const styleImg = mergeStyles({
        '@media screen and (max-width: 768px)': {
            margin: "auto"
        },
    });

    return (
        <div>
            <div style={{backgroundColor: blue}} className={"py-3"}>
                <div className={"container"}>
                    <a href={"/"}>
                        <img src={logoWhitePng} alt="" style={{width: "120px"}} className={styleImg}/>
                    </a>
                </div>
            </div>
            <div className={"container my-5"}>
                <h1>Politica de confidentialitate si acordul de prelucrare a datelor</h1>
                <p>
                    Politicile de confidențialitate MenuOrders explică modul în care tratăm datele dvs. cu caracter
                    personal și în care vă protejăm confidențialitatea atunci când utilizați Serviciile noastre.
                </p>
                <p>
                    Prin utilizarea serviciilor noastre, sunteți de acord cu Politica de confidentialitate si acordul de
                    prelucrare a datelor însoțit și interpretat împreună cu <a
                    href={TERMS_AND_CONDITIONS} target={"_blank"} rel={"noreferrer"}>Termeni
                    și
                    condiții</a>. Vă rugăm să le citiți cu atenție.
                </p>
                <div>
                    <div style={{fontWeight: "bold"}}>
                        <span>1. </span>
                        <span>Baza legala</span>
                    </div>
                    <div>
                        <p>
                            Conform prevederilor legale pentru protecția persoanelor, cu privire la prelucrarea datelor
                            cu
                            caracter personal și libera circulație a acestor date avem obligația de a păstra
                            confidențialitatea și de a utiliza în condiții de siguranță datele personale pe care ni le
                            furnizați despre dvs. numai în scopuri asociate furnizării și comercializării serviciului
                            nostru.
                        </p>
                        <p>
                            Prelucrarea datelor dvs. se face fie pe baza consimțământului dvs., fie în cazul în care
                            prelucrarea este necesara pentru executarea contractului dvs. de servicii MenuOrders la care
                            sunteți
                            parte sau pentru a lua măsuri de solicitarea de închidere contract servicii MenuOrders
                            conform
                            GDPR
                            art. 6(1).
                        </p>
                        <p>
                            Pentru a utiliza serviciile MenuOrders trebuie sa ne furnizați datele personale necesare
                            executării
                            contractului de utilizare servicii MenuOrders.
                        </p>
                    </div>
                </div>
                <div>
                    <div style={{fontWeight: "bold"}}>
                        <span>2. </span>
                        <span>Ce informatii colectam</span>
                    </div>
                    <div>
                        <p>
                            Colectam, stocăm și gestionam următoarele categorii de date ce vor fi procesate de dvs. și
                            în
                            numele dvs. strict în scopul furnizării serviciilor MenuOrders.
                        </p>
                        <p>
                            Datele contului dvs. MenuOrders, date de baza ale firmei dvs., cum ar fi CF, număr
                            înregistrare,
                            telefon, email, adresa firmei, datele persoanei de contact, datele dvs. de acces în sistem
                            (nume cont, nume utilizator, parola);
                        </p>
                        <p>
                            Datele de configurare pentru sistemul dvs., date introduse de dvs. direct în interfața
                            sistemului MenuOrders cum ar fi: date despre firma dvs, puncte de lucru, date
                            personale ale furnizorilor creati de dvs., configurările de setări pentru toate tipurile de
                            documente gestionate de dvs. în cadrul sistemului, cat și toate celelalte date de
                            configurare
                            sistem MenuOrders aferent modulelor MenuOrders utilizate de Dvs.
                        </p>
                        <p>
                            Datele de contact ale clienților definiți de dvs., date despre clienți, furnizori,
                            parteneri,
                            persoane de contact ale acestora și nu numai, asociate sau nu la ale înregistrări gestionate
                            în
                            cadrul sistemului cum ar fi facturi, oferte, activități, produse, liste de preturi sau alte
                            înregistrări aferente modulelor MenuOrders utilizate de dvs. în cadrul serviciului.
                        </p>
                        <p>
                            Date generate de dvs. prin utilizarea sistemului, date și înregistrări generate, stocate și
                            gestionate de sistem pe baza celorlalte tipuri de date menționate mai sus, cum ar fi
                            facturi,
                            oferte, nir-uri, sau alte documente și înregistrări sau alte înregistrări aferente modulelor
                            utilizate de dvs. în cadrul serviciului.
                        </p>
                        <p>
                            Puteți crea, edita, modifica și șterge toate datele administrate de dvs. prin interfața
                            utilizator al sistemului MenuOrders. Dacă o anumita operațiune specifica privind datele
                            personale nu
                            poate fi efectuata de către utilizatorul sistemului iBee prin interfața utilizator puteți sa
                            ne
                            trimiteți o sesizare cu descrierea necesitați dvs,, pe adresa de mail
                            suport-clienti@menuorders.ro.
                        </p>
                    </div>
                </div>
                <div>
                    <div style={{fontWeight: "bold"}}>
                        <span>3. </span>
                        <span>Pentru ce folosim informatiile colectate</span>
                    </div>
                    <div>
                        <p>
                            Orice informație colectata de la dvs. poate fi folosită numai în scopul furnizării
                            serviciilor
                            noastre având unul sau mai multe din următoarele scopuri.
                        </p>
                        <ul>
                            <li>
                                Pentru a va permite sa controlați conținutul Dvs. în cadrul sistemului inclusiv pentru a
                                personaliza functionalitatile sistemului, pentru a inbunatati serviciile MenuOrders;
                            </li>
                            <li>
                                Pentru a stabili canalul principal de comunicare cu dvs, pentru a va identifica ca parte
                                contractanta, pentru a va permite sa va autentificați în sistemul nostru;
                            </li>
                            <li>
                                Pentru emiterea facturilor pentru serviciile oferite, pentru trimiterea mesajelor
                                importante în legătura cu facturile scadente, amintire plati, restante sau pentru
                                recuperarea si solutionarea oricaror incidente aflate în legătura cu furnizarea
                                serviciilor
                                noastre către dvs.
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div style={{fontWeight: "bold"}}>
                        <span>4. </span>
                        <span>Cum protejam informatiile colectate</span>
                    </div>
                    <div>
                        <p>
                            Luam în serios protecția datelor, securitatea și conformarea cu legislația privind protecția
                            datelor cu caracter personal și a vieții private, am luat toate măsurile tehnice și
                            organizaționale pentru protejarea și securitatea datelor, lucram numai cu furnizori de
                            servicii
                            cloud sau infrastructura de stocare, servere ce se conformează cerințelor GDPR.
                        </p>
                        <p>
                            Stocăm informațiile, inclusiv cele cu caracter personal în centrele de date ale Hostlayer.ro
                            Romania, partener ce ne oferă serviciile de infrastructura conforme GDPR. Datele stocate în
                            bazele de date relaționate sunt replicate automat din 24 în 24 ore către servere secundare.
                            Datele de tip fișiere sunt stocate tot în cadrul infrastructurii curente.
                        </p>
                        <p>
                            Securitatea fizica a datelor este asigurata de Hostlayer.ro Romania partener de servicii
                            infrastructura.
                        </p>
                        <p>
                            Toate transmisiile de date sunt criptate, comunicația intre dvs. și sistemul MenuOrders cat
                            și
                            cele de
                            tip administrativ intern este securizat prin Secure Socket Layer (SSL, HTTPS, VPN) și sunt
                            bazate pe standarde binecunoscute din industria de specialitate, standarde ce garantează
                            integritatea datelor.
                        </p>
                        <p>
                            Confidențialitatea este asigurata prin accesul exclusiv la datele procesate numai de către
                            personalul autorizat MenuOrders exclusiv în scopul derulării activităților aferente
                            furnizării serviciului noastre, dacă este cazul subcontractantii trebuie sa semneze un acord
                            de
                            confidențialitate.
                        </p>
                        <p>
                            Disponibilitatea ridicată a sistemului MenuOrders este oferită de către arhitectura
                            sistemului
                            bazata
                            pe micro-containere, load-balancing (echilibrarea încărcării), și redundanta sistemelor.
                        </p>
                        <p>
                            Monitorizam în permanentă sistemul MenuOrders, folosind cele mai avansate metode și tehnici
                            de
                            notificare și raportare incidente de utilizare, securitate, tentativa de acces sau utilizare
                            frauduloasa, logam în cadrul sistemului dvs. modificările realizate de dvs. sau de
                            utilizatorii definiți de Dvs. pe diferite înregistrări de sistem. Aceste loguri le puteți
                            accesa
                            în secțiunea “Istoric” pe diferite inregistrari.
                        </p>
                        <p>
                            Notificarea privind încălcarea datelor cu caracter personal o vom face conform
                            reglementarilor
                            aferente și dacă datele dvs. sunt compromise, va vom notifica prin email pe dvs. în calitate
                            de
                            proprietar sistem cat și pe autoritatea de supraveghere și monitorizare în decurs de 72 ore
                            furnizând suficiente detalii cu privire la datele afectate și amploarea incidentului.
                            "Încălcarea datelor cu caracter personal" înseamnă o încălcare a securității care duce la
                            distrugerea, pierderea, modificarea, dezvăluirea neautorizată sau accesarea neautorizată a
                            datelor personale transmise, stocate sau prelucrate în alt mod în legătură cu furnizarea
                            serviciului.
                        </p>
                    </div>
                </div>
                <div>
                    <div style={{fontWeight: "bold"}}>
                        <span>5. </span>
                        <span>Divulgarea informatiilor</span>
                    </div>
                    <div>
                        <p>
                            Nu divulgăm informațiile dvs. terților decât dacă ne-ați autorizat explicit în acest sens
                            sau
                            dacă suntem obligați prin lege pentru furnizarea datelor către organele de drept.
                        </p>
                        <p>
                            Nu vindem sau transferam terților nici o informație de identificare personala.
                        </p>
                        <p>
                            Partenerii sau subcontractorii cu care în relație de parteneriat realizam diferite
                            activități de
                            dezvoltare, suport sau comercializare a serviciilor MenuOrders pot avea acces la anumite
                            date
                            inclusiv
                            la cele de identificare personala însa vor avea obligația contractuala de a păstra
                            confidențialitatea informațiilor.
                        </p>
                    </div>
                </div>
                <div>
                    <div style={{fontWeight: "bold"}}>
                        <span>6. </span>
                        <span>Cookie-uri</span>
                    </div>
                    <div>
                        <p>
                            Nu folosim cookie-uri.
                        </p>
                    </div>
                </div>
                <div>
                    <div style={{fontWeight: "bold"}}>
                        <span>7. </span>
                        <span>Pastrarea datelor</span>
                    </div>
                    <div>
                        <p>
                            Toate datele introduse de dvs. în sistemul nostry vor fi șterse imediat ce contul dvs.
                            MenuOrders este șters.
                        </p>
                        <p>
                            Datele cu privire la informațiile despre contul dvs. pot fi păstrate mai multi ani în
                            conformitate cu reglementările fiscale.
                        </p>
                        <p>
                            Ne rezervam dreptul de a nu șterge informații sau evidențe personale, dacă suntem obligați
                            de
                            lege să păstrăm acele informații sau acele evidențe.
                        </p>
                    </div>
                </div>
                <div>
                    <div style={{fontWeight: "bold"}}>
                        <span>8. </span>
                        <span>Conformitate GDPR</span>
                    </div>
                    <div>
                        <p>
                            Datorită alinierii cu legislația europeana a prevederilor privind protecția datelor cu
                            caracter
                            personal, MenuOrders implementează un proiect intern de aliniere, folosește metode tehnice
                            și
                            organizaționale de securitate conforme cu prevederile GDPR pentru protejarea datelor
                            personale
                            prelucrate.
                        </p>
                        <p>
                            Orice persoană fizica ale cărei date personale au fost procesate de către MenuOrders va avea
                            dreptul sa:
                        </p>
                        <p>
                            Acceseze datele personale procesate;
                            <ul>
                                <li>
                                    Trimită o solicitare către MenuOrders pentru a corecta datele personale procesate;
                                </li>
                                <li>
                                    Trimită o solicitare către MenuOrders pentru a fi înlăturate datele sale din baza de
                                    date;
                                </li>
                                <li>
                                    Obiecteze împotriva procesării datelor personale de către MenuOrders;
                                </li>
                                <li>
                                    Înainteze o plângere către autoritatea de monitorizare;
                                </li>
                            </ul>
                        </p>
                        <p>
                            Adresa de contact pentru MenuOrders, referitoare la GDPR este suport-clienti@menuorders.ro
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}