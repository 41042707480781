import {ReceiptQuantitiveModelProps} from "./receiptQuantitiveModel.types";
import './styles.scss';
import React, {useEffect, useState} from "react";
import {ReceiptProduct} from "../../../../../Models/model/ReceiptProduct";
import {equalsTemplates, ReceiptTemplate} from "../../../../../Models/backend/ReceiptTemplate";
import {DatePicker, Input} from "antd";
import {mergeStyles} from "@fluentui/react";
import {ExcelTableRow} from "./excelTableRow";
import {twoDecimals} from "../../../../../Library/utils/mathUtil";


let dateFormat = "DD.MM.YYYY";
const textareaStyle: string = mergeStyles({
    border: "none",
    height: "100%",
    width: "100%",
    selectors: {
        ":focus": {
            border: "none",
            outline: "none"
        },
        ":hover": {
            userFocus: "none",
        }
    }
})
export const ReceiptQuantitiveModel = (props: ReceiptQuantitiveModelProps): JSX.Element => {

    const [gestiunea, setGestiunea] = useState<string>();
    const [unitatea, setUnitatea] = useState<string>()
    const [excess, setExcess] = useState<number>()
    const [committee, setCommittee] = useState<string>()
    const [manager, setManager] = useState<string>()

    const [totalBuy, setTotalBuy] = useState<number>()
    const [totalSellWithVat, setTotalSellWithVat] = useState<number>()

    const [map, setMap] = useState<Map<number, ReceiptProduct>>()

    useEffect(() => {
        let r = props.receiptTemplate;
        setGestiunea(r ? r.managementName : "")
        setManager(r ? r.managerName : "")
        setUnitatea(r ? r.businessName : "")
        setCommittee(r ? r.receiptCommitteeNames : "")
        setExcess(r ? r.commercialExcess : 0)
    }, [props.receiptTemplate])

    function refreshTable() {
        let number = props.receiptProducts
            .reduce((total, product) => total + product.field6, 0);
        setTotalBuy(twoDecimals(number))

        const totalField7 = props.receiptProducts
            .reduce((total, product) => total + product.field7, 0);
        setTotalSellWithVat(twoDecimals(totalField7))
    }

    useEffect(() => {
        refreshTable();
    }, [props.receiptProducts, map])

    useEffect(() => {
        const growers = document.querySelectorAll(".grow-wrap");

        growers.forEach((grower) => {
            const textarea = grower.querySelector("textarea")!;
            textarea.addEventListener("input", () => {
                // @ts-ignore
                grower.dataset.replicatedValue = textarea.value;
            });
        });
    }, [])

    function handleReceiptProductChange(product: ReceiptProduct) {
        if (map === undefined) {
            setMap(new Map<number, ReceiptProduct>())
            return
        }
        map.set(product.field0, product)
        let iterableIterator = map.values();
        let newProds = Array.from(iterableIterator);
        props.setReceiptProducts(newProds)
    }

    useEffect(() => {
        const newTemplate: ReceiptTemplate = {
            templateName: "<custom>",
            businessName: unitatea,
            managementName: gestiunea,
            commercialExcess: excess,
            receiptCommitteeNames: committee,
            managerName: manager,
        }
        let templateName = props.receiptTemplate
        if (!equalsTemplates(newTemplate, templateName)) {
            props.setCustomReceiptTemplate(newTemplate)
        }
    }, [unitatea, gestiunea, excess, committee, manager])


    return (
        <div id={"quantitive-table"}>
            <table border={0} cellPadding="0" cellSpacing="0" id="sheet0" className="sheet0 gridlines">
                <tbody>
                <tr className="row0">
                    <td className="column0 style7 null"></td>
                    <td className="column1 style7 null"></td>
                    <td className="column2 style7 null"></td>
                    <td className="column3 style7 null"></td>
                    <td className="column4 style7 null"></td>
                    <td className="column5 style7 null"></td>
                    <td className="column6 style7 null"></td>
                    <td className="column7 style7 null"></td>
                    <td className="column8 style7 null"></td>
                    <td className="column9 style7 null"></td>
                    <td className="column10 style7 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row1">
                    <td className="column0 style7 null"></td>
                    <td className="column1 style7 null"></td>
                    <td className="column2 style53 s style37"
                        colSpan={7}>
                        Nota de receptie si constatare
                        diferente
                    </td>
                    <td className="column9 style7 null"></td>
                    <td className="column10 style7 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row2">
                    <td className="column0 style7 null"></td>
                    <td className="column1 style7 null"></td>
                    <td className="column2 style7 null"></td>
                    <td className="column3 style7 null"></td>
                    <td className="column4 style9 null"></td>
                    <td className="column5 style7 null"></td>
                    <td className="column6 style7 null"></td>
                    <td className="column7 style7 null"></td>
                    <td className="column8 style7 null"></td>
                    <td className="column9 style7 null"></td>
                    <td className="column10 style7 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row3">
                    <td className="column0 style7 null"></td>
                    <td className="column1 style8 s">Unitatea:</td>
                    <td className="column2 style32 s style32"
                        colSpan={3}>
                        <Input value={unitatea}
                               onInput={(e) => {
                                   setUnitatea(e.currentTarget.value)
                                   props.setHasChanged(true)
                               }}/>
                    </td>
                    <td className="column5 style7 null"></td>
                    <td className="column6 style7 null"></td>
                    <td className="column7 style8 s">Nr. NIR:</td>
                    <td className="column8 style22 s">
                        {props.nir}
                    </td>
                    <td className="column9 style10 null"></td>
                    <td className="column10 style7 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row4">
                    <td className="column0 style7 null"></td>
                    <td className="column1 style8 s">Gestiunea:</td>
                    <td className="column2 style32 s style32"
                        colSpan={3}>
                        <Input value={gestiunea}
                               onInput={(e) => {
                                   setGestiunea(e.currentTarget.value)
                                   props.setHasChanged(true)
                               }}/>
                    </td>
                    <td className="column5 style7 null"></td>
                    <td className="column6 style7 null"></td>
                    <td className="column7 style8 s">Data:</td>
                    <td className="column8 style22 s">
                        <DatePicker format={dateFormat}
                                    defaultValue={props.date}
                                    onChange={(e) => {
                                        props.setDate(e!)
                                        props.setHasChanged(true)
                                    }}
                        />
                    </td>
                    <td className="column9 style10 null"></td>
                    <td className="column10 style7 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row5">
                    <td className="column0 style7 null"></td>
                    <td className="column1 style7 null"></td>
                    <td className="column2 style7 null"></td>
                    <td className="column3 style7 null"></td>
                    <td className="column4 style7 null"></td>
                    <td className="column5 style7 null"></td>
                    <td className="column6 style7 null"></td>
                    <td className="column7 style7 null"></td>
                    <td className="column8 style7 null"></td>
                    <td className="column9 style7 null"></td>
                    <td className="column10 style7 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row6">
                    <td className="column0 style7 null"></td>
                    <td className="column1 style54 s style54"
                        colSpan={10} rowSpan={3}>
                        Subsemnatii, membrii ai comisiei de receptie, am receptionat valorile materiale furnizate de:
                        ...{props.provider}..., delegat: ……………….., auto nr.: ……………………, pe baza documentelor insotitoare:
                        ……………….., constatand:
                    </td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row7">
                    <td className="column0 style7 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row8">
                    <td className="column0 style7 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row9">
                    <td className="column0 style7 null"></td>
                    <td className="column1 style7 null"></td>
                    <td className="column2 style7 null"></td>
                    <td className="column3 style7 null"></td>
                    <td className="column4 style7 null"></td>
                    <td className="column5 style7 null"></td>
                    <td className="column6 style7 null"></td>
                    <td className="column7 style7 null"></td>
                    <td className="column8 style7 null"></td>
                    <td className="column9 style7 null"></td>
                    <td className="column10 style7 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row10">
                    <td className="column0 style23 s style23"
                        rowSpan={2}>Nr. Crt.
                    </td>
                    <td className="column1 style24 s style24"
                        colSpan={3} rowSpan={2}>Denumire produse sau a
                        servicii /Cod
                    </td>
                    <td className="column4 style24 s style24"
                        rowSpan={2}>U.M.
                    </td>
                    <td className="column5 style25 s style25"
                        rowSpan={2}>Cantitate inscrisa in doc.
                    </td>
                    <td className="column6 style26 s style26"
                        rowSpan={2}>Cantitate primita
                    </td>
                    <td className="column7 style26 s style26"
                        rowSpan={2}>P.U. achizitie <br/>
                        (fara TVA)
                    </td>
                    <td className="column8 style26 s style27"
                        rowSpan={2}>Val. achizitie<br/>
                        (fara TVA)
                    </td>
                    <td className="column9 style26 s style27"
                        rowSpan={2}>Val. TVA<br/>
                        (aferenta)
                    </td>
                    <td className="column10 style26 s style27"
                        rowSpan={2}>Camp info. suplimentare
                    </td>
                    <td className="column11 style8 null"></td>
                </tr>
                <tr className="row11">
                    <td className="column11 style8 null"></td>
                </tr>
                <tr className="row12">
                    <td className="column0 style1 n">0</td>
                    <td className="column1 style28 n style28"
                        colSpan={3}>1
                    </td>
                    <td className="column4 style1 n">2</td>
                    <td className="column5 style1 n">3</td>
                    <td className="column6 style1 n">4</td>
                    <td className="column7 style1 n">5</td>
                    <td className="column8 style1 s">6(=4x5)</td>
                    <td className="column9 style1 s">7(=6xTVA%)</td>
                    <td className="column10 style1 n">8</td>
                    <td className="column11 style7 null"></td>
                </tr>
                {
                    props.products.map((p, index) => (
                        <ExcelTableRow product={p}
                                       key={index}
                                       index={index}
                                       commercialExcess={excess}
                                       setHasChanged={props.setHasChanged}
                                       handleReceiptProductChange={handleReceiptProductChange}
                        />
                    ))
                }

                <tr className="row14">
                    <td className="column0 style6 null"></td>
                    <td className="column1 style7 null"></td>
                    <td className="column2 style7 null"></td>
                    <td className="column3 style7 null"></td>
                    <td className="column4 style7 null"></td>
                    <td className="column5 style14 s">Total</td>
                    <td className="column6 style15 s">-</td>
                    <td className="column7 style16 s">-</td>
                    <td className="column8 style16 f">{totalBuy}</td>
                    <td className="column9 style16 f">{totalSellWithVat}</td>
                    <td className="column10 style21 s">-</td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row15">
                    <td className="column0 style6 null"></td>
                    <td className="column1 style7 null"></td>
                    <td className="column2 style7 null"></td>
                    <td className="column3 style7 null"></td>
                    <td className="column4 style7 null"></td>
                    <td className="column5 style11 null"></td>
                    <td className="column6 style12 null"></td>
                    <td className="column7 style13 null"></td>
                    <td className="column8 style13 null"></td>
                    <td className="column9 style13 null"></td>
                    <td className="column10 style13 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row16">
                    <td className="column0 style6 null"></td>
                    <td className="column1 style8 s">Observatii:</td>
                    <td className="column2 style42 null style50"
                        colSpan={9} rowSpan={3}>
                        <div className={"grow-wrap"}>
                            <textarea className={textareaStyle}
                                      value={props.observations || ''}
                                      onChange={(e) => {
                                          props.setObservations(e.currentTarget.value)
                                          props.setHasChanged(true)
                                      }}/>
                        </div>
                    </td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row17">
                    <td className="column0 style6 null"></td>
                    <td className="column1 style8 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row18">
                    <td className="column0 style7 null"></td>
                    <td className="column1 style7 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row19">
                    <td className="column0 style7 null"></td>
                    <td className="column1 style7 null"></td>
                    <td className="column2 style7 null"></td>
                    <td className="column3 style7 null"></td>
                    <td className="column4 style7 null"></td>
                    <td className="column5 style7 null"></td>
                    <td className="column6 style7 null"></td>
                    <td className="column7 style7 null"></td>
                    <td className="column8 style7 null"></td>
                    <td className="column9 style7 null"></td>
                    <td className="column10 style7 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row20">
                    <td className="column0 style7 null"></td>
                    <td className="column1 style51 s style52"
                        colSpan={4}>Numele si prenumele membrilor
                        comisiei de receptie
                    </td>
                    <td className="column5 style17 s">Semnatura</td>
                    <td className="column6 style55 s style57"
                        colSpan={4}>Numele si prenumele gestionarului
                    </td>
                    <td className="column10 style17 s">Semnatura</td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row21">
                    <td className="column0 style7 null"></td>
                    <td className="column1 style33 null style41"
                        colSpan={4} rowSpan={3}>
                        <div className={"grow-wrap"}>
                            <textarea className={textareaStyle}
                                      value={committee||""}
                                      onChange={(e) => {
                                          setCommittee(e.currentTarget.value)
                                          props.setHasChanged(true)
                                      }}/>
                        </div>
                    </td>
                    <td className="column5 style18 null"></td>
                    <td className="column6 style33 null style41"
                        colSpan={4} rowSpan={3}>
                        <div className={"grow-wrap"}>
                            <textarea className={textareaStyle}
                                      value={manager||""}
                                      onChange={(e) => {
                                          setManager(e.currentTarget.value)
                                          props.setHasChanged(true)
                                      }}/>
                        </div>
                    </td>
                    <td className="column10 style19 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row22">
                    <td className="column0 style7 null"></td>
                    <td className="column5 style19 null"></td>
                    <td className="column10 style19 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row23">
                    <td className="column0 style7 null"></td>
                    <td className="column5 style20 null"></td>
                    <td className="column10 style20 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row24">
                    <td className="column0 style7 null"></td>
                    <td className="column1 style7 null"></td>
                    <td className="column2 style7 null"></td>
                    <td className="column3 style7 null"></td>
                    <td className="column4 style7 null"></td>
                    <td className="column5 style7 null"></td>
                    <td className="column6 style7 null"></td>
                    <td className="column7 style7 null"></td>
                    <td className="column8 style7 null"></td>
                    <td className="column9 style7 null"></td>
                    <td className="column10 style7 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                <tr className="row25">
                    <td className="column0 style7 null"></td>
                    <td className="column1 style7 null"></td>
                    <td className="column2 style7 null"></td>
                    <td className="column3 style7 null"></td>
                    <td className="column4 style7 null"></td>
                    <td className="column5 style7 null"></td>
                    <td className="column6 style7 null"></td>
                    <td className="column7 style7 null"></td>
                    <td className="column8 style7 null"></td>
                    <td className="column9 style7 null"></td>
                    <td className="column10 style7 null"></td>
                    <td className="column11 style7 null"></td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}