export interface StatusCode {
    code: number
    message: string | undefined
}

const statusCodes: Map<number, StatusCode> = new Map<number, StatusCode>()

statusCodes.set(499, {code: 499, message: "Mesajul nu a putut fi trimis"})
statusCodes.set(458, {code: 458, message: "Valabilitatea contului a expirat"})
statusCodes.set(209, {code: 209, message: "Exista deja un obiect cu acest nume"})
statusCodes.set(210, {code: 210, message: "Exista deja un cont cu acest email"})
statusCodes.set(503, {code: 503, message: "Nu s-a putut crea o conexiune cu serverul"})

export function getStatusCode(code: number): StatusCode | undefined {
    return statusCodes.get(code)
}