import {ChangeEvent, Dispatch, SetStateAction} from "react";

export async function readImageSrc(e: ChangeEvent<HTMLInputElement>, setImageSrc: Dispatch<SetStateAction<ArrayBuffer | undefined>>) {
    let files = e.target.files;
    if (files === null) {
        return
    }
    let file = e.target.files!.item(0);
    if (file === null) {
        return
    }
    var reader = new FileReader();
    reader.onload = function (event) {
        let bytes = event.target!.result! as ArrayBuffer;
        setImageSrc(bytes)
    };
    reader.readAsDataURL(file);
}

export async function readImageSrc0(
    e: ChangeEvent<HTMLInputElement>,
    setImageSrc: (imageSrc: ArrayBuffer | undefined) => Promise<void>
) {
    let files = e.target.files;
    if (files === null) {
        return
    }
    let file = e.target.files!.item(0);
    if (file === null) {
        return
    }
    var reader = new FileReader();
    reader.onload = function (event) {
        let bytes = event.target!.result! as ArrayBuffer;
        setImageSrc(bytes)
    };
    reader.readAsDataURL(file);
}