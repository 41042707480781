import {Menu} from "../../../Models/backend/Menu";
import {useEffect, useState} from "react";
import {Image, Modal} from "antd";
import {MenuProduct} from "../../../Models/backend/MenuProduct";
//@ts-ignore
import emptyImage from "../../../Assets/Images/download.jpg";
import {TextAreaShowMoreOrLess} from "../../../Components/TextAreaShowMoreOrLess/textAreaMultipleLines";
import {computeMediaFileContent} from "../../../Library/utils/arrayBuffer";

export const MenuCardContent = (props: { menu: Menu, index: number }): JSX.Element => {


    return (
        <div>
            <div className={"h6 mb-0"}><b>Descriere:</b></div>
            <TextAreaShowMoreOrLess description={props.menu.description}/>
            <div>
                <div className={"h6 my-2"}><b>Preparate:</b></div>
            </div>
            <div className={"d-flex"} style={{gap: "5px", flexWrap: "wrap"}}>
                {
                    props.menu.items?.map((el, index) => (
                        <Element key={index} menuProduct={el}/>
                    ))
                }
            </div>
        </div>
    )
}

const Element = (props: { menuProduct: MenuProduct }): JSX.Element => {
    const [visible, setVisible] = useState(false);
    const [imageSrc, setImageSrc] = useState<string>();

    useEffect(() => {
        let media = props.menuProduct.media;
        if (media === undefined) {
            setImageSrc(emptyImage)
            return
        }
        let bytes = media.bytes;
        if (bytes === undefined || bytes as string === "") {
            setImageSrc(emptyImage)
            return
        }
        setImageSrc(computeMediaFileContent(bytes, "image"))
    }, [props.menuProduct])

    return (
        <div className={"border border-basic rounded px-2 py-1 pointer"}>
            <div onClick={() => setVisible(true)}>
                {props.menuProduct.name}
            </div>
            <Modal title={<h3>{props.menuProduct.name}</h3>}
                   open={visible}
                   footer={null}
                   onCancel={() => setVisible(false)}
                   width={"80%"}
            >
                <div className={"d-flex"} style={{gap: "20px"}}>
                    <div style={{flex: "1"}}>
                        <Image src={imageSrc} style={{minWidth: "50%"}}/>
                    </div>
                    <div style={{flex: "1", fontSize: "1.1rem"}}>
                        <div>
                            <b>Descriere:</b>
                        </div>
                        <div style={{whiteSpace: "pre-line"}}>
                            {props.menuProduct.description}
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}