import {Button, Divider} from "antd";
import {Outlet} from "react-router-dom";

export const Menu = (props: { title: string, btnAction: () => void, buttonText: string }): React.JSX.Element => {


    return (
        <div className={"padding-responsive"}>
            <div className={" d-flex justify-content-between"}>
                <div className={"h2 mb-3"}>
                    {props.title}
                </div>
                <div>
                    <Button type={"primary"}
                            onClick={() => props.btnAction()}
                            className={"shadow"}
                    >
                        {props.buttonText}
                    </Button>
                </div>
            </div>
            <Divider className={"m-0 mb-3"}/>
            <Outlet/>
        </div>
    )
}
