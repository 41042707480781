import {createContext} from 'react';
import {
    AUTH_ENDPOINT,
    CONTACT_ENDPOINT,
    LOGIN_ENDPOINT,
    MENU_ENDPOINT,
    MENU_PRODUCTS_ENDPOINT,
    ORDERS_ENDPOINT,
    PRIME_PRODUCT_ENDPOINT,
    PROVIDER_ENDPOINT,
    RECEIPT_TEMPLATES_ENDPOINT,
    RECEIPTS_ENDPOINT,
    SETTINGS_ENDPOINT,
    USER_ENDPOINT
} from "../Enums/endpoints";
import LoginService from "../Services/loginService";
import AuthService from "../Services/authService";
import UserService from "../Services/userService";
import ContactService from "../Services/contactService";
import ProviderService from "../Services/providerService";
import PrimeProductService from "../Services/primeProductService";
import OrderService from "../Services/orderService";
import ReceiptTemplateService from "../Services/receiptTemplateService";
import SettingsService from "../Services/settingsService";
import ReceiptService from "../Services/receiptService";
import MenuService from "../Services/menuService";
import MenuProductService from "../Services/menuProductService";

export class ServiceContext {
    private _loginService?: LoginService;
    private _authService?: AuthService;
    private _userService?: UserService;
    private _contactService?: ContactService;
    private _providerService?: ProviderService;
    private _primeProductService?: PrimeProductService;
    private _orderService?: OrderService;
    private _receiptTemplateService?: ReceiptTemplateService;
    private _settingsService?: SettingsService;
    private _receiptsService?: ReceiptService;
    private _menuService?: MenuService;
    private _menuProductsService?: MenuProductService;

    get LoginService(): LoginService {
        if (this._loginService == null) {
            this._loginService = new LoginService(LOGIN_ENDPOINT);
        }
        return this._loginService;
    };

    get AuthService(): AuthService {
        if (this._authService == null) {
            this._authService = new AuthService(AUTH_ENDPOINT);
        }
        return this._authService;
    };

    get UserService(): UserService {
        if (this._userService == null) {
            this._userService = new UserService(USER_ENDPOINT);
        }
        return this._userService;
    };

    get ContactService(): ContactService {
        if (this._contactService == null) {
            this._contactService = new ContactService(CONTACT_ENDPOINT);
        }
        return this._contactService;
    };

    get ProviderService(): ProviderService {
        if (this._providerService == null) {
            this._providerService = new ProviderService(PROVIDER_ENDPOINT);
        }
        return this._providerService;
    };

    get PrimeProductService(): PrimeProductService {
        if (this._primeProductService == null) {
            this._primeProductService = new PrimeProductService(PRIME_PRODUCT_ENDPOINT);
        }
        return this._primeProductService;
    };

    get OrderService(): OrderService {
        if (this._orderService == null) {
            this._orderService = new OrderService(ORDERS_ENDPOINT);
        }
        return this._orderService;
    };

    get ReceiptTemplateService(): ReceiptTemplateService {
        if (this._receiptTemplateService == null) {
            this._receiptTemplateService = new ReceiptTemplateService(RECEIPT_TEMPLATES_ENDPOINT);
        }
        return this._receiptTemplateService;
    };

    get SettingsService(): SettingsService {
        if (this._settingsService == null) {
            this._settingsService = new SettingsService(SETTINGS_ENDPOINT);
        }
        return this._settingsService;
    };

    get ReceiptService(): ReceiptService {
        if (this._receiptsService == null) {
            this._receiptsService = new ReceiptService(RECEIPTS_ENDPOINT);
        }
        return this._receiptsService;
    };

    get MenuService(): MenuService {
        if (this._menuService == null) {
            this._menuService = new MenuService(MENU_ENDPOINT);
        }
        return this._menuService;
    };

    get MenuProductService(): MenuProductService {
        if (this._menuProductsService == null) {
            this._menuProductsService = new MenuProductService(MENU_PRODUCTS_ENDPOINT);
        }
        return this._menuProductsService;
    };

}

export const ServiceContextInstance = createContext(new ServiceContext());