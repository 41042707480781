import {Button, Divider, Form, Input, message, Skeleton} from "antd";
import {useContext, useEffect, useState} from "react";
import {ChangePassword} from "../ChangePassword/changePassword";
import {ServiceContext, ServiceContextInstance} from "../../Core/serviceContext";
import {useFetch} from "../../Hooks/useFetch";
import {IFetchResult} from "../../Hooks/useFetch.types";
import {UserDb} from "../../Models/backend/UserDb";
import dayjs from "dayjs";

export const Dashboard = (): React.JSX.Element => {
    const [formPersonalData] = Form.useForm();

    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const userData: IFetchResult<UserDb> = useFetch<UserDb>(() => services.UserService.getUser(), []);
    const [user, setUser] = useState<UserDb>()
    const [email, setEmail] = useState<string>()
    const [phone, setPhone] = useState<string>()
    const [address, setAddress] = useState<string>()
    const [businessName, setBusinessName] = useState<string>()
    const [availableUntil, setAvailableUntil] = useState<string>()
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false)

    const [changesMade, setChangesMade] = useState<boolean>(false)

    const [loadedData, setLoadedData] = useState<boolean>(false)

    useEffect(() => {
        if (userData.isLoading) {
            return;
        }
        if (userData.errors !== '' ||
            userData.data === null ||
            userData.data?.Error !== undefined ||
            userData.data?.Data === undefined) {
            return;
        }
        setUser(userData.data.Data);
        setLoadedData(true)
    }, [userData])

    useEffect(() => {
        if (user === undefined) {
            return
        }
        formPersonalData.setFieldValue('email', user.email)
        setEmail(user.email)
        formPersonalData.setFieldValue('businessName', user.businessName)
        setBusinessName(user.businessName)
        formPersonalData.setFieldValue('address', user.address)
        setAddress(user.address)
        formPersonalData.setFieldValue('phone', user.phone)
        setPhone(user.phone)
        setAvailableUntil(user.availableUntil)
    }, [user]);

    useEffect(() => {
        if (user === undefined) {
            return
        }
        let b1 = (email ?? "").trim() === (user.email ?? "").trim();
        let b2 = (phone ?? "").trim() === (user.phone ?? "").trim();
        let b3 = (address ?? "").trim() === (user.address ?? "").trim();
        let b4 = (businessName ?? "").trim() === (user.businessName ?? "").trim();
        if (b1 && b2 && b3 && b4) {
            setChangesMade(false)
        } else {
            setChangesMade(true)
        }
    }, [email, phone, address, businessName, user]);

    async function saveChanges() {
        setLoadingRequest(true)
        let email0: string | undefined | null = email;
        if (email?.trim() === "" && user?.email !== null && user?.email !== undefined && user?.email.trim() !== "") {
            email0 = null
        }
        let response = await services.UserService.update(email0, phone, address, businessName);
        setLoadingRequest(false)
        let data = response.Data;
        if (response.Error !== undefined || data === undefined) {
            if (response.Message !== undefined) {
                message.error(response.Message)
                return
            }
            message.error("Datele nu au putut fi actualizate!")
            return
        }
        message.success("Datele au fost modificate!")
        setUser(data)
    }

    return (
        <>
            {
                !loadedData ?
                    (
                        <div className={"p-5"}>
                            <Skeleton active={true} paragraph={
                                {rows: 10}
                            }/>
                            <Skeleton active={true} paragraph={
                                {rows: 6}
                            }/>
                        </div>

                    ) :
                    (
                        <div id={"dashboard"} className={"padding-responsive"}>
                            <div className={"profile-info col-md-12"}>
                                <div className={"mb-3"}>
                                    <h1>
                                        Informații profil
                                    </h1>
                                    <Divider className={"m-0"}/>
                                </div>
                                <div className={"d-flex justify-content-between"}>
                                    <div className={"mb-3"}>
                                        Valabilitate cont: până
                                        la <span>{dayjs(availableUntil).format("DD.MM.YYYY")}</span>
                                    </div>

                                </div>
                                <Form
                                    form={formPersonalData}
                                    labelCol={{span: 6}}
                                    wrapperCol={{span: 20}}
                                    onFinish={saveChanges}
                                    style={{maxWidth: 600}}
                                    validateMessages={{
                                        types: {
                                            email: 'Adresa de email nu este valida'
                                        }
                                    }}
                                >
                                    <Form.Item name={'businessName'}
                                               label="Denumire afacere"
                                    >
                                        <Input value={businessName}
                                               placeholder={"Introduceti numele afacerii"}
                                               size={"middle"}
                                               onChange={(e) => setBusinessName(e.currentTarget.value)}
                                        />
                                    </Form.Item>
                                    <Form.Item name={'email'}
                                               label={<span>Email <span className="material-symbols-outlined"
                                                                        style={{fontSize: "1rem"}}
                                                                        title={"Adresa de email va fi folosita pentru schimbarea parolei in caz ca aceasta este uitata sau pentru comunicarea informatiilor referitoare la statusul contului."}>help</span></span>}
                                               rules={[{type: 'email'}]}>
                                        <Input value={email}
                                               placeholder={"Adresa de email"}
                                               size={"middle"}
                                               onChange={(e) => setEmail(e.currentTarget.value)}
                                        />
                                    </Form.Item>
                                    <Form.Item name={'phone'}
                                               label="Telefon">
                                        <Input value={phone}
                                               placeholder={"Numarul de telefon"}
                                               type={"tel"}
                                               size={"middle"}
                                               onChange={(e) => setPhone(e.currentTarget.value)}
                                        />
                                    </Form.Item>
                                    <Form.Item name={'address'}
                                               label="Adresa">
                                        <Input value={address}
                                               placeholder={"Adresa afacerii / adresa de livrare"}
                                               size={"middle"}
                                               onChange={(e) => setAddress(e.currentTarget.value)}
                                        />
                                    </Form.Item>
                                    <Form.Item wrapperCol={{offset: 6}}>
                                        <Button type="primary"
                                                htmlType="submit"
                                                loading={loadingRequest}
                                                disabled={!changesMade}
                                        >
                                            Salvează modificările
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                            <ChangePassword className={"col-md-12"}/>
                        </div>
                    )
            }
        </>
    )
}
