import {Button, Form, Input, message} from "antd";
import {useContext, useEffect, useState} from "react";
import {ServiceContext, ServiceContextInstance} from "../../Core/serviceContext";

export const ChangePassword = (props: { className?: string }): React.JSX.Element => {
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [form] = Form.useForm();

    const [oldPass, setOldPass] = useState<string>("")
    const [newPass, setNewPass] = useState<string>("")
    const [passAreSame, setPassAreSame] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)

    const formItemLayout = {
        labelCol: {
            xs: {span: 6},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 20},
            sm: {span: 20},
        },
    };

    async function changePassword() {
        setLoading(true)
        let response = await services.AuthService.changePassword(oldPass, newPass);
        let data = response.Data;
        setLoading(false)
        if (response.Error !== undefined || data === undefined) {
            message.error("Parola nu a putut fi schimbată!")
            return
        }
        message.success(data)
        setOldPass("")
        setNewPass("")
        form.resetFields()
    }

    useEffect(() => {
        if (newPass !== undefined) {
            setPassAreSame(false)
        }
    }, [newPass])

    return (
        <div className={`mt-4 ${props.className}`}>
            <div className={"h2 mb-2"}>
                Schimbă parola
            </div>
            <Form
                labelCol={{span: 6}}
                wrapperCol={{span: 20}}
                form={form}
                scrollToFirstError
                style={{maxWidth: 600}}
                onFinish={changePassword}
            >
                <Form.Item
                    name="oldPassword"
                    label="Parola veche"
                    rules={[
                        {
                            required: true,
                            message: 'Introdu parola veche',
                        },
                    ]}

                >
                    <Input.Password value={oldPass}
                                    placeholder={"Introdu parola veche"}
                                    onChange={(e) => setOldPass(e.currentTarget.value)}
                    />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    label="Noua parola"
                    rules={[
                        {
                            required: true,
                            message: 'Introdu parola noua',
                        },
                    ]}
                >
                    <Input.Password value={newPass} 
                                    placeholder={"Introdu parola noua"}
                                    onChange={(e) => setNewPass(e.currentTarget.value)}
                    />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Confirma parola"
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Confirma noua parola',
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    setPassAreSame(true)
                                    return Promise.resolve();
                                }
                                setPassAreSame(false)
                                return Promise.reject(new Error('Parolele nu se potrivesc!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder={"Confirma parola noua"}
                    />
                </Form.Item>
                <Form.Item wrapperCol={{offset: 6}}>
                    <Button type={"primary"} htmlType="submit"
                            disabled={!passAreSame}
                            loading={loading}
                    >
                        Schimbă parola
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}