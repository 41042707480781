import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import {MenuProduct} from "../../../Models/backend/MenuProduct";
import {Card, message, Popconfirm, Spin, Tooltip} from "antd";
import {ServiceContext, ServiceContextInstance} from "../../../Core/serviceContext";
import {MenuProductCardContent} from "./menuProductCardContent";
import {QuestionCircleOutlined} from "@ant-design/icons";
//@ts-ignore
import Highlighter from "react-highlight-words";
import {DeletionsForProductResponse} from "../../../Models/model/DeletionsForProductResponse";

export const MenuProductCard = (props: {
    menuProduct: MenuProduct;
    index: number;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    setOperation: Dispatch<SetStateAction<(menuProduct: MenuProduct) => Promise<boolean>>>;
    setModalType: Dispatch<SetStateAction<"create" | "update">>;
    setSelectedMenuProductToUpdate: Dispatch<SetStateAction<MenuProduct | undefined>>;
    searchText: string | undefined;
    refreshProducts: () => void;
}): JSX.Element => {

    const services: ServiceContext = useContext(ServiceContextInstance);

    const [possibleDeletions, setPossibleDeletions] = useState<DeletionsForProductResponse | undefined>();


    async function findPossibleDeletions(id: string): Promise<void> {
        let response = await services.MenuProductService.findPossibleMenuDeletions(id);
        if (response.Error !== undefined) {
            message.warning("Nu s-au putut găsi meniurile posibile de sters")
            return
        }
        setPossibleDeletions(response.Data)
    }

    async function deleteProduct() {
        let response = await services.MenuProductService.delete(props.menuProduct.id!);
        if (response.Error !== undefined || response.Data === undefined) {
            message.error("Nu s-a putut sterge preparatul")
            return
        }
        message.success("Preparatul a fost sters!")
        props.refreshProducts()
    }

    async function updateMenuProduct(menuProduct: MenuProduct): Promise<boolean> {
        let response = await services.MenuProductService.update(menuProduct);
        if (response.Error !== undefined || response.Data === undefined) {
            message.error("Nu s-a putut modifica preparatul")
            return false
        }
        message.success("Preparatul a fost modificat!")
        return true
    }

    function f() {
        props.setSelectedMenuProductToUpdate(props.menuProduct)
    }

    return (
        <Tooltip
            title={
                tooltipTitle(
                    props.setShowModal,
                    deleteProduct,
                    props.setOperation,
                    services,
                    props.setModalType,
                    f,
                    updateMenuProduct,
                    possibleDeletions
                )
            }
            onOpenChange={(open) => {
                if (!open) {
                    setPossibleDeletions(undefined)
                } else {
                    findPossibleDeletions(props.menuProduct.id!)
                }
            }}
        >
            <Card size="small"
                  title={
                      <div>
                          <span style={{fontSize: "1.2rem"}}>
                              {
                                  props.searchText !== undefined && props.searchText.trim() !== "" ?
                                      (
                                          <Highlighter
                                              highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                                              searchWords={[props.searchText]}
                                              autoEscape
                                              textToHighlight={props.menuProduct.name ? props.menuProduct.name.toString() : ''}
                                          />
                                      ) :
                                      (
                                          props.menuProduct.name
                                      )
                              }
                          </span>
                      </div>
                  }
                  extra={<span>pret: {props.menuProduct.price} ron</span>}
                  className={"shadow"}
            >
                <MenuProductCardContent menuProduct={props.menuProduct} index={props.index}/>
            </Card>
        </Tooltip>
    )
}

function tooltipTitle(
    setShowModal: (value: (((prevState: boolean) => boolean) | boolean)) => void,
    deleteItem: () => Promise<void>,
    setOperation: Dispatch<SetStateAction<(menuProduct: MenuProduct) => Promise<boolean>>>,
    services: ServiceContext,
    setModalType: Dispatch<SetStateAction<"create" | "update">>,
    selectProduct: () => void,
    updateMenuProduct: (menuProduct: MenuProduct) => Promise<boolean>,
    possibleDeletions: DeletionsForProductResponse | undefined
) {

    function showModal() {
        setOperation(
            () => {
                return (menuProduct: MenuProduct): Promise<boolean> => {
                    return updateMenuProduct(menuProduct)
                }
            }
        )
        setModalType("update")
        selectProduct()
        setShowModal(true)
    }


    return <div>
        <i className="fa-solid fa-square-pen fa-2x pointer me-3 hover-btn-primary"
           onClick={showModal}></i>
        <Popconfirm
            title="Sterge preparatul"
            description={
                <div>
                    <div>Vrei să ștergi preparatul?</div>
                    <div style={{maxWidth:"350px"}}>
                        <div>
                            {possibleDeletions === undefined && <Spin/>}
                            {possibleDeletions !== undefined && (possibleDeletions.menuProducts.length > 0 || possibleDeletions.menus.length > 0) && (
                                <div>
                                    <div>
                                        Vor fi șterge și meniurile care conțin acest preparat
                                    </div>
                                    <div>
                                        Meniurile
                                    </div>
                                    <div>
                                        <ul>
                                            {possibleDeletions.menus.map((menu, index) => (
                                                <li key={index}>{menu}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }
            icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
            okText={"Da"}
            cancelText={"Nu"}
            onConfirm={deleteItem}
            placement={"topLeft"}
            arrow={false}
        >
            <i className="fa-solid fa-trash fa-2x pointer hover-btn-danger"></i>
        </Popconfirm>
    </div>;
}