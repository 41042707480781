import {MenuProduct} from "../../../Models/backend/MenuProduct";
import {Image, message, Tooltip} from 'antd';
//@ts-ignore
import emptyImage from '../../../Assets/Images/download.jpg';
import {TextAreaShowMoreOrLess} from "../../../Components/TextAreaShowMoreOrLess/textAreaMultipleLines";
import {ChangeEvent, useContext, useEffect, useRef, useState} from "react";
import {arrayBufferToString, computeMediaFileContent} from "../../../Library/utils/arrayBuffer";
import {readImageSrc0} from "../../../Library/utils/imageHandling";
import {ServiceContext, ServiceContextInstance} from "../../../Core/serviceContext";

export const MenuProductCardContent = (props: { menuProduct: MenuProduct, index: number }): JSX.Element => {

    const services: ServiceContext = useContext(ServiceContextInstance);

    const [imageSrc, setImageSrc] = useState<ArrayBuffer>();

    const inputImageRef = useRef(null)

    useEffect(() => {
        let media = props.menuProduct.media;
        if (media === undefined || media.bytes === "") {
            setImageSrc(emptyImage)
            return
        }
        let bytes = media.bytes;
        if (bytes === undefined || arrayBufferToString(bytes as ArrayBuffer) === "") {
            setImageSrc(emptyImage)
            return
        }
        setImageSrc(bytes as ArrayBuffer)
    }, [props.menuProduct])

    async function changePhoto(e: ChangeEvent<HTMLInputElement>) {
        await readImageSrc0(e, async (src) => {
            setImageSrc(src)
            if (src === undefined) {
                return
            }
            let menuProduct = props.menuProduct;
            let stringSrc = arrayBufferToString(src);
            let searchString = ";base64,";
            let index = stringSrc.indexOf(searchString);
            stringSrc = stringSrc.substring(index + searchString.length);
            menuProduct.media = {
                bytes: stringSrc,
                type: "image/*",
                name: "image"
            }
            let response = await services.MenuProductService.update(menuProduct);
            if (response.Error !== undefined || response.Data === undefined) {
                message.error("Nu s-a putut modifica imaginea")
                return
            }
            message.success("Imaginea a fost schimbată cu succes")
        })
    }

    return (
        <div>
            <div><b>Descriere:</b></div>
            <TextAreaShowMoreOrLess description={props.menuProduct.description}/>
            <div className={"d-flex justify-content-center"}
                 style={{position: "relative"}}
            >
                <Image style={{maxWidth: "200px", maxHeight: "150px"}}
                       src={computeMediaFileContent(imageSrc as ArrayBuffer, "image")}
                />
                <div style={{position: "absolute", right: "0", top: "50%", transform: "translateY(-50%)"}}>
                    <Tooltip title={"Schimbă imaginea"}>
                        <i className="fa-solid fa-paperclip fa-2x pointer"
                           style={{color: "rgba(0,0,0,0.2)"}}
                           onClick={() => {
                               //@ts-ignore
                               inputImageRef.current.click()
                           }}
                        ></i>
                        <input style={{display: "none"}}
                               ref={inputImageRef}
                               type="file"
                               onChange={changePhoto}
                               accept="image/*"/>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}