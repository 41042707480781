import {blue} from "../../Assets/Styles/variables";
import {red} from "@mui/material/colors";
import {Button} from "antd";
import {Link} from "react-router-dom";
import {CONTACT_LINK} from "../../Enums/pagesUrls";

export const ExpiredAccount = (): JSX.Element => {


    return (
        <div className={"d-flex justify-content-center align-items-start bg-whitesmoke rounded"}
             style={{backgroundColor: blue, minHeight: "100vh"}}>
            <div className={"p-5 border bg-white shadow mt-5"} style={{maxWidth: "800px"}}>
                <h3 className={"text-center mb-3"}>Valabilitatea contului a expirat</h3>
                <p style={{fontSize: "1.1rem"}} className={"mb-3"}>
                    Contul se află în perioada de grație și va rămâne <span
                    style={{color: red[800]}}>dezactivat</span> până
                    la <span style={{textDecoration: "underline"}}>reactivare</span>. Contul va fi arhivat și va rămâne
                    așa o perioadă maximă de 60 de zile, după
                    care va fi șters definitiv.
                </p>
                <Button size={"large"} className={"p-0"} type={"link"}>
                    <Link to={CONTACT_LINK}>Detalii contact</Link>
                </Button>
            </div>
        </div>
    )
}