import {OrderNameWithProviderCard} from "../../Components/OrderNameWithProviderCard/orderNameWithProviderCard";
import {Empty, message, Modal} from "antd";
import {useContext, useEffect, useState} from "react";
import {Order} from "../../Models/backend/Order";
import {ServiceContext, ServiceContextInstance} from "../../Core/serviceContext";
import {IFetchResult} from "../../Hooks/useFetch.types";
import {useFetch} from "../../Hooks/useFetch";

export interface CreateReceiptModalProps {
    setLoadedData: (loadedData: boolean) => void
    setIsModalOpen: (open: boolean) => void
    isModalOpen: boolean
    setReceiptsChanged: () => void
}

export const CreateReceiptModal = (props: CreateReceiptModalProps): JSX.Element => {
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [orders, setOrders] = useState<Order[]>([])
    const ordersData: IFetchResult<Order[]> = useFetch<Order[]>(() => services.OrderService.getAll(false), []);
    useEffect(() => {
        if (ordersData.isLoading) {
            return;
        }
        if (ordersData.errors !== '' ||
            ordersData.data === null ||
            ordersData.data?.Error !== undefined ||
            ordersData.data?.Data === undefined) {
            return;
        }
        let data = ordersData.data.Data
        setOrders(data)
    }, [ordersData])


    async function createReceipt(orderId: string, orderName: string) {
        props.setLoadedData(false)
        let response = await services.ReceiptService.createReceipt(orderId);
        if (response.Error !== undefined) {
            message.warning("Nu s-a putut crea recepția pentru comanda " + orderName);
        }
        props.setReceiptsChanged()
    }


    return (
        <Modal title={"Creaza receptii"}
               open={props.isModalOpen}
               onOk={() => props.setIsModalOpen(false)}
               onCancel={() => props.setIsModalOpen(false)}
               style={{maxWidth: "80%"}}
               width={"fit-content"}
               footer={null}
        >
            <div style={{fontSize: "1.05rem"}}>
                Dublu click pe comenzile pentru care vrei sa creezi receptie.
            </div>
            <div className={"d-flex flex-wrap"} style={{gap: "12px"}}>
                {
                    orders.map((o, index) => (
                        <OrderNameWithProviderCard name={o.nir + " - " + o.name}
                                                   providerName={o.providerName}
                                                   onClick={() => props.setIsModalOpen(true)}
                                                   onDoubleClick={(e) => {
                                                       if (orders.length > 0) {
                                                           createReceipt(o.id!, o.name)
                                                           let elem = e.currentTarget as HTMLElement;
                                                           elem.classList.value = ""
                                                           let height = e.currentTarget.getBoundingClientRect().height + "px";
                                                           let width = e.currentTarget.getBoundingClientRect().width + "px";
                                                           elem.style.height = height
                                                           elem.style.width = width
                                                           elem.style.transition = "all 0.4s"
                                                           elem.classList.add("shrink-width")
                                                           elem.style.overflow = "hidden"
                                                       }
                                                   }}
                                                   key={index}
                        />
                    ))
                }
                {
                    orders.length === 0 && (
                        <Empty className={"mx-auto"}>Nu sunt comenzi fără recepții create</Empty>
                    )
                }
            </div>
        </Modal>
    )
}