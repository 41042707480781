import {Button, Divider, Input, message} from "antd";
import {OrderList} from "../../../../Components/OrderList/orderList";
import {Order} from "../../../../Models/backend/Order";
import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {ServiceContext, ServiceContextInstance} from "../../../../Core/serviceContext";
import {getCurrentDate} from "../../../../Library/utils/dateUtils";
import {PrimeProductWithQuantity} from "../../../../Models/PrimeProductWithQuantity";

export const OrderCart = (props: {
    totalPrice: number;
    ordersList: PrimeProductWithQuantity[];
    setOrdersList: Dispatch<SetStateAction<PrimeProductWithQuantity[]>>;
    providerName?: string;
}): JSX.Element => {
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [nextItemId, setNextItemId] = useState<number | undefined>(undefined)
    const [orderDescription, setOrderDescription] = useState<string | undefined>()
    const [loadingButton, setLoadingButton] = useState<boolean>(false)

    useEffect(() => {
        services.SettingsService
            .nextOrderId()
            .then(response => {
                let data = response.Data;
                if (data !== undefined) {
                    setNextItemId(data + 1)
                } else {
                    setNextItemId(undefined)
                }
            })
    }, [])

    useEffect(() => {
        setOrderName()
    }, [props.providerName])

    function setOrderName() {
        if (props.providerName === undefined) {
            return
        }
        setOrderDescription(props.providerName + "_" + getCurrentDate())
    }

    async function createOrder() {
        setLoadingButton(true)
        let primeProducts = props.ordersList;
        for (let primeProduct of primeProducts) {
            // set it undefined because of backend deserialization error
            primeProduct.orderProduct!.provider = undefined
        }
        const order: Order = {
            nir: nextItemId!,
            providerName: props.providerName!,
            primeProducts: primeProducts,
            name: orderDescription!,
            hasReceipt: false
        }
        let response = await services.OrderService.create(order);
        setLoadingButton(false)
        if (response.Error !== undefined) {
            message.error("Eroare la crearea comenzii!");
            return
        }

        props.setOrdersList([])
        setOrderName()
        setNextItemId(nextItemId ? nextItemId + 1 : undefined)
        message.success("Comanda a fost creată. O poți vedea la secțiunea 'Vizualizare comenzi'")
    }

    return (
        <div className={"p-4 pt-0 align-self-start"}
            // style={{height: "100%", flex: "2", overflowY: "auto"}}
             style={{flex: "2"}}
        >
            <div className={"h4"}>
                Comanda #{nextItemId !== undefined ? nextItemId : 1}
            </div>
            <Input placeholder={"Introdu denumirea comenzii"}
                   value={orderDescription}
                   onChange={(e) => setOrderDescription(e.currentTarget.value)}
            />
            <Divider className={"m-0 my-2"}/>
            <div className={"d-flex flex-column justify-content-between"}>
                <OrderList list={props.ordersList} setList={props.setOrdersList}/>
                <div style={{position: "sticky", bottom: "0"}}
                     className={"pt-4"}
                >
                    <div className={"p-2 rounded shadow-sm justify-content-between d-flex"}
                         style={{backgroundColor: "white"}}>
                        <Button type="primary"
                                onClick={createOrder}
                                disabled={props.ordersList.length === 0}
                                loading={loadingButton}
                        >
                            creează comanda
                        </Button>
                        <div className={"my-auto"}>
                            Pret: {props.totalPrice.toLocaleString()} ron
                        </div>
                        <Button type="primary"
                                disabled={props.ordersList.length === 0}
                                onClick={() => {
                                    props.setOrdersList([])
                                }}
                        >
                            sterge tot
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}