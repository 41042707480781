import {APIHelper} from "../Configuration/apiHelper";
import {IResponse} from "../Models/IResponse";
import {PrimeProduct} from "../Models/backend/PrimeProduct";
import {DeletionsForProductResponse} from "../Models/model/DeletionsForProductResponse";

export default class PrimeProductService {

    constructor(protected readonly _endpoint: string) {

    };

    async update(product: PrimeProduct): Promise<IResponse<PrimeProduct>> {
        let bodyJson = JSON.stringify(product);
        return await APIHelper.request(`${this._endpoint}`, 'PUT', bodyJson, false)
    };

    async delete(ids: string[]): Promise<IResponse<void>> {
        const theIds: string = ids.join(",");
        return await APIHelper.request(`${this._endpoint}/${theIds}`, 'DELETE')
    };


    async getAll(): Promise<IResponse<PrimeProduct[]>> {
        return await APIHelper.request(`${this._endpoint}`, 'GET')
    };

    async getPossibleMenusDeletions(ids: string): Promise<IResponse<DeletionsForProductResponse>> {
        return await APIHelper.request(`${this._endpoint}/related-to/${ids}`, 'GET')
    };
};