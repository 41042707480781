import React, {useEffect, useState} from 'react';
import {Divider, Layout, Menu} from 'antd';
import {Outlet, useNavigate} from "react-router-dom";
//@ts-ignore
import logoPng from '../../Assets/Images/logo-white.png'
import {getNavItem, MenuItem, navItems} from "./navItems";
import {CONFIDENTIALITY_POLICY, EXPIRED_ACCOUNT_LINK, TERMS_AND_CONDITIONS} from "../../Enums/pagesUrls";
import {accountExpiredLS} from "../../Enums/localStorageItems";
import V from '../../version.json'
import {LG} from "../../Assets/Styles/variables";
import useScroll from "../../Hooks/useScroll";

const {Content, Sider, Footer, Header} = Layout;

const menuItemsLabels: string[] = ["dashboard", "produse", "meniuri", "comenzi", "receptii", "contact", "deconectare"]
export const MyLayout = (): React.JSX.Element => {

    const [items, setItems] = useState<MenuItem[]>([])
    const [disabledNavs, setDisabledNavs] = useState<string[] | undefined>(undefined)
    const [collapsed, setCollapsed] = useState<boolean>(true)
    const [useHeader, setUseHeader] = useState<boolean>(false)
    const [blockScroll, allowScroll] = useScroll();

    useEffect(() => {
        if (window.innerWidth >= LG) {
            setUseHeader(false)
        } else {
            setUseHeader(true)
        }
    }, [window.innerWidth])

    useEffect(() => {
        if (!collapsed) {
            blockScroll()
        } else {
            allowScroll()
        }
    }, [collapsed])


    useEffect(() => {
        setItems(
            [...menuItemsLabels.map((el, index) => {
                let item = navItems.get(el)!;
                if (disabledNavs !== undefined && disabledNavs.includes(el)) {
                    item.disabled = true
                } else {
                    item.disabled = false
                }
                return getNavItem(item)
            })]
        )
    }, [disabledNavs]);

    useEffect(() => {
        let pathname1 = window.location.pathname;
        let item1 = localStorage.getItem(accountExpiredLS);
        if (pathname1 === EXPIRED_ACCOUNT_LINK || item1 === "true") {
            setDisabledNavs(["produse", "meniuri", "comenzi", "receptii"])
        } else {
            setDisabledNavs([])
        }
    }, [window.location.pathname]);

    const navigate = useNavigate()
    const [selectedKey, setSelectedKey] = useState<string>()
    const [openKeys, setOpenKeys] = useState<string[]>([])

    useEffect(() => {
        if (selectedKey !== undefined) {
            return
        }
        const pathname = window.location.pathname
        let menuItem2s = Array.from(navItems.values());
        for (let item of menuItem2s) {
            if (item.regex !== undefined) {
                if (item.regex.test(pathname)) {
                    setSelectedKey(item.index + "")
                    break
                }
            } else {
                if (pathname === item.link) {
                    setSelectedKey(item.index + "")
                    break;
                }
            }
            if (item.children !== undefined) {
                for (let child of item.children) {
                    if (child.regex !== undefined) {
                        if (child.regex.test(pathname)) {
                            setSelectedKey(child.index + "")
                            break
                        }
                    } else {
                        if (pathname === child.link) {
                            setSelectedKey(child.index + "")
                            break
                        }
                    }
                }
            }
        }
    }, [window.location.href])

    useEffect(() => {
        if (selectedKey === undefined) {
            return
        }
        let menuItem2 = navItems.get("meniuri")!;
        let children = menuItem2.children!;
        const openKeys = []
        for (let child of children) {
            if (Number(selectedKey) === child.index) {
                openKeys.push(menuItem2.index + "")
            }
        }
        setOpenKeys(openKeys)
    }, [selectedKey])

    function goTo(index: number) {
        let deconectare = navItems.get("deconectare")!;
        if (index === deconectare.index) {
            localStorage.clear()
            // window.history.replaceState(null, "", "/login"); //this replace only the last state
        }
        let items = Array.from(navItems.values());
        items.forEach(el => {
            if (el.index === index) {
                navigate(el.link)
            }
            if (el.children !== undefined) {
                el.children.forEach(child => {
                    if (child.index === index) {
                        navigate(child.link)
                    }
                })
            }
        })
    }

    return (
        <Layout>
            <MySider
                collapsed={collapsed}
            >
                <div style={{color: "white", fontSize: "1.5rem"}}
                     className={"d-flex align-items-center justify-content-center mt-4 mb-3"}
                >
                    <img src={logoPng} alt={"MenuOrders logo"}
                         style={{width: "120px"}}
                    />
                </div>
                <div className={"ps-4"} style={{color: "white"}}>
                    {V.version}
                </div>
                <Menu theme="dark"
                      mode="inline"
                      onClick={(e) => goTo(Number(e.key))}
                      selectedKeys={[selectedKey || "0"]}
                      openKeys={openKeys}
                      items={items}
                      onOpenChange={(e) => setOpenKeys(e)}
                      onSelect={(e) => {
                          setSelectedKey(e.key)
                      }}
                      forceSubMenuRender={true}
                />
            </MySider>
            <Layout style={{marginLeft: `${useHeader ? 0 : "200px"}`, height: "100vh", position: "relative"}}
                    className={"justify-content-between"}>
                <div>
                    {
                        useHeader && (
                            <Header style={{color: "white"}} className={"p-3"}>
                                <div>
                                    <span className="material-symbols-outlined" style={{fontSize: "2rem"}} onClick={() => setCollapsed(!collapsed)}>menu</span>
                                </div>
                            </Header>
                        )
                    }
                    {
                        !collapsed && (
                            <div style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                zIndex: 100
                            }} onClick={() => setCollapsed(true)}/>
                        )
                    }
                    <Content>
                        <Outlet/>
                    </Content>
                </div>

                <Footer style={{textAlign: 'center'}} className={"padding-responsive"}>
                    <Divider className={"mt-0"}/>
                    <div className={"d-flex"} style={{gap: "20px"}}>
                        <div>
                            <a href={TERMS_AND_CONDITIONS}
                               target={"_blank"} rel={"noreferrer"}>
                                Termenii și condițiile
                            </a>
                        </div>
                        <div className={"mb-3"}>
                            <a href={CONFIDENTIALITY_POLICY}
                               target={"_blank"} rel={"noreferrer"}>
                                Politica de confidențialitate
                            </a>
                        </div>
                    </div>
                    <div>
                        MenuOrders ©{new Date().getFullYear()} Created by Sigoiu George
                    </div>
                </Footer>
            </Layout>
        </Layout>
    )
}

const MySider = (props: {
    children: JSX.Element[],
    collapsed?: boolean
}): JSX.Element => {

    const [useDesktop, setUseDesktop] = useState<boolean>(true)

    useEffect(() => {
        if (window.innerWidth >= LG) {
            setUseDesktop(true)
        } else {
            setUseDesktop(false)
        }
    }, [window.innerWidth])

    return (
        <>
            {
                useDesktop ? <SiderDesktop children={props.children}/> :
                    <SiderPhone children={props.children} collapsed={props.collapsed!}/>
            }
        </>
    )
}

const SiderDesktop = (props: {
    children: JSX.Element[]
}): JSX.Element => {
    return (
        <Sider
            breakpoint="lg"
            style={{overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0}}
        >
            {props.children}
        </Sider>
    )
}

const SiderPhone = (props: {
    children: JSX.Element[],
    collapsed: boolean
}): JSX.Element => {
    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={props.collapsed}
            breakpoint="lg"
            collapsedWidth="0"
            style={{height: '100vh'}}
        >
            {props.children}
        </Sider>
    )
}