import './styles.scss'
import React, {useContext, useEffect, useState} from "react";
import {Button, Input, message, notification} from "antd";
import {Link, useNavigate, useParams} from "react-router-dom";
import {DASHBOARD_LINK, PASSWORD_RESET_LINK} from "../../Enums/pagesUrls";
import {ServiceContext, ServiceContextInstance} from "../../Core/serviceContext";
import {blue} from "@mui/material/colors";
import Checkbox from "antd/es/checkbox/Checkbox";
import {LoginResponse} from "../../Models/model/LoginResponse";
import {IResponse} from "../../Models/IResponse";
import {accessTokenLS, refreshTokenLS, rememberLS, usernameLS} from "../../Enums/localStorageItems";

export const Login = (): React.JSX.Element => {
    const params = useParams()
    const {sessionExpired} = params
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [loading, setLoading] = useState<boolean>(false)
    const [state, setState] = useState<"Conectare" | "Conectat cu succes" | "Autentificare..." | "Eroare de autentificare">("Conectare")
    const navigate = useNavigate()

    const [username, setUsername] = useState<string>()
    const [password, setPassword] = useState<string>("")
    const [doRemember, setDoRemember] = useState<boolean>(false)

    const [api, contextHolder] = notification.useNotification();


    async function onSubmit(e: React.FormEvent) {
        e.preventDefault()
        if (loading) return;
        let currentTarget = e.currentTarget;
        currentTarget.classList.add("loading")
        setState("Autentificare...")
        let boolean = await login();
        const cls: string = boolean ? "ok" : "notok";
        const stare: "Eroare de autentificare" | "Conectat cu succes" = boolean ? "Conectat cu succes" : "Eroare de autentificare";
        currentTarget.classList.add(cls);
        setState(stare);
        setTimeout(function () {
            setState('Conectare');
            currentTarget.classList.remove(cls, 'loading');
            setLoading(false)
            if (boolean) {
                navigate(DASHBOARD_LINK)
            }
        }, 1700);
    }

    async function login(): Promise<boolean> {
        if (username === undefined || username === "") {
            message.warning("Numele de utilizator nu este completat!")
            return false
        }
        let response = await services.LoginService.login(username, password);
        if (response.Error !== undefined || response.Data === undefined) {
            let message1 = response.Message;
            if (message1 === undefined) {
                message1 = "Numele de utilizator sau parola sunt gresite!"
            }
            message.error(message1)
            return false;
        }
        let data = response.Data;
        localStorage.setItem(usernameLS, data.username)
        localStorage.setItem(accessTokenLS, data.access_token)
        localStorage.setItem(refreshTokenLS, data.refresh_token)
        localStorage.setItem(rememberLS, doRemember + "")
        return true
    }

    useEffect(() => {
        if (window.location.search === "?sessionExpired=true") {
            api.warning({
                message: "Sesiunea a expirat",
                description: "Conectati-va din nou.",
            });
        }
        checkAuth()
    }, [])

    async function checkAuth() {
        let remember0 = localStorage.getItem(rememberLS);
        if (remember0 === null || remember0 === "false") {
            localStorage.clear()
            return
        }
        if (remember0 === "true") {
            if (localStorage.getItem("access_token") === null && localStorage.getItem("refresh_token") === null) {
                return
            }
            if (localStorage.getItem("access_token") === undefined && localStorage.getItem("refresh_token") === undefined) {
                return
            }
            let response: IResponse<LoginResponse>
            try {
                response = await services.AuthService.checkAuth();
            } catch (e) {
                localStorage.clear()
                return
            }
            if (response.Error !== undefined || response.Data === undefined || ((response.Status ?? 1) / 100) === 4) {
                localStorage.clear()
                return
            }
            localStorage.setItem(refreshTokenLS, response.Data.refresh_token)
            localStorage.setItem(accessTokenLS, response.Data.access_token)
            localStorage.setItem(usernameLS, response.Data.username)
            navigate(DASHBOARD_LINK)
        }
    }

    return (
        <div id={"login"}
             style={{backgroundColor: `${blue[800]}`}}
        >
            {contextHolder}
            <div className={"wrapper pt-5"}>
                <form className="login rounded" onSubmit={onSubmit}>
                    <div className={"p-3"}>
                        <div>
                            <a href={"/"} style={{fontSize: "1rem", textDecoration: "none"}}>
                                <i className="fa-solid fa-arrow-left-long me-1"></i>
                                MenuOrders
                            </a>
                        </div>
                        <p className="title text-center">
                            Conectare
                        </p>
                        <Input type="username"
                               size={"large"}
                               placeholder="Nume utilizator"
                               value={username}
                               className={"mb-2"}
                               prefix={<i className="fa fa-user" style={{color: `gray`, marginRight: "10px"}}></i>}
                               onChange={(e) => setUsername(e.currentTarget.value)}
                        />
                        <Input.Password
                            size={"large"}
                            placeholder="Parola"
                            value={password}
                            className={"mb-2"}
                            visibilityToggle={true}
                            prefix={<i className="fa fa-key" style={{color: `gray`, marginRight: "10px"}}></i>}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                        />
                        <div className={"mb-2 d-flex justify-content-between"}>
                            <Checkbox value={rememberLS}
                                      onChange={(e) => setDoRemember(e.target.checked)}
                            >
                                Ține-mă autentificat
                            </Checkbox>
                            <Link to={PASSWORD_RESET_LINK} style={{fontSize: "1rem"}}>Ai uitat parola?</Link>
                        </div>
                        <div>
                            <Button
                                type={"primary"}
                                htmlType={"submit"}
                                size={"large"}
                                className={"w-100 mt-3"}
                            >
                                <span className="state">{state}</span>
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}