import {Methods} from '../Enums/methods';
import {IResponse} from '../Models/IResponse';
import BASE_URL from './config.json';
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import jwtDecode from "jwt-decode";
import {getStatusCode} from "./statusCodes";
import {EXPIRED_ACCOUNT_LINK} from "../Enums/pagesUrls";
import {accessTokenLS, accountExpiredLS, refreshTokenLS} from "../Enums/localStorageItems";


class ApiHelper {

    request = async <T, U, >(
        endpoint: string,
        method: Methods,
        body?: string | FormData,
        isFormData?: boolean,
        token?: string
    ): Promise<IResponse<U>> => {
        await this.checkTokensAndRefreshIfCase();

        let response: AxiosResponse;
        let url = `${BASE_URL.BASE_URL}${endpoint}`;
        let config = this.getConfig(url, method, body, isFormData, token);
        let statusCode;
        try {
            response = await axios.request(config)
        } catch (error: any) {
            // we get here if can not connect to the server
            const err = error as AxiosError
            response = err.response!
        }
        try {
            statusCode = getStatusCode(response.status);
        } catch (e) {
            return {Status: 403, Error: "Nu s-a putut comunica cu serverul"}
        }
        let message = statusCode?.message;
        if (response.status === 458) {
            localStorage.setItem(accountExpiredLS, "true")
            window.location.assign(EXPIRED_ACCOUNT_LINK)
            return {Status: response.status, Message: message, Error: response.statusText};
        }
        if (response.status === 204) {
            return {Status: response.status};
        }
        if (response.status === 209 || response.status === 210) {
            return {Status: response.status, Message: message};
        }
        if (response.status === 503) {
            return {Status: response.status, Message: message, Error: response.statusText};
        }
        if (response.status === 499) {
            return {Status: response.status, Message: message, Error: response.statusText};
        }

        if (response.status >= 300 || response.status < 200) {
            return {Status: response.status, Error: response.statusText, Message: message};
        }
        const data = await response.data
        return {Data: data, Status: response.status};
    };

    private getConfig(url: string, method: "GET" | "POST" | "PUT" | "DELETE", body: string | FormData | undefined, isFormData: boolean | undefined, token: string | undefined) {
        // const csrfToken = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1');
        return {
            url: url,
            method: method,
            data: body,
            headers: {
                "Accept": "application/json",
                "Content-Type": isFormData === true ? "multipart/form-data" : "application/json",
                "Authorization": "Bearer " + (token !== undefined ? token : localStorage.getItem("access_token")),
                // "X-XSRF-TOKEN": csrfToken
            }
        };
    }

    private async checkTokensAndRefreshIfCase() {
        let accessToken = localStorage.getItem("access_token");
        if (accessToken !== null) {
            let tokenDecoded: { sub: string, exp: number, roles: string[] } = jwtDecode(accessToken!)!;
            let exp = tokenDecoded.exp;
            if (exp * 1000 <= Date.now()) {
                await this.refreshTokensAndSendRequestAgain()
            }
        }
    }

    private async refreshTokensAndSendRequestAgain() {
        // const csrfToken = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1');
        const secondConfig: AxiosRequestConfig = {
            method: "GET",
            url: `${BASE_URL.BASE_URL}/auth/token/refresh`,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem(refreshTokenLS),
                // "X-XSRF-TOKEN": csrfToken,
            }
        }
        let response = await axios.request(secondConfig);
        let data = response.data;
        let refresh_token: string = data.refresh_token;
        let access_token: string = data.access_token;
        localStorage.setItem(refreshTokenLS, refresh_token);
        localStorage.setItem(accessTokenLS, access_token);
    }
}

export const APIHelper = new ApiHelper();