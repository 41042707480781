import {OrderListItem} from "./OrderListItem/orderListItem";
import {PrimeProductWithQuantity} from "../../Models/PrimeProductWithQuantity";
import {Dispatch, SetStateAction} from "react";

/**
 * It is the list that appears in the right when you want to place an order
 */
export const OrderList = (props: {
    list: PrimeProductWithQuantity[];
    setList: Dispatch<SetStateAction<PrimeProductWithQuantity[]>>;
}): JSX.Element => {

    return (
        <div id={"cart-products-order"}>
            {
                props.list.map((p, index) => (
                    <OrderListItem
                        key={index}
                        product={p}
                        list={props.list}
                        setList={props.setList}
                    />
                ))
            }
            <div id={"cart-products-order-bottom"}></div>
        </div>
    )
}