import {Badge, Button, Input, message, Modal, Popconfirm, Space, Spin, Tooltip} from "antd";
import {OrderCardProps} from "./orderCard.types";
import React, {useContext, useEffect, useState} from "react";
import {PrimeProductsTable} from "./ProductsTable/primeProductsTable";
import {PrimeProductWithQuantity} from "../../../../Models/PrimeProductWithQuantity";
import {ServiceContext, ServiceContextInstance} from "../../../../Core/serviceContext";
import {extensionFor, FileFormat} from "../../../../Enums/fileFormat";
import {MediaFileObj} from "../../../../Models/model/MediaFileObj";
import {downloadFile} from "../../../../Library/utils/documentDownload";
import {OrderNameWithProviderCard} from "../../../../Components/OrderNameWithProviderCard/orderNameWithProviderCard";

export const OrderCard = (props: OrderCardProps): JSX.Element => {
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(props.order.name)
    const [products, setProducts] = useState<PrimeProductWithQuantity[]>(props.order.primeProducts)
    const [loadingForDelete, setLoadingDelete] = useState<boolean>(false)
    const [totalPrice, setTotalPrice] = useState<number>()
    const [loadingForFile, setLoadingForFile] = useState<"PDF" | "WORD" | undefined>(undefined)
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        let price = 0
        for (let product of products) {
            price += product.quantity! * (product.orderProduct?.price ?? 0)
        }
        setTotalPrice(Number(price.toFixed(2)))
    }, [products])

    async function renameOrder() {
        if (props.order.name === title) {
            return
        }
        let response = await services.OrderService.rename(props.order.id!, title);
        if (response.Error !== undefined || response.Data === undefined) {
            message.warning("Numele comenzii NU a fost modificata")
            return
        }
        message.success("Numele comenzii a fost modificat!")
        props.setOrdersChanged()
    }

    /**
     * @param oldId prime product id
     * @param newQuantity new quantity
     */
    async function updateProduct(oldId: string, newQuantity: number) {
        let primeProductId;
        let newProds = products.map(p => {
            if (p.orderProduct!.id === oldId) {
                primeProductId = p.orderProduct!.id
                const r: PrimeProductWithQuantity = {
                    orderProduct: {
                        name: p.orderProduct!.name,
                        price: p.orderProduct!.price,
                        quantityType: p.orderProduct!.quantityType,
                    },
                    quantity: newQuantity,
                }
                return r
            } else {
                return p
            }
        })
        if (primeProductId === undefined) {
            message.error("Eroare la gasirea produsului!")
            return
        }
        let response = await services.OrderService.updateProductQuantity(props.order.id!, primeProductId, newQuantity);
        if (response.Error !== undefined || response.Data === undefined) {
            message.warning("Cantitatea NU a fost modificata!")
            return
        }
        message.success("Cantitatea a fost modificata")
        setProducts([...newProds])
    }

    function createModalTitle(): JSX.Element {
        return (
            <div className={"d-flex"}>
                <Space.Compact style={{width: '75%'}}>
                    <Input value={title} onChange={(e) => setTitle(e.currentTarget.value)}/>
                    <Tooltip placement={"topRight"} title={"Redenumeste comanda"}>
                        <Button type="primary" onClick={renameOrder}><i className="fa-solid fa-pencil"></i></Button>
                    </Tooltip>
                </Space.Compact>
                <div className={"d-flex mx-5"} style={{gap: "15px", fontSize: "15px"}}>
                    <Tooltip placement="top" title={"Descarcă PDF"}>
                        <div className={"pointer"} onClick={() => {
                            setLoadingForFile("PDF")
                            exportOrderAsDocument("PDF")
                        }}>
                            {
                                (loadingForFile !== undefined && loadingForFile === "PDF") ? (
                                    <Spin/>
                                ) : (
                                    <i className="fa-regular fa-file-pdf fa-2x"></i>
                                )
                            }
                        </div>
                    </Tooltip>
                    <Tooltip placement="top" title={"Descarcă WORD"}>
                        <div className={"pointer"} onClick={() => {
                            setLoadingForFile("WORD")
                            exportOrderAsDocument("WORD")
                        }}>
                            {
                                (loadingForFile !== undefined && loadingForFile === "WORD") ? (
                                    <Spin/>
                                ) : (
                                    <i className="fa-regular fa-file-word fa-2x"></i>
                                )
                            }
                        </div>
                    </Tooltip>
                </div>
            </div>
        )
    }

    async function deleteOrder() {
        setLoadingDelete(true)
        const response = await services.OrderService.delete(props.order.id!);
        if (response.Error !== undefined) {
            message.error("Comanda nu a fost sterasa!")
        } else {
            setIsModalOpen(false)
            props.setOrdersChanged()
            message.success("Comanda a fost stearsa!")
        }
        setLoadingDelete(false)
    }

    function createModalFooter(): JSX.Element {
        return (
            <div>
                <Popconfirm title={"Sigur vrei să ștergi comanda?"} okText={"Da"}
                            cancelText={"Nu"}
                            okButtonProps={{loading: loadingForDelete}}
                            onConfirm={deleteOrder}
                >
                    <Button danger>Sterge comanda</Button>
                </Popconfirm>
            </div>
        )
    }

    async function exportOrderAsDocument(fileType: FileFormat) {
        let response = await services.OrderService.export(props.order.id!, fileType);
        setLoadingForFile(undefined)
        if (response.Error !== undefined || response.Data === undefined) {
            message.error("Nu s-a putut descărca fișierul :(")
            return
        }
        let fileName: string = props.order.name + "." + extensionFor(fileType)
        let file: MediaFileObj = response.Data;
        downloadFile(file, fileName)
    }

    return (
        <>
            <Badge count={
                !props.order.hasReceipt ? (
                    <Tooltip title={"Nu a fost generata receptie pentru comanda"}>
                        <i className="fa-solid fa-receipt fa-lg" style={{color: "red"}}></i>
                    </Tooltip>
                ) : (0)
            }
                   className={"pointer"}
            >
                <OrderNameWithProviderCard
                    name={props.order.nir + " - " + props.order.name}
                    onClick={showModal}
                    providerName={props.providerName}
                />
            </Badge>
            <Modal
                title={createModalTitle()}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={createModalFooter()}
                keyboard={false}
                style={{minWidth: "40%", maxWidth: "80%"}}
            >
                <div className={"mt-4 d-flex justify-content-between align-items-center"}>
                    <div className={"h4"}>
                        {props.providerName}
                    </div>
                    <div className={"h6"}>
                        Comanda #{props.order.nir}
                    </div>
                </div>
                <div className={"mt-3"}>
                    <PrimeProductsTable products={products} updateProduct={updateProduct}/>
                </div>
                <div className={"mt-3"}>
                    Pretul total: {totalPrice} ron
                </div>

            </Modal>
        </>
    )
}