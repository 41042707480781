import {Empty, FloatButton, Spin} from "antd";
import ProductsTableCreateOrder from "./productsTable";
import {OrderCart} from "./orderCart";
import React, {useEffect, useState} from "react";
import {Order} from "../../../../Models/backend/Order";
import {PrimeProductWithQuantity} from "../../../../Models/PrimeProductWithQuantity";
import {PrimeProduct} from "../../../../Models/backend/PrimeProduct";

export const TableWithCart = (props: {
    order: Order;
    allProducts: PrimeProduct[] | undefined;
}): JSX.Element => {

    const [orderList, setOrderList] = useState<PrimeProductWithQuantity[]>([])
    const [collapseHeight, setCollapseHeight] = useState<number>()
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [providerName, setProviderName] = useState<string>()

    useEffect(() => {
        setProviderName(props.order.providerName)
    }, [props.order.providerName])

    useEffect(() => {
        let primeProductWithQuantities = props.order.primeProducts;
        setOrderList(primeProductWithQuantities.sort((a, b) => a.orderProduct!.name! > b.orderProduct!.name! ? 1 : -1))
    }, [props.order])

    useEffect(() => {
        let number = orderList
            .map(p => p.orderProduct!.price! * p.quantity!)
            .reduce((partialSum, a) => partialSum + a, 0)
        setTotalPrice(Math.round((number + Number.EPSILON) * 100) / 100)
    }, [orderList])

    useEffect(() => {
        let divRef = document.getElementById("create-order-div");
        if (divRef !== null) {
            //@ts-ignore
            let boundingClientRect = divRef.getBoundingClientRect();
            let innerHeight = window.innerHeight;
            let firstDivBounding = divRef.children[0].getBoundingClientRect();
            setCollapseHeight(innerHeight - boundingClientRect.top - 20 - firstDivBounding.height)
        }
    }, [window])

    function addToCart(key: React.Key) {
        if (props.allProducts !== undefined) {
            let productToBeAddedInCart = props.allProducts.filter(p => String(p.id) === String(key))[0];
            let existingElementsInCartNames = orderList.map(p => p.orderProduct!.name!);

            if (existingElementsInCartNames.includes(productToBeAddedInCart.name!)) {
                //element is existent
                return
            }
            setOrderList([
                ...orderList,
                {
                    orderProduct: {
                        id: productToBeAddedInCart.id,
                        name: productToBeAddedInCart.name,
                        price: productToBeAddedInCart.price,
                        quantityType: productToBeAddedInCart.quantityType,
                    },
                    quantity: 1,
                }
            ])
        }
    }

    return (
        <div
            style={{
                width: "100%",
                height: collapseHeight,
                backgroundColor: "whitesmoke",
            }}
        >
            <div className={"d-flex justify-content-between"} style={{height: "100%", gap: "30px"}}>
                <div style={{flex: "5"}}>
                    {
                        providerName !== undefined ? (
                            <ProductsTableCreateOrder provider={providerName}
                                                      addToCart={addToCart}
                                                      products={props.allProducts}
                            />
                        ) : (
                            <Empty description={"Selecteaza furnizorul pentru a vedea produsele"}/>
                        )
                    }

                </div>
                <OrderCart totalPrice={totalPrice}
                           ordersList={orderList}
                           providerName={providerName}
                           setOrdersList={setOrderList}
                />
            </div>
            <FloatButton.BackTop/>
        </div>
    )
}