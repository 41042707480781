import {IResponse} from "../Models/IResponse";
import {APIHelper} from "../Configuration/apiHelper";
import {Provider} from "../Models/backend/Provider";
import {Order} from "../Models/backend/Order";
import {MediaFileObj} from "../Models/model/MediaFileObj";
import {FileFormat} from "../Enums/fileFormat";
import {OrderWithProvider} from "../Models/model/OrderWithProvider";

export default class OrderService {

    constructor(protected readonly _endpoint: string) {

    };

    async create(order: Order): Promise<IResponse<Provider>> {
        const request = {
            ...order,
            provider: order.providerName
        }
        return await APIHelper.request(`${this._endpoint}`, 'POST', JSON.stringify(request), false)
    };

    async getAll(hasReceipt?: boolean): Promise<IResponse<Order[]>> {
        const params = [];
        if (hasReceipt !== undefined) {
            params.push("hasReceipt=" + hasReceipt)
        }
        if (params.length > 0) {
            return await APIHelper.request(`${this._endpoint}?${params.join("&")}`, 'GET')
        }
        return await APIHelper.request(`${this._endpoint}`, 'GET')
    };

    async getOrdersForMenu(menuId: string, people: number): Promise<IResponse<OrderWithProvider[]>> {
        return await APIHelper.request(`${this._endpoint}/for-menu/${menuId}/people=${people}`, 'GET')
    };

    async delete(orderId: string): Promise<IResponse<void>> {
        return await APIHelper.request(`${this._endpoint}/${orderId}`, 'DELETE')
    };

    async rename(orderId: string, name: string): Promise<IResponse<Order>> {
        const formData: FormData = new FormData()
        formData.append("orderId", orderId)
        formData.append("name", name)
        return await APIHelper.request(`${this._endpoint}/rename`, 'POST', formData, true)
    };

    async export(orderId: string, fileFormat: FileFormat): Promise<IResponse<MediaFileObj>> {
        return await APIHelper.request(`${this._endpoint}/${orderId}/export/${fileFormat}`, 'GET')
    };

    async updateProductQuantity(orderId: string, productId: string, quantity: number): Promise<IResponse<Order>> {
        const formData: FormData = new FormData()
        formData.append("orderId", orderId)
        formData.append("productId", productId)
        formData.append("quantity", quantity.toString())
        return await APIHelper.request(
            `${this._endpoint}/${orderId}/updateQuantity`,
            'PUT',
            formData,
            true
        )
    };


};