import {Button, message, Tooltip} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {ServiceContext, ServiceContextInstance} from "../../../Core/serviceContext";
import {ReceiptTemplate} from "../../../Models/backend/ReceiptTemplate";
import {TemplateModal} from "./templateModal";
import {TemplateDivProps} from "./templateDiv.types";

export const TemplatesDiv = (props: TemplateDivProps): JSX.Element => {
    const maximumNumberOfTemplates: number = 5;
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState<ReceiptTemplate>()

    useEffect(() => {
        if (!isTemplateModalOpen) {
            setSelectedTemplate({})
        }
    }, [isTemplateModalOpen])

    const showTemplateModal = () => {
        setIsTemplateModalOpen(true);
    };


    async function createTemplateCard() {
        if (props.receiptsTemplates.length >= 5) {
            return
        }

        let response = await services.ReceiptTemplateService.put({});
        if (response.Data === undefined || response.Error !== undefined) {
            message.error("Nu s-a putut crea un template nou :(")
            return
        }

        //create a new empty template
        props.setReceiptsTemplates([...props.receiptsTemplates, response.Data])
    }

    async function updateTemplate(receipt: ReceiptTemplate) {
        let response = await services.ReceiptTemplateService.put(receipt);
        if (response.Data === undefined || response.Error !== undefined) {
            message.error("Sablonul nu a fost actualizat :(")
            return
        }
        message.success("Sablonul a fost actualizat!")

        let newReceipts = props.receiptsTemplates.map(t => {
            if (t.id === receipt.id) {
                return receipt
            } else {
                return t
            }
        });
        props.setReceiptsTemplates([...newReceipts])
    }

    async function deleteTemplate(id: string) {
        let response = await services.ReceiptTemplateService.delete(id);
        if (response.Data === undefined || response.Error !== undefined) {
            message.error("Sablonul nu a fost sters :(")
            return
        }
        message.success("Sablonul a fost sters!")
        props.setReceiptsTemplates([...props.receiptsTemplates.filter(t => t.id !== id)])
    }

    return (
        <div>
            <div className={"mt-3"}>
                <div className={"mb-2"}>
                    Valori implicite pentru fișierele de recepții
                </div>
                <div className={"d-flex flex-wrap"} style={{gap: "15px"}}>
                    <div>
                        <Tooltip title={"Numarul maxim de template-uri e 5"}>
                            <Button type={"primary"}
                                    disabled={props.receiptsTemplates.length >= maximumNumberOfTemplates}
                                    onClick={createTemplateCard}>
                                Creează template nou +
                            </Button>
                        </Tooltip>
                    </div>
                    <div className={"d-flex flex-wrap"} id={"receipt-templates-container"} style={{gap: "15px"}}>
                        {
                            props.receiptsTemplates.map((el, index) => (
                                <div key={index} className={"rounded border shadow-sm px-2 py-1 pointer bg-white"}>
                                    <span onClick={() => {
                                        setSelectedTemplate(el)
                                        showTemplateModal()
                                    }}>
                                        {el.templateName ?? 'template nou'}
                                    </span>
                                    <i className="fa-solid fa-xmark ps-2"
                                       style={{color: "red"}}
                                       onClick={() => deleteTemplate(el.id!)}>

                                    </i>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <TemplateModal update={updateTemplate}
                           isModalOpen={isTemplateModalOpen}
                           selectedTemplate={selectedTemplate}
                           setIsModalOpen={setIsTemplateModalOpen}/>
        </div>
    )
}
