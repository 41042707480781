import React, {useContext, useEffect, useState} from "react";
import {Divider} from "antd";
import {ServiceContext, ServiceContextInstance} from "../../Core/serviceContext";
import {TemplatesDiv} from "./Templates/templatesDiv";
import {IFetchResult} from "../../Hooks/useFetch.types";
import {ReceiptTemplate} from "../../Models/backend/ReceiptTemplate";
import {useFetch} from "../../Hooks/useFetch";
import {ViewReceipt} from "./viewReceipt";


export const Receipts = (): React.JSX.Element => {
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [receiptsTemplates, setReceiptsTemplates] = useState<ReceiptTemplate[]>([]);

    const receiptTemplatesData: IFetchResult<ReceiptTemplate[]> = useFetch<ReceiptTemplate[]>(() => services.ReceiptTemplateService.getAll(), []);
    useEffect(() => {
        if (receiptTemplatesData.isLoading) {
            return;
        }
        if (receiptTemplatesData.errors !== '' ||
            receiptTemplatesData.data === null ||
            receiptTemplatesData.data?.Error !== undefined ||
            receiptTemplatesData.data?.Data === undefined) {
            return;
        }
        let data = receiptTemplatesData.data.Data;
        setReceiptsTemplates(data)
    }, [receiptTemplatesData])

    return (
        <div className={"padding-responsive"}>
            <div className={"h2"}>
                Vizualizare recepții
            </div>
            <Divider className={"m-0"}/>
            <TemplatesDiv
                receiptsTemplates={receiptsTemplates}
                setReceiptsTemplates={setReceiptsTemplates}/>
            <Divider className={"my-3"}/>
            <ViewReceipt receiptTemplates={receiptsTemplates}/>
        </div>
    )
}

