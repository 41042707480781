import React, {CSSProperties} from "react";
import {Form, Input, InputNumber, Select} from "antd";
import {QuantityTypeArray} from "../../../../Enums/quantityType";
import {EditableCellProps} from "./editableCellProps";

export const EditableCell: React.FC<EditableCellProps> =
    ({
         editing,
         dataIndex,
         title,
         edit,
         inputType,
         record,
         index,
         children,
         ...restProps
     }) => {
        const defaultStyle: CSSProperties = {
            width: "100%"
        }
        let inputNode =
            inputType === 'number' ?
                <InputNumber style={defaultStyle}/> :
                <Input style={defaultStyle}/>;

        if (dataIndex === "quantityType") {
            inputNode = (
                <Select options={[...QuantityTypeArray.map(el => {
                    return {
                        value: el,
                        label: el
                    }
                })]}
                        style={defaultStyle}
                />
            )
        }

        return (
            <td {...restProps} onDoubleClick={() => {
                if (record !== undefined) {
                    edit(record)
                }
            }}
                id={`editable-cell-${index}`}
            >
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{margin: 0}}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };