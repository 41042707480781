import {APIHelper} from "../Configuration/apiHelper";
import {IResponse} from "../Models/IResponse";
import {LoginResponse} from "../Models/model/LoginResponse";

export default class LoginService {

    constructor(protected readonly _endpoint: string) {

    };

    async login(username: string, password: string): Promise<IResponse<LoginResponse>> {
        const formData: FormData = new FormData()
        formData.append("username", username);
        formData.append("password", password);
        return await APIHelper.request(`${this._endpoint}`, 'POST', formData, true)
    };

};