import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Form, Input, InputRef, message, Popconfirm, Space, Spin, Table, Typography} from 'antd';
import {QuantityType} from "../../../../Enums/quantityType";
import './styles.scss'
import {EditableCell} from "./editableCell";
import {PrimeProductsTableProps} from "./primeProductsTableProps";
import {DataIndex, TableItem} from "./tableItem";
import {ColumnType, FilterConfirmProps} from "antd/es/table/interface";
import {SearchOutlined} from "@ant-design/icons";
//@ts-ignore
import Highlighter from "react-highlight-words";
import Checkbox from "antd/es/checkbox/Checkbox";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import {DeletionsForProductResponse} from "../../../../Models/model/DeletionsForProductResponse";
import {ServiceContext, ServiceContextInstance} from "../../../../Core/serviceContext";


export const PrimeProductsTable = ({
                                       products,
                                       loaded,
                                       setProducts,
                                       saveProduct,
                                       deleteProduct,
                                       setCheckedProducts,
                                       checkedProducts
                                   }: PrimeProductsTableProps): React.JSX.Element => {
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [form] = Form.useForm();
    const [data, setData] = useState<TableItem[]>([]);
    const [editingKey, setEditingKey] = useState('');

    const searchInput = useRef<InputRef>(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const [checkedList, setCheckedList] = useState<string[]>([]);

    const [possibleDeletions, setPossibleDeletions] = useState<DeletionsForProductResponse | undefined>();

    useEffect(() => {
        setCheckedList([...checkedProducts])
    }, [checkedProducts])

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm({closeDropdown: false});
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<TableItem> => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}): JSX.Element => (
            <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Cauta dupa nume`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        handleSearch([e.target.value], confirm, dataIndex)
                    }}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Cauta
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{width: 90}}
                    >
                        Reseteaza
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Inchide
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean): JSX.Element => (
            <SearchOutlined style={{color: filtered ? '#1677ff' : undefined}}/>
        ),
        onFilter: (value, record): boolean =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible): void => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text): JSX.Element =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    useEffect(() => {
        setData([...products.map(
            p => {
                let newVar: TableItem = {
                    key: p.id!,
                    price: p.price!,
                    quantityType: p.quantityType!,
                    name: p.name!
                };
                return newVar
            }
        )])
    }, [products])

    const isEditing = (record: TableItem) => record.key === editingKey;

    const edit = (record: Partial<TableItem> & { key: React.Key }) => {
        form.setFieldsValue({name: '', price: 0, quantityType: '', ...record});
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key: React.Key) => {
        try {
            const row = (await form.validateFields()) as TableItem;
            const newData: TableItem[] = [...data];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                saveProduct({
                    id: item.key,
                    name: row.name,
                    quantityType: row.quantityType as QuantityType,
                    price: row.price
                })
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    async function findPossibleDeletions(id: string): Promise<void> {
        let response = await services.PrimeProductService.getPossibleMenusDeletions(id);
        if (response.Error !== undefined) {
            message.warning("Produsul nu a putut fi gasit!")
            return
        }
        setPossibleDeletions(response.Data)
    }

    async function deleteProd(id: string) {
        let response = await deleteProduct(id);
        setPossibleDeletions(undefined)
        if (response.Error !== undefined) {
            message.warning("Produsul nu a putut fi sters!")
            return
        }
        message.success("Produsul a fost sters")
        setProducts([...products.filter(p => {
            return Number(p.id) !== Number(id)
        })])
    }

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        let strings = e.target.checked ? data.map((item) => item.key) : [];
        setCheckedList(strings);
        setCheckedProducts(strings);
    };

    const mergedColumns = ([
        {
            title: <Checkbox indeterminate={checkedList.length > 0 && checkedList.length !== data.length}
                             onChange={onCheckAllChange} checked={checkedList.length === data.length}/>,
            dataIndex: "select",
            render: (_: any, record: TableItem) => {
                return <Checkbox
                    checked={checkedList.includes(record.key)}
                    onChange={(e) => {
                        let isChecked = e.target.checked;
                        let id = record.key;
                        if (isChecked) {
                            if (!checkedList.includes(id)) {
                                let strings2 = [...checkedList, id];
                                setCheckedList(strings2)
                                setCheckedProducts(strings2);
                            }
                        } else {
                            let strings1 = checkedList.filter((item) => item !== id);
                            setCheckedList(strings1)
                            setCheckedProducts(strings1);
                        }
                    }}/>
            },
            width: "10px"
        },
        {
            title: 'Denumire',
            dataIndex: 'name',
            editable: true,
            sorter: (a: any, b: any) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
            ...getColumnSearchProps('name'),
        },
        {
            title: 'UM',
            dataIndex: 'quantityType',
            editable: true,
        },
        {
            title: 'Pret',
            dataIndex: 'price',
            editable: true,
            sorter: (a: any, b: any) => a.price - b.price,
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            width: "170px",
            render: (_: any, record: TableItem) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link onClick={() => save(record.key)}
                                         style={{marginRight: 8}}>Salvează</Typography.Link>
                        <Typography.Link onClick={cancel}>Anulează</Typography.Link>
                    </span>
                ) : (
                    <span>
                        <Typography.Link disabled={editingKey !== ''} style={{marginRight: 8}}
                                         onClick={() => edit(record)}>Modifică</Typography.Link>
                        <Popconfirm title={"Sigur vrei să ștergi?"}
                                    placement={"topLeft"}
                                    description={
                                        <div>
                                            <div>
                                                {possibleDeletions === undefined && <Spin/>}
                                                {possibleDeletions !== undefined && (possibleDeletions.menuProducts.length > 0 || possibleDeletions.menus.length > 0) && (
                                                    <div>
                                                        <div>
                                                            Meniurile și preparatele care conțin acest produs vor fi șterse și ele.
                                                        </div>
                                                        <div>
                                                            Preparatele
                                                        </div>
                                                        <div>
                                                            <ul>
                                                                {possibleDeletions.menuProducts.map((menuProduct, index) => (
                                                                    <li key={index}>{menuProduct}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        <div>
                                                            Meniurile
                                                        </div>
                                                        <div>
                                                            <ul>
                                                                {possibleDeletions.menus.map((menu, index) => (
                                                                    <li key={index}>{menu}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                    onConfirm={() => deleteProd(record.key)}
                                    okText={"Sterge"}
                                    cancelText={"Renunta"}
                                    okButtonProps={{danger: true}}
                                    onOpenChange={(open) => {
                                        if (!open) {
                                            setPossibleDeletions(undefined)
                                        } else {
                                            findPossibleDeletions(record.key)
                                        }
                                    }}
                        >
                            <Typography.Link disabled={editingKey !== ''}>Sterge</Typography.Link>
                        </Popconfirm>
                    </span>
                );
            },
        },
    ] as ColumnStructure[]).map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: getOnCell(col, isEditing, calcIndex, edit),
        };
    });

    function calcIndex(record: TableItem, col: any): number {
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === record.key) {
                return i;
            }
        }
        return -1;
    }

    return (
        <Form form={form} component={false}>
            <Table
                id={"editable-table-prime-products"}
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                loading={!loaded}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={false}
            />
        </Form>
    );
};

interface ColumnStructure {
    title?: string,
    dataIndex?: string,
    editable?: boolean,
    sorter?: (a: any, b: any) => number,
    render?: (_: any, record: TableItem) => JSX.Element,
    filterDropdown?: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}: any) => JSX.Element,
    filterIcon?: (filtered: boolean) => JSX.Element,
    onFilter?: (value: boolean | React.Key, record: TableItem) => boolean,
    width?: number | string
}

function getOnCell(
    col: ColumnStructure,
    isEditing: (record: TableItem) => boolean,
    calcIndex: (record: TableItem, col: any) => number,
    edit: (record: (Partial<TableItem> & {
        key: React.Key
    })) => void) {
    return (record: TableItem) => ({
        record,
        inputType: col.dataIndex === 'price' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        index: calcIndex(record, col),
        edit: edit
    });
}