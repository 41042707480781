import {APIHelper} from "../Configuration/apiHelper";
import {IResponse} from "../Models/IResponse";
import {Provider} from "../Models/backend/Provider";
import {PrimeProduct} from "../Models/backend/PrimeProduct";
import {UploadFile} from "antd/es/upload/interface";
import {MediaFileObj} from "../Models/model/MediaFileObj";
import {DeletionsForProductResponse} from "../Models/model/DeletionsForProductResponse";

export default class ProviderService {

    constructor(protected readonly _endpoint: string) {

    };

    async getAll(): Promise<IResponse<Provider[]>> {
        return await APIHelper.request(`${this._endpoint}`, 'GET')
    };

    async getById(providerId: string | undefined): Promise<IResponse<Provider>> {
        if (providerId === undefined) {
            return {
                Status: 210,
                Data: undefined,
                Message: 'Provider id is undefined'
            }
        }
        return await APIHelper.request(`${this._endpoint}/${providerId}`, 'GET')
    };

    async getProviderProducts(providerId: string): Promise<IResponse<PrimeProduct[]>> {
        return await APIHelper.request(`${this._endpoint}/${providerId}/products`, 'GET')
    };

    async addOrUpdate(id: string | undefined,
                      name: string,
                      emails: string[] | undefined,
                      phones: string[] | undefined,
                      address: string | undefined,
                      action: "CREATE" | "UPDATE"): Promise<IResponse<Provider>> {
        const request = {
            id: id,
            name: name,
            emails: emails,
            phones: phones,
            address: address,
            actionToPerform: action
        }
        return await APIHelper.request(`${this._endpoint}`, 'PUT',
            JSON.stringify(request), false)
    };

    async deleteProvider(providerId: string): Promise<IResponse<void>> {
        return await APIHelper.request(`${this._endpoint}/delete/${providerId}`, 'DELETE')
    };

    async itemsThatMightGetDeleted(providerId: string): Promise<IResponse<DeletionsForProductResponse>> {
        return await APIHelper.request(`${this._endpoint}/related-to/${providerId}`, 'GET')
    };

    async addProduct(providerId: string, product: PrimeProduct): Promise<IResponse<PrimeProduct>> {
        let bodyJson = JSON.stringify(product);
        return await APIHelper.request(`${this._endpoint}/${providerId}/add-product`, 'POST', bodyJson, false)
    };

    async importProducts(providerId: string, files: UploadFile[], fileType: string): Promise<IResponse<PrimeProduct[]>> {
        const formData: FormData = new FormData()
        for (let file of files) {
            let originFileObj = file.originFileObj;
            if (originFileObj !== undefined) {
                formData.append("files", originFileObj);
            }
        }
        return await APIHelper.request(`${this._endpoint}/${providerId}/import-products/${fileType.toLowerCase()}`, 'POST', formData, true)
    };

    async exportProducts(providerId: string, fileType: string, productsIds: string[]): Promise<IResponse<MediaFileObj>> {
        return await APIHelper.request(`${this._endpoint}/${providerId}/export-products/${fileType.toLowerCase()}`, 'POST', JSON.stringify(productsIds), false)
    };


};