import {APIHelper} from "../Configuration/apiHelper";
import {IResponse} from "../Models/IResponse";
import {UserDb} from "../Models/backend/UserDb";

export default class UserService {

    constructor(protected readonly _endpoint: string) {

    };

    async getUser(): Promise<IResponse<UserDb>> {
        return await APIHelper.request(`${this._endpoint}`, 'GET')
    };

    async update(email: string | undefined | null, phone: string | undefined, address: string | undefined, businessName: string | undefined): Promise<IResponse<UserDb>> {
        const request = {
            email: email?.trim(),
            phone: phone?.trim(),
            address: address?.trim(),
            businessName: businessName?.trim()
        }
        return await APIHelper.request(`${this._endpoint}`, 'PUT',
            JSON.stringify(request), false)
    };

    async createDemoAccount(email: string): Promise<IResponse<void>> {
        const formData: FormData = new FormData()
        formData.append("email", email);
        return await APIHelper.request(`${this._endpoint}/demo-account`, 'PUT', formData, true)
    };

};