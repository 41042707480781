export const LOGIN_ENDPOINT: string = "/login";
export const AUTH_ENDPOINT: string = "/auth";
export const USER_ENDPOINT: string = "/users";
export const CONTACT_ENDPOINT: string = "/contact";
export const PROVIDER_ENDPOINT: string = "/providers";
export const PRIME_PRODUCT_ENDPOINT: string = "/prime-products";
export const ORDERS_ENDPOINT: string = "/orders";
export const RECEIPT_TEMPLATES_ENDPOINT: string = "/receipts-templates";
export const RECEIPTS_ENDPOINT: string = "/receipts";
export const SETTINGS_ENDPOINT: string = "/settings";
export const MENU_ENDPOINT: string = "/menu";
export const MENU_PRODUCTS_ENDPOINT: string = "/menu-products";