import {PrimeProduct} from "../../Models/backend/PrimeProduct";
import {QuantityType} from "../../Enums/quantityType";

export interface MinQuantity {
    quantity: number;
    type: QuantityType;
}

const basicQuantityTypes: QuantityType[] = ["KG", "GR", "L", "ML"]
const basicQuantityMinValues = {
    "KG": {quantity: 0.01, type: "KG"},
    "GR": {quantity: 1, type: "GR"},
    "L": {quantity: 0.01, type: "L"},
    "ML": {quantity: 1, type: "ML"},
}
const basicQuantityNormalValues = {
    "KG": {quantity: 1, type: "KG"},
    "GR": {quantity: 1, type: "GR"},
    "L": {quantity: 1, type: "L"},
    "ML": {quantity: 1, type: "ML"},
}

const quantityRegex = new RegExp(
    "(\\d+[.x]\\d+[.]*\\d+[ ]*(kg|g|l|ml|gr))|" +
    "(\\d+[.]\\d+[.x]\\d+[ ]*(kg|g|l|ml|gr))|" +
    "(\\d+[.x]\\d+[ ]*(kg|g|l|ml|gr))|" +
    "(\\d+[ ]*(kg|g|l|ml|gr))|" +
    "(\\d+[,x]\\d+[,]*\\d+[ ]*(kg|g|l|ml|gr))|" +
    "((\\d+[,]\\d+[,x]\\d+[ ]*(kg|g|l|ml|gr)))|" +
    "(\\d+[,x]\\d+[ ]*(kg|g|l|ml|gr))|" +
    "(\\d+[ ]*(kg|g|l|ml|gr))"
);
const quantityTypeRegex = new RegExp(
    "(kg|gr|l|ml|g)"
)

function extractMinQuantityTypeFromProductName(product: PrimeProduct) {
    let name = product.name!.toLowerCase();
    const matchQuantity = name.match(quantityRegex);
    if (matchQuantity) {
        let quantity: string = matchQuantity[0];
        let regExpMatchArray = quantity.match(quantityTypeRegex);
        if (regExpMatchArray) {
            const quantityType = regExpMatchArray[0];
            quantity = quantity.replace(quantityType, "");
            if (quantity.includes("x")) {
                let strings = quantity.split("x");
                quantity = (parseFloat(strings[0]) * parseFloat(strings[1])) + "";
            }
            return {
                quantity: parseFloat(quantity),
                type: quantityType.trim().toUpperCase() as QuantityType
            }
        } else {
            return undefined
        }
    } else {
        return undefined
    }
}

export function extractMinQuantity(product: PrimeProduct): MinQuantity | undefined {
    if (basicQuantityTypes.includes(product.quantityType!)) {
        let quantityType1: "KG" | "GR" | "ML" | "L" = product.quantityType as "KG" | "GR" | "ML" | "L";
        return basicQuantityMinValues[quantityType1] as MinQuantity;
    }

    let extractMinQuantityTypeFromProductName1 = extractMinQuantityTypeFromProductName(product);
    if (extractMinQuantityTypeFromProductName1 !== undefined) {
        return extractMinQuantityTypeFromProductName1
    }

    return {
        quantity: 1,
        type: product.quantityType!
    }
}

export function extractBasicQuantity(product: PrimeProduct): MinQuantity | undefined {
    if (basicQuantityTypes.includes(product.quantityType!)) {
        let quantityType1: "KG" | "GR" | "ML" | "L" = product.quantityType as "KG" | "GR" | "ML" | "L";
        return basicQuantityNormalValues[quantityType1] as MinQuantity;
    }

    let extractMinQuantityTypeFromProductName1 = extractMinQuantityTypeFromProductName(product);
    if (extractMinQuantityTypeFromProductName1 !== undefined) {
        return extractMinQuantityTypeFromProductName1
    }

    return {
        quantity: 1,
        type: product.quantityType!
    }
}

function extractPercentage(
    s: string,
    minQuantity: MinQuantity,
    totalQuantity: number,
    bigQ: QuantityType,
    letters: string[]) {
    let number = 0;
    try {
        number = parseFloat(
            s.replace(letters[0], "")
                .replace(letters[1], "")
                .trim()
        );
    } catch (e) {
        return undefined
    }

    if (s.includes(letters[0])) {
        number = number * 1000;// l to ml || kg to g
    }
    if (minQuantity.type === bigQ) {
        totalQuantity = totalQuantity * 1000;// l to ml
    }
    number = number === 0 ? 1 : number
    let x = totalQuantity / number;
    return (100 / x);
}

export function proportionToPercentage(proportion: string | undefined, minQuantity: MinQuantity): number | undefined {
    if (proportion === undefined) {
        return undefined;
    }
    if (containsLetters(proportion)) {
        // that means is something like 500g, 330ml, 1L, etc
        let s = proportion.toLowerCase()
        let divider = minQuantity.quantity;
        //---------GR, KG--------
        if ((s.includes("g") || s.includes("gr") || s.includes("kg")) && (minQuantity.type === "GR" || minQuantity.type === "KG")) {
            return extractPercentage(s, minQuantity, divider, "KG", ["kg", "g"]);
        }
        //--------------L, ML----------
        if ((s.includes("l") || s.includes("ml")) && (minQuantity.type === "ML" || minQuantity.type === "L")) {
            return extractPercentage(s, minQuantity, divider,"L", ["l", "m"]);
        }
        return undefined
    }
    if (proportion.includes("%")) {
        let num = proportion.replace("%", "");
        try {
            return (parseFloat(num))
        } catch (e) {
            return undefined
        }
    }
    if (proportion.includes("/")) {
        let nums = proportion.split("/");
        if (nums.length !== 2) {
            return undefined
        }
        try {
            let n1 = parseFloat(nums[0]);
            let n2 = parseFloat(nums[1]);
            let number = n1 * 100 / n2;
            return (number);
        } catch (e) {
            return undefined
        }
    }
    if (proportion.includes(".")) {
        try {
            let number1 = parseFloat(proportion);
            let number = number1 * 100;
            return (number);
        } catch (e) {
            return undefined
        }
    }
    try {
        return parseFloat(proportion) * 100
    } catch (e) {
        return undefined
    }
}

const containsLetters = (str: string): boolean => {
    const letterRegex = /[a-zA-Z]/;
    return letterRegex.test(str);
};