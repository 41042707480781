import {APIHelper} from "../Configuration/apiHelper";
import {IResponse} from "../Models/IResponse";
import {ReceiptTemplate} from "../Models/backend/ReceiptTemplate";

export default class ReceiptTemplateService {

    constructor(protected readonly _endpoint: string) {

    };

    async getAll(): Promise<IResponse<ReceiptTemplate[]>> {
        return await APIHelper.request(`${this._endpoint}`, 'GET')
    };

    async put(receipt: ReceiptTemplate): Promise<IResponse<ReceiptTemplate>> {
        return await APIHelper.request(`${this._endpoint}`, 'PUT',
            JSON.stringify(receipt), false)
    };

    async delete(id: string): Promise<IResponse<void>> {
        return await APIHelper.request(`${this._endpoint}/${id}`, 'DELETE')
    };

};