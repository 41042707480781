import {useState} from "react";
import {blue} from "../../Assets/Styles/variables";
import {Typography} from 'antd';

export const TextAreaShowMoreOrLess = (props: { description?: string }): JSX.Element => {
    const textsForBtn = ["Afișează mai puțin", "Afișează mai mult"]

    const [showMoreLines, setShowMoreLines] = useState<boolean>(false)
    const [btnTextType, setBtnTextType] = useState<number>(1)

    function changeLines() {
        setShowMoreLines(!showMoreLines)
        setBtnTextType(1 - btnTextType)
    }

    return (
        <div>
            {
                showMoreLines ? (
                        <textarea style={{
                            overflowY: "auto",
                            outline: "none",
                            width: "100%",
                            border: "none",
                            height: "100px",
                            minHeight: "10px"
                        }} readOnly
                                  defaultValue={props.description}
                        />
                    ) :
                    (
                        <div style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace:"nowrap"}}>
                            {props.description}
                        </div>
                    )
            }
            {
                (props.description?.length ?? 0) > 0 && (
                    <div className={"pointer"}
                         onClick={changeLines}
                         style={{display: "block", color: blue}}
                    >
                        {textsForBtn[btnTextType]}
                    </div>
                )
            }
        </div>
    )
}