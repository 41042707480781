import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import {Button, message, Modal} from "antd";
import {mergeStyles} from "@fluentui/react";
import {ServiceContext, ServiceContextInstance} from "../../../Core/serviceContext";
import {MediaFileObj} from "../../../Models/model/MediaFileObj";
import {extensionFor, FileFormat} from "../../../Enums/fileFormat";
import {downloadFile} from "../../../Library/utils/documentDownload";

export const ExportProductsForm = (
    {providerId, showModal, setShowModal,productsIds}: {
        providerId: string;
        showModal: boolean;
        setShowModal: Dispatch<SetStateAction<boolean>>;
        // products to be exported
        productsIds: string[];
    }
): JSX.Element => {
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedFileType, setSelectedFileType] = useState<FileFormat>()

    const handleOk = async () => {
        setLoading(true)
        let response = await services.ProviderService.exportProducts(providerId, selectedFileType!, productsIds);
        if (response.Error !== undefined || response.Data === undefined) {
            message.error("Eroare la crearea fisierului!")
            setLoading(false)
            return
        }
        reset();
        let fileType = extensionFor(selectedFileType!)
        let fileName = "produse." + fileType;
        let file: MediaFileObj = response.Data;
        downloadFile(file, fileName)
    };

    function reset() {
        setShowModal(false);
        setSelectedFileType(undefined)
        setLoading(false)
    }

    const handleCancel = () => {
        reset();
    };

    const selectedTypeStyle: string = mergeStyles({
        backgroundColor: "green",
        color: "white"
    })

    return (
        <Modal title="Exportă produse"
               open={showModal}
               style={{minWidth: "40%", maxWidth: "80%"}}
               onOk={handleOk}
               onCancel={handleCancel}
               footer={[
                   <Button key="back" onClick={handleCancel}>
                       Renunță
                   </Button>,
                   <Button key="submit" type="primary"
                           loading={loading}
                           disabled={selectedFileType === undefined}
                           onClick={handleOk}>
                       Exportă
                   </Button>
               ]}
        >
            <div>
                <div style={{fontSize: "18px"}} className={"d-flex align-items-center"}>
                    <div>Selectează tipul fișierului<span style={{color: "red"}}>*</span>:</div>
                    <ul style={{listStyle: "none", columnGap: "10px"}}
                        className={"d-flex p-0 ms-2 mt-2"}
                    >
                        <li className={`border border-2 py-1 px-2 pointer rounded ${selectedFileType === "JSON" && selectedTypeStyle}`}
                            style={{transition: "0.3s"}}
                            onClick={() => setSelectedFileType("JSON")}
                        >
                            JSON
                        </li>
                        <li className={`border border-2 py-1 px-2 pointer rounded ${selectedFileType === "CSV" && selectedTypeStyle}`}
                            style={{transition: "0.3s"}}
                            onClick={() => setSelectedFileType("CSV")}
                        >
                            CSV
                        </li>
                        <li className={`border border-2 py-1 px-2 pointer rounded ${selectedFileType === "PDF" && selectedTypeStyle}`}
                            style={{transition: "0.3s"}}
                            onClick={() => setSelectedFileType("PDF")}
                        >
                            PDF
                        </li>
                        <li className={`border border-2 py-1 px-2 pointer rounded ${selectedFileType === "WORD" && selectedTypeStyle}`}
                            style={{transition: "0.3s"}}
                            onClick={() => setSelectedFileType("WORD")}
                        >
                            WORD
                        </li>
                    </ul>
                </div>
                <div>

                </div>
            </div>
        </Modal>
    )
}