import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {PrimeProduct} from "../../../Models/backend/PrimeProduct";
import {Button, Divider, message, Modal, Table, Upload} from "antd";
import {mergeStyles} from "@fluentui/react";
import {UploadOutlined} from "@ant-design/icons";
import {UploadFile} from "antd/es/upload/interface";
import {ServiceContext, ServiceContextInstance} from "../../../Core/serviceContext";

export const ImportProductsForm = (
    {providerId, showModal, setShowModal, setProducts, products}: {
        providerId: string,
        showModal: boolean,
        setShowModal: Dispatch<SetStateAction<boolean>>,
        setProducts: Dispatch<SetStateAction<PrimeProduct[]>>,
        products: PrimeProduct[]
    }
): JSX.Element => {
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedFileType, setSelectedFileType] = useState<"word" | "json" | "csv">()
    const [content, setContent] = useState<JSX.Element>()
    const [selectedFiles, setSelectedFiles] = useState<UploadFile[]>([])

    const handleOk = async () => {
        setLoading(true)
        let response = await services.ProviderService.importProducts(providerId, selectedFiles, selectedFileType!);
        let data = response.Data;
        if (response.Error !== undefined || data === undefined) {
            message.warning("Fisierele nu au putut fi incarcate, verificati din nou continutul!")
            setLoading(false)
            return
        }
        reset();
        message.success("Datele au fost importate!")
        setProducts([...products, ...data])
    };

    function reset() {
        setShowModal(false);
        setContent(undefined)
        setSelectedFileType(undefined)
        setLoading(false)
        setSelectedFiles([])
    }

    const handleCancel = () => {
        reset();
    };

    const selectedTypeStyle: string = mergeStyles({
        backgroundColor: "green",
        color: "white"
    })

    function setContentJson() {
        const example: string =
            `
    [
        { 
            "name":"salam uscat",
            "price":23.5,
            "um":"BUC"
        },
        ...
    ]
            `
        setContent(
            <div style={{fontSize: "18px"}}>
                <Divider className={"m-0 pt-3"}/>
                <div>
                    Extensia fisierului trebuie sa fie <b>json</b>.
                    Structura fisierului <b>JSON</b> trebuie sa fie urmatoarea:
                </div>
                <div style={{backgroundColor: "lightgray"}}
                     className={"border rounded"}
                >
                    <pre>
                        {example}
                    </pre>
                </div>
            </div>
        )
    }

    function setContentCsv() {
        const example: string =
            `
    nume, pret, unitate masura,
    salam uscat, 23.5, BUC,
    ardei rosu, 4.99, KG,
    ...
            `
        setContent(
            <div style={{fontSize: "18px"}}>
                <Divider className={"m-0 pt-3"}/>
                <div>
                    Extensia fisierului trebuie sa fie <b>csv</b>.
                    Structura fisierului <b>CSV</b> trebuie sa fie urmatoarea:
                </div>
                <div style={{backgroundColor: "lightgray"}}
                     className={"border rounded"}
                >
                    <pre>
                        {example}
                    </pre>
                </div>
            </div>
        )
    }

    function setContentWord() {
        const dataSource = [
            {
                key: '1',
                name: 'salam uscat',
                price: 23.5,
                um: 'BUC',
            },
            {
                key: '2',
                name: 'ardei rosu',
                price: 5.99,
                um: 'KG',
            },
        ];
        const columns = [
            {
                title: 'Nume',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Pret',
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: 'UM',
                dataIndex: 'um',
                key: 'um',
            },
        ];
        const example: JSX.Element = <Table dataSource={dataSource} columns={columns} pagination={false}/>;
        setContent(
            <div style={{fontSize: "18px"}}>
                <Divider className={"m-0 pt-3"}/>
                <div>
                    Extensia fisierului trebuie sa fie <b>doc</b> sau <b>docx</b>.
                    Fisierul <b>WORD</b> trebuie sa contina un tabel / tabele cu urmatoarea structura:
                </div>
                <div>
                    {example}
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (selectedFileType === "json") {
            setContentJson();
        } else if (selectedFileType === "csv") {
            setContentCsv()
        } else if (selectedFileType === "word") {
            setContentWord();
        }
    }, [selectedFileType])

    return (
        <Modal title="Importă produse"
               open={showModal}
               style={{minWidth: "40%", maxWidth: "80%"}}
               onOk={handleOk}
               onCancel={handleCancel}
               footer={[
                   <Button key="back" onClick={handleCancel}>
                       Renunță
                   </Button>,
                   <Button key="submit" type="primary"
                           loading={loading}
                           disabled={selectedFiles.length === 0}
                           onClick={handleOk}>
                       Importă
                   </Button>
               ]}
        >
            <div>
                <div style={{fontSize: "18px"}} className={"d-flex align-items-center"}>
                    <div>Selectează tipul fișierului<span style={{color: "red"}}>*</span>:</div>
                    <ul style={{listStyle: "none", columnGap: "10px"}}
                        className={"d-flex p-0 ms-2 mt-2"}
                    >
                        <li className={`border border-2 py-1 px-2 pointer rounded ${selectedFileType === "json" && selectedTypeStyle}`}
                            style={{transition: "0.3s"}}
                            onClick={() => setSelectedFileType("json")}
                        >
                            JSON
                        </li>
                        <li className={`border border-2 py-1 px-2 pointer rounded ${selectedFileType === "csv" && selectedTypeStyle}`}
                            style={{transition: "0.3s"}}
                            onClick={() => setSelectedFileType("csv")}
                        >
                            CSV
                        </li>
                        <li className={`border border-2 py-1 px-2 pointer rounded ${selectedFileType === "word" && selectedTypeStyle}`}
                            style={{transition: "0.3s"}}
                            onClick={() => setSelectedFileType("word")}
                        >
                            WORD
                        </li>
                    </ul>
                </div>
                <div>
                    {content}
                </div>
                <div className={"mt-3"}>
                    {
                        selectedFileType !== undefined && (
                            <Upload
                                beforeUpload={() => false}
                                listType={"picture"}
                                onChange={(info) => {
                                    let file = info.file;
                                    let fileName = file.name;
                                    let fileNameLower = fileName.toLowerCase();
                                    if (fileNameLower.endsWith("." + selectedFileType.toLowerCase()) || (
                                        selectedFileType === "word" && (fileNameLower.endsWith(".doc") || fileNameLower.endsWith(".docx"))
                                    )) {
                                        setSelectedFiles([...info.fileList])
                                    } else {
                                        message.warning("Fisierul ales nu este de tip " + selectedFileType.toLowerCase());
                                    }
                                }
                                }
                                fileList={selectedFiles}
                            >
                                <Button icon={<UploadOutlined/>}>Încarcă fișiere... </Button>
                            </Upload>
                        )
                    }
                </div>
            </div>
        </Modal>
    )
}