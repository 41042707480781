import {IResponse} from "../Models/IResponse";
import {MediaFileObj} from "../Models/model/MediaFileObj";
import {APIHelper} from "../Configuration/apiHelper";
import {Receipt} from "../Models/backend/Receipt";

export default class ReceiptService {

    constructor(protected readonly _endpoint: string) {

    };

    async createReceipt(orderId: string): Promise<IResponse<void>> {
        const formData = new FormData();
        formData.append('orderId', orderId);
        return await APIHelper.request(
            `${this._endpoint}/create`, 'POST', formData, true)
    };

    async downloadReceiptFile(id: string): Promise<IResponse<MediaFileObj>> {
        return await APIHelper.request(
            `${this._endpoint}/download/${id}`, 'GET')
    };

    async delete(id: string): Promise<IResponse<void>> {
        return await APIHelper.request(
            `${this._endpoint}/${id}`, 'DELETE')
    };

    async getAll(): Promise<IResponse<Receipt[]>> {
        return await APIHelper.request(`${this._endpoint}`, 'GET')
    };

    async update(id: string, receipt: Receipt): Promise<IResponse<Receipt>> {
        return await APIHelper.request(`${this._endpoint}/${id}`, 'PUT', JSON.stringify(receipt), false)
    };

};