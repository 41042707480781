import {APIHelper} from "../Configuration/apiHelper";
import {IResponse} from "../Models/IResponse";
import {Menu} from "../Models/backend/Menu";
import {MenuSortOrder} from "../Enums/MenuSortOrder";

export default class MenuService {

    constructor(protected readonly _endpoint: string) {

    };

    async getAll(sorted?: MenuSortOrder): Promise<IResponse<Menu[]>> {
        let params = ''
        if (sorted !== undefined) {
            params = "?sort=" + MenuSortOrder[sorted]
        }
        return await APIHelper.request(`${this._endpoint}${params}`, 'GET')
    };

    async get(id: string): Promise<IResponse<Menu>> {
        return await APIHelper.request(`${this._endpoint}/${id}`, 'GET')
    };

    async create(menu: Menu): Promise<IResponse<Menu>> {
        return await APIHelper.request(
            `${this._endpoint}`,
            'POST',
            JSON.stringify(menu),
            false
        )
    };

    async update(menu: Menu): Promise<IResponse<Menu>> {
        const request = {
            name: menu.name,
            description: menu.description,
            price: menu.totalPrice,
            itemsIds: menu.items?.map(el => el.id)
        }
        return await APIHelper.request(
            `${this._endpoint}/${menu.id}`,
            'PUT',
            JSON.stringify(request),
            false
        )
    };

    async delete(id: string): Promise<IResponse<void>> {
        return await APIHelper.request(
            `${this._endpoint}/${id}`,
            'DELETE')
    }
};