import {APIHelper} from "../Configuration/apiHelper";
import {IResponse} from "../Models/IResponse";

export default class SettingsService {

    constructor(protected readonly _endpoint: string) {

    };

    async nextOrderId(): Promise<IResponse<number>> {
        return await APIHelper.request(`${this._endpoint}/next-order-id`, 'GET')
    };

    async nextReceiptId(): Promise<IResponse<number>> {
        return await APIHelper.request(`${this._endpoint}/next-receipt-id`, 'GET')
    };

};