import {MediaFileObj} from "../../Models/model/MediaFileObj";
import {computeMediaFileContent} from "./arrayBuffer";

export const downloadFile = (file: MediaFileObj, fileName: string) => {
    let content = computeMediaFileContent(file.bytes, file.type);
    let htmlA = document.createElement("a");
    htmlA.setAttribute("download", fileName)
    htmlA.setAttribute("href", content)
    htmlA.click()
    setTimeout(() => {
        htmlA.remove()
    }, 100)
}