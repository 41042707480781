import {Button, Card, InputNumber} from "antd";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {PrimeProductWithQuantity} from "../../../Models/PrimeProductWithQuantity";

/**
 * It is the card that appears new when you add a new item in cart when placing an order
 */
export const OrderListItem = (props: {
    product: PrimeProductWithQuantity;
    list: PrimeProductWithQuantity[];
    setList: Dispatch<SetStateAction<PrimeProductWithQuantity[]>>;
}): JSX.Element => {
    // element from props.list will have no id
    // use element.name -> it is set to be unique

    const quantityModifierButtonClassName: string = "mx-1"

    const [quantity, setQuantity] = useState<number>()

    useEffect(() => {
        setQuantity(props.product.quantity)
    }, [props.product])

    useEffect(() => {
        let product = props.product;
        product.quantity = quantity
        let primeProductWithQuantities = props.list.map(p => {
            let b = String(p.orderProduct!.name) === String(product.orderProduct!.name);
            if (b) {
                return product
            } else {
                return p
            }
        });
        props.setList([...primeProductWithQuantities])
    }, [quantity])

    function removeProduct() {
        let newList = props.list.filter(p => p.orderProduct!.name !== props.product.orderProduct!.name);
        props.setList(newList);
    }

    function computeTitle(): JSX.Element {
        let name = props.product.orderProduct!.name
        let price = props.product.orderProduct!.price
        let quantityType = props.product.orderProduct!.quantityType
        let totalPrice = price ? price * quantity! : 0
        let numberToShow = Math.round((totalPrice + Number.EPSILON) * 100) / 100
        return (
            <div className={"px-2"}>
                <div className={"d-flex"}>
                    <span className={"d-flex justify-content-between w-100 me-3"}>
                        <span style={{fontWeight: "bold"}}>{name}</span>
                        <span
                            style={{fontWeight: "normal"}}>{price} ron/ {quantityType}</span>
                    </span>
                    <button className={"btn btn-danger px-1 py-0"} style={{display: "inline"}}
                            onClick={removeProduct}>X
                    </button>
                </div>
                <div style={{fontWeight: "normal"}}>
                    total price: <span>{numberToShow.toLocaleString()}</span>
                </div>
            </div>

        )
    }

    function updateQuantity(adaos: number) {
        let newVal = quantity! + adaos;
        if (newVal < 0) {
            newVal = 0;
        }
        setQuantity(newVal)
    }

    return (
        <Card title={computeTitle()} bordered={false} className={"my-1"} bodyStyle={{padding: "0"}}
              headStyle={{padding: "0"}} rootClassName={"shadow-sm"}>
            <div className={"p-1 d-flex justify-content-center"}>
                <Button type={"primary"} className={quantityModifierButtonClassName}
                        onClick={() => updateQuantity(-5)}>-5</Button>
                <Button type={"primary"} className={quantityModifierButtonClassName}
                        onClick={() => updateQuantity(-1)}>-1</Button>
                <InputNumber type={"number"} min={0} value={quantity} onInput={(val) => setQuantity(Number(val))}/>
                <Button type={"primary"} className={quantityModifierButtonClassName}
                        onClick={() => updateQuantity(+1)}>+1</Button>
                <Button type={"primary"} className={quantityModifierButtonClassName}
                        onClick={() => updateQuantity(+5)}>+5</Button>
                <Button type={"primary"} className={quantityModifierButtonClassName}
                        onClick={() => updateQuantity(+10)}>+10</Button>
            </div>
        </Card>
    )
}