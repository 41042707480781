import {Divider, Empty, FloatButton, Input, Select, Spin} from "antd";
import {ServiceContext, ServiceContextInstance} from "../../../../Core/serviceContext";
import React, {useContext, useEffect, useState} from "react";
import {IFetchResult} from "../../../../Hooks/useFetch.types";
import {useFetch} from "../../../../Hooks/useFetch";
import {Order} from "../../../../Models/backend/Order";
import {OrdersWithDates} from "../../../../Models/model/OrdersWithDates";
import {Provider} from "../../../../Models/backend/Provider";
import {OrderCard} from "./orderCard";

export const ViewOrder = ({refresh}: {
    refresh: boolean,
}): JSX.Element => {
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [ordersChanged, setOrdersChanged] = useState<boolean>()
    const ordersData: IFetchResult<Order[]> = useFetch<Order[]>(() => services.OrderService.getAll(), [ordersChanged, refresh]);
    const [orders, setOrders] = useState<Order[]>()
    const [ordersWithDates, setOrdersWithDates] = useState<OrdersWithDates[]>()

    const providersData: IFetchResult<Provider[]> = useFetch<Provider[]>(() => services.ProviderService.getAll(), [ordersChanged, refresh]);
    const [providers, setProviders] = useState<Provider[]>()
    const [selectedProvider, setSelectedProvider] = useState<Provider>()

    const [searchOrderName, setSearchOrderName] = useState<string>()
    const [loadedData, setLoadedData] = useState<boolean>(false)

    useEffect(() => {
        if (ordersData.isLoading) {
            return;
        }
        if (ordersData.errors !== '' ||
            ordersData.data === null ||
            ordersData.data?.Error !== undefined ||
            ordersData.data?.Data === undefined) {
            return;
        }
        let data = ordersData.data.Data;
        setOrders(data)
    }, [ordersData])

    useEffect(() => {
        if (providersData.isLoading) {
            return;
        }
        if (providersData.errors !== '' ||
            providersData.data === null ||
            providersData.data?.Error !== undefined ||
            providersData.data?.Data === undefined) {
            return;
        }
        let data = providersData.data.Data;
        setProviders(data)
    }, [providersData])

    useEffect(() => {
        if (orders !== undefined && providers !== undefined) {
            setLoadedData(true)
        } else {
            setLoadedData(false)
        }
    }, [providers, orders])

    useEffect(() => {
        setLoadedData(false)
        setOrdersWithDates(undefined)
    }, [refresh])

    useEffect(() => {
        if (orders === undefined) {
            return
        }
        if (orders.length === 0) {
            setOrdersWithDates([])
            return
        }

        let map: OrdersWithDates[] = []
        let list: Order[] = []
        let lastDate = getDate(orders[0].createdDate!)
        for (let order of orders) {
            //if provider is selected skip the orders that does not have that provider
            if (selectedProvider !== undefined) {
                if (order.providerName !== selectedProvider.name) {
                    continue
                }
            }
            const orderName = order.name.toLowerCase().trim()
            if (searchOrderName === undefined || searchOrderName.trim() === "") {

            } else {
                if (!orderName.includes(searchOrderName.toLowerCase().trim())) {
                    continue
                }
            }
            let date = getDate(order.createdDate!)
            if (date !== lastDate) {
                const newEntry: OrdersWithDates = {
                    date: lastDate,
                    orders: list
                }
                lastDate = date
                map.push(newEntry)
                list = [order]
            } else {
                list.push(order)
            }
        }
        if (list.length !== 0) {
            const newEntry: OrdersWithDates = {
                date: lastDate,
                orders: list
            }
            map.push(newEntry)
        }
        setOrdersWithDates(map)
    }, [orders, selectedProvider, searchOrderName, refresh])

    function getDate(dateString: string): string {
        return dateString.split("T")[0]
    }

    return (
        <>
            <div style={{position: "relative"}}>
                <FloatButton.BackTop/>
                <div className={"d-flex align-items-center mb-2"} style={{gap: "12px"}}>
                    <div>Vezi comenzile de la:</div>
                    <Select
                        options={[{
                            label: "toți",
                            value: "all",
                            provider: undefined
                        },
                            (providers === undefined ? [] : [...providers]).map(p => {
                                return {
                                    label: p.name,
                                    value: p.id,
                                    provider: p
                                }
                            })]}
                        onChange={(val, opt) => {
                            // @ts-ignore
                            setSelectedProvider(opt.provider)
                        }}
                        defaultValue={{
                            label: "toți",
                            value: "all",
                            provider: undefined
                        }}
                        style={{width: "400px"}}
                    />
                </div>
                <div className={"d-flex align-items-center"}>
                    <div style={{marginRight: "10px"}}>
                        Cauta dupa denumire:
                    </div>
                    <Input style={{width: "400px"}} placeholder={"Cauta comenzi dupa nume"}
                           value={searchOrderName}
                           onChange={(e) => setSearchOrderName(e.currentTarget.value)}
                    />
                </div>
                {
                    (!loadedData || ordersWithDates === undefined) ?
                        (
                            <div className={"pt-5"} style={{marginLeft: "35%"}}>
                                <Spin size={"large"} style={{fontSize: "50px", transform: "scale(2.1)"}}/>
                            </div>
                        ) :
                        (
                            <div className={""}>
                                {
                                    ordersWithDates.map((el, index) => (
                                        <div key={index}>
                                            <Divider orientation={"left"} style={{fontSize: "20px"}}>
                                                {el.date}
                                            </Divider>
                                            <div className={"d-flex flex-wrap"} style={{gap: "12px", fontSize: "20px"}}>
                                                {
                                                    el.orders.map((order, index) => (
                                                        <OrderCard
                                                            setOrdersChanged={() => setOrdersChanged(!ordersChanged)}
                                                            order={order}
                                                            key={index}
                                                            providerName={order.providerName}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    ordersWithDates.length === 0 && (
                                        <Empty description={"Nu sunt comenzi generate"}/>
                                    )
                                }
                            </div>
                        )
                }
            </div>
        </>

    )
}