import React, {useEffect, useState} from "react";
import {InputNumber} from "antd";
import {ReceiptProduct} from "../../../../../Models/model/ReceiptProduct";
import {twoDecimals} from "../../../../../Library/utils/mathUtil";

export interface ExcelTableRowProps {
    product: ReceiptProduct;
    index: number;
    commercialExcess?: number;
    handleReceiptProductChange: (receiptProduct: ReceiptProduct) => void;
    setHasChanged: (hasChanged: boolean) => void;
}

export const ExcelTableRow = (props: ExcelTableRowProps): JSX.Element => {

    const [row, setRow] = useState<ReceiptProduct>(extracted());

    function extracted() {
        let product = props.product;
        return {
            field0: props.index + 1,
            field1: product.field1,
            field2: product.field2,
            field3: twoDecimals(product.field4),
            field4: twoDecimals(product.field4),
            field5: twoDecimals(product.field5),
            field6: twoDecimals(twoDecimals(product.field4) * twoDecimals(product.field5)),
            field7: 0,
            field8: twoDecimals(props.commercialExcess),
            field9: twoDecimals(twoDecimals(product.field5) * twoDecimals(props.commercialExcess)),
            field10: 0,
            field11: twoDecimals(twoDecimals(product.field5) + twoDecimals(product.field5) * twoDecimals(props.commercialExcess)),
            field12: twoDecimals(twoDecimals(product.field4) * twoDecimals(product.field5)),
            field13: 0,
            field14: 0,
            field15: 0
        };
    }

    useEffect(() => {
        setRow(extracted())
    }, [props.product, props.commercialExcess, props.index])


    useEffect(() => {
        props.handleReceiptProductChange({...row})
    }, [row])

    return (
        <tr className="row13">
            <td className="column0 style3 n">
                {row.field0}
            </td>
            <td className="column1 style59 null style61"
                //@ts-ignore
                colSpan="3">
                {row.field1}
            </td>
            <td className="column4 style14 null">
                {row.field2}
            </td>
            <td className="column5 style15 null">
                {row.field3}
            </td>
            <td className="column6 style15 null">
                <InputNumber value={row.field4}
                             style={{textAlign: "center", width: "60px"}}
                             onChange={e => {
                                 setRow({
                                     ...row,
                                     field4: twoDecimals(e!),
                                     field6: twoDecimals(e! * row.field5),
                                     field12: twoDecimals(e! * row.field5 + row.field10),
                                     field14: twoDecimals(e! * row.field13),
                                     field15: twoDecimals(e! * row.field13 - e! * row.field5 + row.field10)
                                 })
                                 props.setHasChanged(true)
                             }}
                />
            </td>
            <td className="column7 style16 null">
                {row.field5}
            </td>
            <td className="column8 style16 null">
                {row.field6}
            </td>
            <td className="column9 style16 null">
                <InputNumber value={row.field7}
                             style={{textAlign: "center", width: "60px"}}
                             onChange={e => {
                                 setRow({...row, field7: twoDecimals(e!)})
                                 props.setHasChanged(true)
                             }}
                />
            </td>
            <td className="column10 style16 null">
                <InputNumber value={row.field8}
                             style={{textAlign: "center", width: "60px"}}
                             onChange={e => {
                                 setRow({
                                     ...row,
                                     field8: twoDecimals(e!),
                                     field9: twoDecimals(row.field5 * e! / 100),
                                     field11: twoDecimals(row.field5 + row.field5 * e! / 100)
                                 })
                                 props.setHasChanged(true)
                             }}
                />
            </td>
            <td className="column11 style14 null">
                {row.field9}
            </td>
            <td className="column12 style14 null">
                <InputNumber value={row.field10}
                             style={{textAlign: "center", width: "60px"}}
                             onChange={e => {
                                 setRow({
                                     ...row,
                                     field10: twoDecimals(e!),
                                     field12: twoDecimals(row.field4 * row.field5 + e!),
                                 })
                                 props.setHasChanged(true)
                             }}
                />
            </td>
            <td className="column13 style16 null">
                {row.field11}
            </td>
            <td className="column14 style16 null">
                {row.field12}
            </td>
            <td className="column15 style16 null">
                <InputNumber value={row.field13}
                             style={{textAlign: "center"}}
                             onChange={e => {
                                 setRow({
                                     ...row,
                                     field13: twoDecimals(e!),
                                     field14: twoDecimals(row.field4 * e!),
                                     field15: twoDecimals(row.field4 * e! - row.field12)
                                 })
                                 props.setHasChanged(true)
                             }}
                />
            </td>
            <td className="column16 style16 null">
                {row.field14}
            </td>
            <td className="column17 style16 null">
                {row.field15}
            </td>
        </tr>
    )
}