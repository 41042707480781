export const blue: string = "#1CACF4";
export const dark_blue: string = "#042444";
export const light_blue: string = "#8CCCF4";
export const dark_violet: string = "#7404AC";
export const light_violet: string = "#B650C6";


export const SM: number = 576
export const MD: number = 768
export const LG: number = 992
export const XL: number = 1200
export const XXL: number = 1400