export interface ReceiptTemplate {
    id?: string;
    templateName?: string;
    businessName?: string;
    managementName?: string;
    commercialExcess?: number;
    receiptCommitteeNames?: string;
    managerName?: string;
}

export function equalsTemplates(a: ReceiptTemplate | undefined, b: ReceiptTemplate | undefined): boolean {
    if (a === undefined && b === undefined) {
        return true
    }
    if (a === undefined) {
        return false
    }
    if (b === undefined) {
        return false
    }
    return (a.id ?? "") === (b.id ?? "") &&
        (a.templateName ?? "") === (b.templateName ?? "") &&
        (a.businessName ?? "") === (b.businessName ?? "") &&
        (a.managementName ?? "") === (b.managementName ?? "") &&
        (a.commercialExcess ?? "") === (b.commercialExcess ?? "") &&
        (a.receiptCommitteeNames ?? "") === (b.receiptCommitteeNames ?? "") &&
        (a.managerName ?? "") === (b.managerName ?? "")
}