import {Input, InputNumber, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {ReceiptTemplate} from "../../../Models/backend/ReceiptTemplate";
import {TemplateModalProps} from "./templateModal.types";

export const TemplateModal = (props: TemplateModalProps): JSX.Element => {
    const titleMaxLength: number = 28;

    const [title, setTitle] = useState<string>()
    const [businessName, setBusinessName] = useState<string>()
    const [managerName, setManagerName] = useState<string>()
    const [excess, setExcess] = useState<number>()
    const [management, setManagement] = useState<string>()
    const [committee, setCommittee] = useState<string>()

    useEffect(() => {
        let t = props.selectedTemplate;
        setTitle(t?.templateName ?? "Nume sablon")
        setManagement(t?.managementName)
        setBusinessName(t?.businessName)
        setExcess(t?.commercialExcess)
        setManagerName(t?.managerName)
        setCommittee(t?.receiptCommitteeNames)
    }, [props.selectedTemplate])

    const handleTemplateOk = () => {
        props.setIsModalOpen(false);

        const newTemplate: ReceiptTemplate = {
            id: props.selectedTemplate?.id,
            templateName: title,
            managementName: management,
            businessName: businessName,
            managerName: managerName,
            receiptCommitteeNames: committee,
            commercialExcess: excess
        }
        props.update(newTemplate)
    };

    const handleTemplateCancel = (e: any) => {
        if (e.currentTarget instanceof HTMLButtonElement) {
            props.setIsModalOpen(false);
            return
        }
        alert("Modificari nesalvate! Foloseste butonul 'Anuleaza' pentru a inchide fereastra!")
    };

    return (
        <Modal title={
            <Input className={"w-75"}
                   maxLength={titleMaxLength}
                   value={title}
                   onChange={(e) => setTitle(e.currentTarget.value)}
            />

        }
               open={props.isModalOpen}
               onOk={handleTemplateOk}
               okText={"Actualizeaza"}
               onCancel={handleTemplateCancel}
               cancelText={"Anuleaza"}
               style={{minWidth: "40%", maxWidth: "80%"}}
        >
            <div style={{textAlign: "center", fontSize: "20px"}} className={"my-3"}>
                Nota de receptie - sablon
            </div>
            <div className={"row mb-2"}>
                <div className={"col-md-6 d-flex align-items-center"}>
                    <b>Unitatea: </b>
                    <Input style={{width: "60%", marginLeft: "5px"}}
                           value={businessName}
                           onChange={(e) => setBusinessName(e.currentTarget.value)}
                    />
                </div>
                <div className={"col-md-6 align-items-center my-auto"}>
                    <b>Nr. nir.: </b> AUTO
                </div>
            </div>
            <div className={"row mb-2"}>
                <div className={"col-md-6 d-flex align-items-center"}>
                    <b>Gestiunea: </b>
                    <Input style={{width: "60%", marginLeft: "5px"}}
                           value={management}
                           onChange={(e) => setManagement(e.currentTarget.value)}
                    />
                </div>
                <div className={"col-md-6 align-items-center my-auto"}>
                    <b>Data: </b> AUTO
                </div>
            </div>
            <div className={"mb-2 d-flex align-items-center"}>
                <b>Adaos comercial (%): </b>
                <InputNumber style={{marginLeft: "5px"}}
                             min={0}
                             value={excess}
                             onChange={(e) => setExcess(e ?? 0)}
                />
            </div>
            <div className={"mb-2 d-flex align-items-center"}>
                <b>Nume membru/membrii comitet de receptie</b>
                <Input style={{marginLeft: "5px"}}
                       value={committee}
                       onChange={(e) => setCommittee(e.currentTarget.value)}
                />
            </div>
            <div className={"mb-2 d-flex align-items-center"}>
                <b style={{whiteSpace: "nowrap"}}>Nume gestionar</b>
                <Input style={{marginLeft: "5px"}}
                       value={managerName}
                       onChange={(e) => setManagerName(e.currentTarget.value)}
                />
            </div>
        </Modal>
    )
}