import {CONFIDENTIALITY_POLICY, TERMS_AND_CONDITIONS} from "../../Enums/pagesUrls";
import {blue} from "../../Assets/Styles/variables";
//@ts-ignore
import logoWhitePng from "../../Assets/Images/logo-white.png";
import {mergeStyles} from "@fluentui/react";


export const TermsAndConditions = (): JSX.Element => {

    const styleImg = mergeStyles({
        '@media screen and (max-width: 768px)': {
            margin: "auto"
        },
    });

    return (
        <div>
            <div style={{backgroundColor: blue}} className={"py-3"}>
                <div className={"container"}>
                    <a href={"/"}>
                        <img src={logoWhitePng} alt="" style={{width: "120px"}} className={styleImg}/>
                    </a>
                </div>
            </div>
            <div className={"container my-5"}>
                <h1>
                    Termenii și condițiile
                </h1>

                <div>
                    {/*<p>*/}
                    {/*    Serviciile sunt oferite de CLOUD SYSTEMS SRL - și/sau partenerii săi - (“Cloud Systems”), cu sediul*/}
                    {/*    social în Gheorgheni, str. Teiului, nr. 2, înregistrată la Oficiul Registrului Comerțului sub nr.*/}
                    {/*    J19/406/2010 CUI 27798312, atribut fiscal RO, având contul nr. RO63BTRL02101202U36872XX, deschis la*/}
                    {/*    Banca Transilvania, operator al platformei (“iBee”) ibee.ro, prin ansamblul serviciilor accesibile*/}
                    {/*    pe site-ul ibee.ro de internet, sau pe subdomeniile acestuia pe baza/sub forma unui abonament lunar.*/}
                    {/*</p>*/}
                    <p>
                        Prin utilizarea serviciilor noastre, sunteți de acord cu prezenții <a
                        href={TERMS_AND_CONDITIONS} target={"_blank"} rel={"noreferrer"}>Termeni
                        și
                        condiții</a> însoțit și interpretat împreună cu <a
                        href={CONFIDENTIALITY_POLICY} target={"_blank"} rel={"noreferrer"}>Politica
                        de
                        confidențialitate</a>. Vă rugăm să le citiți cu atenție.
                    </p>
                </div>
                <div>
                    <div style={{fontWeight: "bold"}}>
                        <span>1. </span>
                        <span>Utilizarea serviciilor noastre</span>
                    </div>
                    <div>
                        <p>
                            Serviciile pot fi accesate în baza unui abonament lunar achizționat de dvs.
                        </p>
                        <p>
                            Declarați în mod expres ca înțelegeți și că sunteți de acord să folosiți serviciile noastre
                            pe
                            propria
                            răspundere, că serviciile sunt furnizate “asa cum sunt/ca atare”.
                        </p>
                        <p>
                            Vă obligați să folosiți Serviciile în deplină legalitate și cunoscând dispozițiile legale în
                            vigoare cu privire la activitatea pe care o desfășurați, incluzând dar nelimitat la crearea,
                            personalizarea, administrarea, gestionarea, acuratețea și legalitatea documentelor emise cu
                            ajutorul serviciilor noastre.
                        </p>
                        <p>
                            Vă obligați să respectați în totalitate prevederile legate de drepturile de autor și
                            protecția
                            datelor cu caracter personal, inclusiv dar nelimitat la cele reglementate de Regulamentul
                            general al UE privind protecția datelor în ceea ce privește datele salvate în, trimise către
                            și
                            furnizate prin serviciile noastre.
                        </p>
                        <p>
                            Nu utilizaţi serviciile în mod necorespunzător. De exemplu, nu încercaţi să influenţaţi
                            serviciile sau să le accesaţi în alt mod decât prin interfaţa şi prin instrucţiunile oferite
                            de
                            platformă. Puteţi utiliza serviciile numai conform legii. Putem întrerupe sau înceta
                            furnizarea serviciilor către dvs. dacă nu respectaţi Termenii şi condiţiile sau politicile
                            noastre ori în cazul în care investigăm un comportament suspectat ca fiind necorespunzător.
                        </p>
                        <p>
                            Utilizarea serviciilor nu vă oferă niciun drept de proprietate intelectuală asupra acestora
                            sau
                            asupra conţinutului pe care îl accesaţi.
                        </p>
                        <p>
                            În ceea ce priveşte utilizarea serviciilor de către dvs., putem să vă trimitem anunţuri
                            privind
                            serviciile, mesaje administrative şi alte informaţii. Puteţi renunţa la unele dintre aceste
                            comunicări.
                        </p>
                    </div>
                </div>
                <div>
                    <div style={{fontWeight: "bold"}}>
                        <span>2. </span>
                        <span>Contul dumneavoastra</span>
                    </div>
                    <div>
                        <p>
                            Pentru a utiliza Serviciile, este necesar un cont MenuOrders. Puteţi să vă creaţi propriul
                            cont,
                            devenind astfel proprietar de sistem sau contul vă poate fi atribuit de către
                            administratorul
                            platformei.
                        </p>
                        <p>
                            Din momentul creării unui cont pe site-ul menuorders.ro veți beneficia de o perioada de
                            gratuitate de
                            1 săptămână în care veți putea folosi serviciul fără restricții. După această perioadă
                            puteți
                            opta
                            pentru unul din pachetele plătite conform grilei de prețuri.
                        </p>
                        <p>
                            Pentru a vă proteja contul, păstrați parola confidențială.
                        </p>
                        <p>
                            Purtați întreaga responsabilitate pentru activitatea care are loc în sau prin intermediul
                            contului MenuOrders, ați luat la cunoștință și sunteți de acord cu faptul că sunteți
                            singurul
                            responsabil pentru conținutul informațiilor salvate în sau prin intermediul contului dvs.
                        </p>
                        <p>
                            Încercați să nu reutilizați parola contului MenuOrders pentru aplicații terță parte. Dacă
                            aflați
                            despre orice utilizare neautorizată a parolei sau a contului, contactați administratorul de
                            sistem din firma dvs. respectiv trimiteți notificare pe suport-clienti@menuorders.ro.
                        </p>
                    </div>
                </div>
                <div>
                    <div style={{fontWeight: "bold"}}>
                        <span>3. </span>
                        <span>Protejarea confidentialitatii</span>
                    </div>
                    <div>
                        <p>
                            <a href={CONFIDENTIALITY_POLICY} target={"_blank"}
                               rel={"noreferrer"}>Politicile
                                de
                                confidenţialitate</a> explică modul în care tratăm datele dvs. cu
                            caracter personal şi în care vă protejăm confidenţialitatea atunci când utilizaţi serviciile
                            noastre.
                        </p>
                        <p>
                            Prin utilizarea serviciilor furnizate de MenuOrders, sunteţi de acord ca datele respective
                            să
                            fie utilizate conform politicilor noastre de confidenţialitate.
                        </p>
                    </div>
                </div>
                <div>
                    <div style={{fontWeight: "bold"}}>
                        <span>4. </span>
                        <span>Continutul dumneavostra in cadul serviciilor noastre</span>
                    </div>
                    <div>
                        <p>
                            Serviciile noastre vă permit să creați, încărcați, să trimiteți, să stocați sau să primiți
                            conținut. Vă păstrați toate drepturile de proprietate intelectuală deținute asupra
                            conținutului
                            respectiv. Pe scurt, tot ceea ce vă aparține rămâne în proprietatea dvs., respectiv sunteti
                            unicul responsabil pentru întregul conținut existent în contul dvs. sau pentru orice
                            încălcare a legii sau a drepturilor unei terțe persoane.
                        </p>
                        <p>
                            Când creați, încărcați, trimiteți, stocați sau primiți conținut (de exemplu emiteți sau
                            inregistrați diferite documente, inregistrați date despre clineți, etc.) în cadrul
                            serviciilor
                            sau prin intermediul acestora, acordați MenuOrders (și părților cu care lucrăm) o licenţă
                            globală (în sensul de autorizare/permisiune globală) pentru găzduirea, stocarea
                            respectivului
                            conținut. Drepturile pe care ni le acordați în cadrul acestei licențe au scopul limitat de
                            operare și îmbunătățire a Serviciilor, precum și de dezvoltare a unor servicii noi.
                            Asigurați-vă
                            că dețineți drepturile necesare pentru a ne acorda această licență pentru întregul conținut
                            pe
                            care îl trimiteți către serviciile noastre. Această licenţă expiră în momentul in care
                            renunțați
                            la serviciile noastre și primiți notificarea finală privind stergerea conținutului dvs.
                        </p>
                    </div>
                </div>
                <div>
                    <div style={{fontWeight: "bold"}}>
                        <span>5. </span>
                        <span>Valoarea abonamentui și efectuarea plății</span>
                    </div>
                    <div>
                        <p>
                            Pentru serviciile oferite, se va plăti o sumă lunară, de tipul unui abonament, prevăzută în
                            momentul finalizării comenzii dvs. de servicii, achitat prin transfer bancar iar plata
                            serviciilor reprezintă acceptarea prețului oferit pentru servicii.
                        </p>
                        <p>
                            Oferta cu privire la servicii și prețul acestora este afișat pe site-ul <a
                            href={"/"} target={"_blank"} rel={"noreferrer"}>menuorders.ro</a> și
                            este
                            valabilă atâta vreme cât este afișată, respectiv poate fi aflată pe
                            suport-clienti@menuorders.ro.
                        </p>
                    </div>
                </div>
                <div>
                    <div style={{fontWeight: "bold"}}>
                        <span>6. </span>
                        <span>Modificarea şi încetarea furnizării Serviciilor</span>
                    </div>
                    <div>
                        <p>
                            Modificăm şi îmbunătăţim în mod constant serviciile. Putem să adăugăm sau să eliminăm
                            funcţionalităţi ori funcţii, putem stabili în orice moment noi limite pentru servicii; de
                            asemenea, putem să suspendăm sau să întrerupem complet furnizarea serviciului către dvs.: de
                            comun acord; ca urmare a unei decizii unilaterale transmisă în scris celeilalte părți;
                            recepția
                            notificării de încetare trebuie să aibă loc cu minimum 30 zile calendaristice înainte de
                            data
                            stabilită pentru încetarea colaborării; în caz de dizolvare, lichidare, faliment, retragerea
                            autorizației de funcționare.
                        </p>
                        <p>
                            Puteţi să renunţaţi oricând la utilizarea serviciilor noastre, însă ne va părea rău să nu
                            mai
                            colaborăm cu dvs. Dacă doriți acest lucru trebuie să ne transmiteți in scris această
                            intenție;
                            recepția notificării de încetare trebuie să aibă loc cu minimum 30 zile calendaristice
                            înainte
                            de data stabilită pentru încetarea colaborării;
                        </p>
                        <p>
                            Considerăm că dvs. sunteţi proprietarul propriilor date, iar păstrarea accesului la datele
                            respective este un lucru important. Dacă întrerupem furnizarea serviciului, sau dacă dvs.
                            renunțați la servicii în situaţiile când acest lucru este posibil în mod rezonabil, vă vom
                            notifica în prealabil şi în mod rezonabil şi vă vom oferi posibilitatea de a prelua
                            informaţiile
                            din cadrul serviciului.
                        </p>
                    </div>
                </div>
                {/*<div>*/}
                {/*    <div>*/}
                {/*        <span>7</span>*/}
                {/*        <span>Garanţii şi declinarea responsabilităţii</span>*/}
                {/*    </div>*/}
                {/*    <div>*/}
                {/*        <p>*/}
                {/*            Furnizăm Serviciile la un nivel de aptitudine şi atenţie rezonabil din punct de vedere comercial*/}
                {/*            şi sperăm că vă va face plăcere să le utilizaţi. Dar, există anumite lucruri pe care nu le putem*/}
                {/*            promite în legătură cu Serviciile.*/}
                {/*        </p>*/}
                {/*        <p>*/}
                {/*            Cloud Systems nu oferă nici o garanție că serviciile vor corespunde tuturor cerințelor Dvs.;*/}
                {/*            Serviciile vor fi neîntrerupte, la timp, sigure sau fără erori; orice eroare de program va fi*/}
                {/*            corectat;*/}
                {/*        </p>*/}
                {/*        <p>*/}
                {/*            CU EXCEPŢIA PREVEDERILOR EXPLICITE DIN PREZENŢII TERMENI ŞI CONDIŢII SAU DIN TERMENII ŞI*/}
                {/*            CONDIŢIILE SUPLIMENTARE, CLOUD SYSTEMS ŞI FURNIZORII SAU DISTRIBUITORII SĂI NU FAC NICIO*/}
                {/*            PROMISIUNE SPECIALĂ ÎN LEGĂTURĂ CU SERVICIILE. DE EXEMPLU, NU NE LUĂM NICIUN ANGAJAMENT CU*/}
                {/*            PRIVIRE LA CONŢINUTUL DIN CADRUL SERVICIILOR, FUNCŢIA SPECIFICĂ DISPONIBILĂ PRIN INTERMEDIUL*/}
                {/*            SERVICIILOR, FIABILITATEA, DISPONIBILITATEA ŞI CAPACITATEA ACESTORA DE A VĂ SATISFACE CERINŢELE.*/}
                {/*            FURNIZĂM SERVICIILE „CA ATARE”.*/}
                {/*        </p>*/}
                {/*        <p>*/}
                {/*            ANUMITE JURISDICŢII PREVĂD UNELE GARANŢII, CUM AR FI GARANŢIA IMPLICITĂ DE VANDABILITATE,*/}
                {/*            CONFORMITATE CU UN ANUMIT SCOP ŞI NEÎNCĂLCARE A PREVEDERILOR LEGALE. ÎN LIMITA PREVEDERILOR*/}
                {/*            LEGALE, EXCLUDEM TOATE GARANŢIILE.*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div>
                    <div style={{fontWeight: "bold"}}>
                        <span>7. </span>
                        <span>Răspunderea pentru serviciile noastre</span>
                    </div>
                    <div>
                        <p>
                            ÎN MĂSURA PERMISĂ DE LEGE, MenuOrders, FURNIZORII ŞI DISTRIBUITORII MenuOrders NU VOR FI
                            RĂSPUNZĂTORI PENTRU PIERDERILE DE PROFITURI, VENITURI SAU DATE, PENTRU PIERDERILE FINANCIARE
                            SAU
                            PENTRU DAUNELE INDIRECTE, SPECIALE, SUBSECVENTE, EXEMPLARE SAU PUNITIVE.
                        </p>
                        <p>
                            ÎN LIMITELE PERMISE DE LEGE, RĂSPUNDEREA TOTALĂ A COMPANIEI MenuOrders, A FURNIZORILOR ŞI A
                            DISTRIBUITORILOR SĂI PENTRU ORICE RECLAMAŢIE ÎN BAZA ACESTOR TERMENI ŞI CONDIŢII, INCLUSIV
                            PENTRU TOATE GARANŢIILE IMPLICITE, SE LIMITEAZĂ LA SUMA PE CARE NE-AŢI PLĂTIT-O PENTRU
                            UTILIZAREA SERVICIILOR (SAU, LA ALEGEREA NOASTRĂ, SE LIMITEAZĂ LA A VĂ FURNIZA DIN NOU
                            SERVICIILE).
                        </p>
                        <p>
                            MenuOrders, FURNIZORII ŞI DISTRIBUITORII SĂI NU VOR FI ÎN NICIUN CAZ RESPONSABILI PENTRU
                            NICIO PIERDERE SAU PAGUBĂ CARE NU POATE FI PREVĂZUTĂ ÎN MOD REZONABIL.
                        </p>
                    </div>
                </div>
                <div>
                    <div style={{fontWeight: "bold"}}>
                        <span>8. </span>
                        <span>Despre prezenţii Termeni şi condiţii</span>
                    </div>
                    <div>
                        <p>
                            Putem să modificăm prezenţii <a href={TERMS_AND_CONDITIONS}
                                                            target={"_blank"} rel={"noreferrer"}>Termeni şi condiţii</a>,
                            de
                            exemplu, pentru a corespunde schimbărilor
                            survenite la nivelul legislaţiei sau al serviciilor noastre. Consultaţi periodic Termenii şi
                            condiţiile. Pe această pagină vom posta notificările privind modificările prezenţilor
                            Termeni şi
                            condiţii. Modificările nu se vor aplica retroactiv şi vor intra în vigoare în termen de
                            minimum
                            paisprezece zile după postare. Totuşi, modificările aferente funcţiilor noi ale serviciului
                            şi
                            cele din motive legale vor intra în vigoare imediat. Dacă nu sunteţi de acord cu
                            modificările
                            aduse Termenilor şi condiţiilor aferente Serviciului, vă sugerăm să întrerupeţi utilizarea
                            acestuia.
                        </p>
                        <p>
                            Prezenţii Termeni şi condiţii reglementează relaţia dintre MenuOrders şi dvs. Aceştia nu
                            creează niciun fel de drepturi pentru terţi beneficiari.
                        </p>
                        <p>
                            Dacă nu respectaţi prezenţii Termeni şi condiţii şi nu luăm măsuri imediate, nu înseamnă că
                            renunţăm la drepturile pe care le avem (de exemplu, dreptul de a lua măsuri pe viitor).
                        </p>
                        <p>
                            Dacă anumiţi termeni şi anumite condiţii se dovedesc a fi inaplicabili, aceasta nu va afecta
                            restul Termenilor şi condiţiilor.
                        </p>
                        <p>
                            În cazul litigiilor survenite din sau asociate cu prezenţii Termeni şi condiţii sau cu
                            serviciile, se va aplica legislaţia Română în vigoare iar competența de soluționare va
                            reveni
                            instanțelor românești de la sediul MenuOrders.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}