import {Button, message, Modal, Popconfirm, Select} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {ServiceContext, ServiceContextInstance} from "../../../Core/serviceContext";
import {downloadFile} from "../../../Library/utils/documentDownload";
import {equalsTemplates, ReceiptTemplate} from "../../../Models/backend/ReceiptTemplate";
import {ReceiptProduct} from "../../../Models/model/ReceiptProduct";
import dayjs from "dayjs";
import {ReceiptGlobalModel} from "./ReceiptModels/Global/receiptGlobalModel";
import {ReceiptQuantitiveModel} from "./ReceiptModels/Quantitive/receiptQuantitiveModel";
import {Receipt} from "../../../Models/backend/Receipt";
import {OrderNameWithProviderCard} from "../../../Components/OrderNameWithProviderCard/orderNameWithProviderCard";


const defaultReceiptTemplate: ReceiptTemplate = {
    templateName: "<custom>",
    receiptCommitteeNames: "",
    managerName: ""
}

export const ReceiptCard = (props: {
    receipt: Receipt;
    receiptsTemplates: ReceiptTemplate[];
    setReceiptsChanged: () => void;
}): JSX.Element => {
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedTemplate, setSelectedTemplate] = useState<ReceiptTemplate>()
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const [customReceiptTemplate, setCustomReceiptTemplate] = useState<ReceiptTemplate>(defaultReceiptTemplate)
    const [observations, setObservations] = useState<string>()
    const [date, setDate] = useState<dayjs.Dayjs>(dayjs())
    const [receiptProducts, setReceiptProducts] = useState<ReceiptProduct[]>([]);
    const [receiptModel, setReceiptModel] = useState<number>(1)

    const [hasChanged, setHasChanged] = useState<boolean>(false);
    const [loadingUpdating, setLoadingUpdating] = useState<boolean>(false);

    useEffect(() => {
        let receipt = props.receipt;
        setSelectedTemplate(
            {
                templateName: "<custom>",
                receiptCommitteeNames: receipt.receiptCommitteeNames,
                managerName: receipt.managerName,
                businessName: receipt.businessName,
                managementName: receipt.managementName,
                commercialExcess: receipt.commercialExcess,
            }
        )
    }, [])


    useEffect(() => {
        if (!equalsTemplates(selectedTemplate, customReceiptTemplate) && !equalsTemplates(customReceiptTemplate, defaultReceiptTemplate)) {
            setSelectedTemplate({...customReceiptTemplate})
        }
    }, [customReceiptTemplate])

    useEffect(() => {
        let receiptProducts1 = props.receipt.receiptProducts;
        receiptProducts1.sort((a, b) => {
            if (a.field1 < b.field1) {
                return -1;
            }
            if (a.field1 > b.field1) {
                return 1;
            }
            return 0;
        });
        setReceiptProducts(receiptProducts1)
    }, [props.receipt.receiptProducts])

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleDownloadReceipt = async () => {
        setConfirmLoading(true)
        try {
            await downloadReceipt();
        } catch (e) {

        } finally {
            setConfirmLoading(false)
        }
    };

    async function handleUpdateReceipt() {
        setLoadingUpdating(true)
        const receipt: Receipt = {
            id: props.receipt.id, // no change
            providerName: props.receipt.providerName, // no change
            nir: props.receipt.nir, // no change
            name: props.receipt.name, // no change
            businessName: selectedTemplate?.businessName || undefined,
            date: date.toISOString(),
            managementName: selectedTemplate?.managementName || undefined,
            managerName: selectedTemplate?.managerName || undefined,
            observations: observations,
            receiptCommitteeNames: selectedTemplate?.receiptCommitteeNames || undefined,
            receiptModel: receiptModel === 1 ? "SIMPLE" : "COMPLEX",
            receiptProducts: receiptProducts
        }
        let response = await services.ReceiptService.update(props.receipt.id!, receipt);
        setLoadingUpdating(false)
        if (response.Error !== undefined || response.Data === undefined) {
            message.error("Nu s-a putut modifica recepția")
            return
        }
        message.success("Recepția a fost modificată")
        setHasChanged(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    async function downloadReceipt() {
        let response = await services.ReceiptService.downloadReceiptFile(props.receipt.id!);
        if (response.Error !== undefined || response.Data === undefined) {
            message.error("Eroare la crearea receptiei")
            return
        }
        message.success("Recepția a fost generată")
        downloadFile(response.Data, props.receipt.name + ".xlsx");
        props.setReceiptsChanged()
    }

    async function confirmReceptionDelete() {
        let response = await services.ReceiptService.delete(props.receipt.id!);
        if (response.Error !== undefined) {
            message.error("Nu s-a putut șterge recepția")
            return
        }
        message.success("Recepția cu nir=" + props.receipt.nir + " a fost ștearsă")
        props.setReceiptsChanged()
        handleCancel()
    }


    return (
        <>
            <OrderNameWithProviderCard
                name={props.receipt.nir + " - " + props.receipt.name}
                onClick={showModal}
                providerName={props.receipt.providerName}
            />
            <Modal title=""
                   open={isModalOpen}
                   onOk={handleDownloadReceipt}
                   width={"1050px"}
                   onCancel={handleCancel}
                   footer={[
                       <Button key="back" onClick={handleCancel}>
                           închide
                       </Button>,
                       <Button key="update" type="primary"
                               disabled={!hasChanged}
                               loading={loadingUpdating}
                               onClick={handleUpdateReceipt}>
                           Actualizează recepția
                       </Button>,
                       <Button key="submit" type="primary"
                               disabled={hasChanged}
                               loading={confirmLoading}
                               onClick={handleDownloadReceipt}>
                           Descarcă EXCEL
                       </Button>,
                       <Popconfirm
                           key={"popconfirm"}
                           title="Sterge receptia"
                           description="Confirmă ștergerea recepției"
                           onConfirm={confirmReceptionDelete}
                           okText="Confirmă"
                           cancelText="Renunță"
                       >
                           <Button
                               key={"action"}
                               type="primary"
                               danger
                           >
                               Sterge receptia
                           </Button>
                       </Popconfirm>
                   ]}
            >
                <div className={"d-flex align-items-center"}>
                    <div className={"me-2"}>
                        Aplica un sablon explicit:
                    </div>
                    <Select style={{width: "300px"}}
                            defaultValue={{label: "<custom>", value: null, template: customReceiptTemplate}}
                            options={[{
                                label: "<custom>",
                                value: null,
                                template: customReceiptTemplate
                            },
                                ...props.receiptsTemplates?.map(rt => (
                                    {
                                        label: rt.templateName,
                                        value: rt.id,
                                        template: rt
                                    }
                                ))]}
                            onChange={(value, opt) => {
                                //@ts-ignore
                                setSelectedTemplate({...opt.template})
                                setHasChanged(true)
                            }}
                    />
                </div>
                <div className={"d-flex align-items-center pt-3"}>
                    <div className={"me-2"}>
                        Alege tipul de receptie:
                    </div>
                    <Select style={{width: "300px"}}
                            options={[{
                                label: "Gestiune global valorica",
                                value: 1
                            },
                                {
                                    label: "Gestiune cantitativ valorica",
                                    value: 2
                                }
                            ]}
                            defaultValue={1}
                            onChange={(value, opt) => {
                                setReceiptModel(value)
                            }}
                    />
                </div>
                {
                    receiptModel === 1 ? (
                            <ReceiptQuantitiveModel
                                setHasChanged={setHasChanged}
                                nir={props.receipt.nir}
                                date={date}
                                setDate={setDate}
                                observations={observations}
                                setObservations={setObservations}
                                receiptProducts={receiptProducts}
                                setReceiptProducts={setReceiptProducts}
                                receiptTemplate={selectedTemplate}
                                setCustomReceiptTemplate={setCustomReceiptTemplate}
                                products={receiptProducts}
                                provider={props.receipt.providerName}
                            />
                        ) :
                        (
                            <ReceiptGlobalModel
                                setHasChanged={setHasChanged}
                                nir={props.receipt.nir}
                                date={date}
                                setDate={setDate}
                                observations={observations}
                                setObservations={setObservations}
                                receiptProducts={receiptProducts}
                                setReceiptProducts={setReceiptProducts}
                                receiptTemplate={selectedTemplate}
                                setCustomReceiptTemplate={setCustomReceiptTemplate}
                                products={receiptProducts}
                                provider={props.receipt.providerName}
                            />
                        )
                }


            </Modal>
        </>
    )
}