import {Button, Divider, Input, notification} from "antd";
import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import {blue} from "@mui/material/colors";
import {ServiceContext, ServiceContextInstance} from "../../Core/serviceContext";

export const ResetPassword = (): React.JSX.Element => {

    const services: ServiceContext = useContext(ServiceContextInstance);

    const [email, setEmail] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const [api, contextHolder] = notification.useNotification();

    async function handleClick() {
        if (email === undefined) {
            return
        }
        setLoading(true)
        let response = await services.ContactService.resetPassword(email);
        setLoading(false)
        if (response.Error !== undefined) {
            let message1 = response.Message;
            if (message1 === undefined) {
                message1 = "Nu s-a putut trimite email-ul!"
            }
            api.open({
                message: 'Nu s-a putut reseta parola',
                description: message1,
            });
            return
        }
        api.open({
            message: 'Parola a fost resetată',
            description: 'Urmează să primești un email cu noua parolă. Dacă nu îl primești în 5 minute, te rugăm să ne contactezi.',
        });
    }


    return (
        <div style={{
            backgroundColor: `${blue[800]}`,
            width: "100%",
            minHeight: "100vh",
        }}
             className={"pt-5"}
        >
            {contextHolder}
            <div className={"mx-auto border bg-white rounded p-5"}
                 style={{width: "550px"}}
            >
                <div>
                    <a href={"/"} style={{fontSize: "1rem", textDecoration: "none"}}>
                        <i className="fa-solid fa-arrow-left-long me-1"></i>
                        MenuOrders
                    </a>
                </div>
                <h3 className={"text-center"}>
                    Ai uitat parola?
                </h3>
                <p className={"text-center"}
                   style={{fontWeight: "400"}}
                >
                    Înțelegem, se mai intamplă. Introdu emailul aici, iar noi iți vom trimite
                    noua parolă, pe care te rugăm să o schimbi după ce te conectezi.
                </p>
                <div className="mb-4">
                    <label className={"mb-1"}>
                        <b>Adresa de email</b>
                    </label>
                    <Input
                        size={"large"}
                        className={"shadow"}
                        type="email"
                        placeholder="Introdu adresa de email"
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value)
                        }}
                    />
                </div>
                <div className="mb-6 text-center">
                    <Button
                        type={"primary"}
                        size={"large"}
                        onClick={handleClick}
                        className={"w-100 shadow"}
                        loading={loading}
                    >
                        Resetează parola
                    </Button>
                </div>
                <Divider className={"my-4"}/>
                <div className="text-center">
                    <Link to="/login" style={{textDecoration: "none"}}>
                        Ai un cont? Loghează-te!
                    </Link>
                </div>
            </div>
        </div>
    )
}