import {Route, Routes, useNavigate} from "react-router-dom";
import * as pageUrls from "./Enums/pagesUrls";
import {
    CONFIDENTIALITY_POLICY,
    CONTACT_LINK,
    DASHBOARD_LINK,
    EXPIRED_ACCOUNT_LINK,
    HOME_LINK,
    ITEMS_LINK,
    LOGIN_LINK,
    MENU_PRODUCTS_LINK,
    MENUS_LINK,
    ORDERS_LINK,
    PASSWORD_RESET_LINK,
    PRODUCTS_LINK,
    RECEIPTS_LINK,
    TERMS_AND_CONDITIONS
} from "./Enums/pagesUrls";
import {Login} from "./Pages/Login/login";
import {HomePage} from "./Pages/Home/home";
import {Dashboard} from "./Pages/Dashboard/dashboard";
import {Menu} from "./Pages/Menu/menu";
import {Providers} from "./Pages/ProvidersPage/providers";
import {Orders} from "./Pages/Orders/orders";
import {Receipts} from "./Pages/Receipts/receipts";
import {ResetPassword} from "./Pages/ResetPassword/resetPassword";
import {MyLayout} from "./Pages/Layout/layout";
import {PrimeProducts} from "./Pages/ProvidersPage/PrimeProductsPage/primeProducts";
import {Contact} from "./Pages/Contact/contact";
import {MenuPage} from "./Pages/Menu/MenuPage/menuPage";
import {MenuProductsPage} from "./Pages/Menu/MenuProductsPage/menuProductsPage";
import React, {useContext, useEffect, useState} from "react";
import {LoginResponse} from "./Models/model/LoginResponse";
import {IResponse} from "./Models/IResponse";
import {ServiceContext, ServiceContextInstance} from "./Core/serviceContext";
import {ExpiredAccount} from "./Pages/ExpiredAccount/expiredAccount";
import {rememberLS} from "./Enums/localStorageItems";
import {ConfidentialityPolicy} from "./Pages/StaticPages/confidentialityPolicy";
import {TermsAndConditions} from "./Pages/StaticPages/termsAndConditions";

const App = (): React.JSX.Element => {

    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const navigate = useNavigate()

    const [title, setTitle] = useState<string>("")
    const [btnAction, setBtnAction] = useState<() => void>(() => {
    })
    const [btnText, setBtnText] = useState<string>("")


    useEffect(() => {
        window.onbeforeunload = function (e) {
            window.onunload = function () {
                localStorage.isMySessionActive = "false";
            }
            return undefined;
        };

        window.onload = function () {
            localStorage.isMySessionActive = "true";
        };
    }, [])

    useEffect(() => {
        const urlValues = Object.values(pageUrls);
        if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
            // if there is an reload, we don't want to navigate anywhere else
            return;
        }
        let pathname = window.location.pathname;
        if (pathname === "" || pathname === "/" ||
            pathname === LOGIN_LINK || pathname === EXPIRED_ACCOUNT_LINK ||
            pathname === CONFIDENTIALITY_POLICY || pathname === TERMS_AND_CONDITIONS) {
            return;
        }
        if (!urlValues.includes(pathname)) {
            return;
        }
        const remember = localStorage.getItem(rememberLS)
        const sessionActive = localStorage.getItem("isMySessionActive")
        if (sessionActive === "true") {
            //allow - its open in another tab
            return;
        }
        if (remember === "true") {
            isSessionExpired()
                .then(isExpired => {
                    if (isExpired) {
                        navigate(`${LOGIN_LINK}?sessionExpired=true`)
                    }
                })
            navigate(pathname)
        } else {
            navigate(`${LOGIN_LINK}?sessionExpired=true`)
        }
    }, [window])


    async function isSessionExpired(): Promise<boolean> {
        if ((localStorage.getItem("access_token") === null || localStorage.getItem("access_token") === undefined) &&
            (localStorage.getItem("refresh_token") === null || localStorage.getItem("refresh_token") === undefined)
        ) {
            return true
        }
        let response: IResponse<LoginResponse>
        try {
            response = await services.AuthService.checkAuth();
        } catch (e) {
            return true
        }
        if (response.Error !== undefined || response.Data === undefined || ((response.Status ?? 1) / 100) === 4) {
            return true
        }
        return false
    }

    return (

        <Routes>
            <Route path={LOGIN_LINK} element={<Login/>}/>
            <Route path={HOME_LINK} element={<HomePage/>}/>
            <Route path={CONFIDENTIALITY_POLICY} element={<ConfidentialityPolicy/>}/>
            <Route path={TERMS_AND_CONDITIONS} element={<TermsAndConditions/>}/>
            <Route path={"/"} element={<MyLayout/>}>
                <Route path={DASHBOARD_LINK} element={<Dashboard/>}/>
                <Route path={MENUS_LINK} element={
                    <Menu title={title}
                          btnAction={btnAction}
                          buttonText={btnText}
                    />
                }>
                    <Route path={MENU_PRODUCTS_LINK} element={
                        <MenuProductsPage setTitle={setTitle}
                                          setBtnAction={setBtnAction}
                                          setBtnText={setBtnText}/>
                    }/>
                    <Route path={MENUS_LINK} element={
                        <MenuPage setTitle={setTitle}
                                  setBtnAction={setBtnAction}
                                  setBtnText={setBtnText}
                        />
                    }/>
                </Route>
                <Route path={ITEMS_LINK} element={<Providers/>}/>
                <Route path={PRODUCTS_LINK} element={<PrimeProducts/>}/>
                <Route path={`${ORDERS_LINK}/:action`} element={<Orders/>}/>
                <Route path={RECEIPTS_LINK} element={<Receipts/>}/>
                <Route path={CONTACT_LINK} element={<Contact/>}/>
                <Route path={EXPIRED_ACCOUNT_LINK} element={<ExpiredAccount/>}/>
            </Route>
            <Route path={PASSWORD_RESET_LINK} element={<ResetPassword/>}/>
            {/*<Route path={"*"} element={<NotFoundPage/>}/>*/}
        </Routes>

    );
}

export default App;
