import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import {ServiceContext, ServiceContextInstance} from "../../../Core/serviceContext";
import {Button, Input, InputNumber, message, Modal, Select} from "antd";
import {PrimeProduct} from "../../../Models/backend/PrimeProduct";
import {QuantityType, QuantityTypeArray} from "../../../Enums/quantityType";

export const AddProductModalForm = ({providerId, showModal, setShowModal, setProducts, products}: {
    providerId: string,
    showModal: boolean,
    setShowModal: Dispatch<SetStateAction<boolean>>,
    setProducts: Dispatch<SetStateAction<PrimeProduct[]>>,
    products: PrimeProduct[]
}): React.JSX.Element => {
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);
    const [loading, setLoading] = useState<boolean>(false);

    const [name, setName] = useState<string>()
    const [price, setPrice] = useState<number>()
    const [measurement, setMeasurement] = useState<QuantityType>()

    const handleOk = async () => {
        let boolean = await addNewProduct();
        // setShowModal(!boolean);
        if (boolean) {
            setName(undefined)
            setMeasurement(undefined)
            setPrice(undefined)
        }
    };

    const handleCancel = () => {
        setShowModal(false);
        setName(undefined)
        setMeasurement(undefined)
        setPrice(undefined)
    };

    async function addNewProduct() {
        if (name === undefined || name.trim() === "") {
            message.warning("Numele trebuie sa fie completat")
            return
        }
        const product: PrimeProduct = {
            name: name!.trim(),
            price: price,
            quantityType: measurement
        }
        setLoading(true)
        let response = await services.ProviderService.addProduct(providerId, product);
        setLoading(false)
        if (response.Error !== undefined) {
            message.error("Produsul nu a putut fi adaugat!")
            return true
        }
        if (response.Message !== undefined) {
            message.warning(response.Message)
            return false
        }
        if (response.Data === undefined) {
            message.error("Produsul nu a putut fi adaugat!")
            return true
        }
        message.success("Produsul a fost adaugat cu succes!")
        setProducts([])
        setTimeout(() => {
            setProducts([response.Data!, ...products])
        }, 100)
        return true
    }

    return (
        <Modal title="Adauga produs"
               open={showModal}
               onOk={handleOk}
               onCancel={handleCancel}
               style={{minWidth: "40%", maxWidth: "80%"}}
               footer={[
                   <Button key="back" onClick={handleCancel}>
                       Renunță
                   </Button>,
                   <Button key="submit" type="primary"
                           loading={loading}
                           onClick={handleOk}>
                       Adaugă
                   </Button>
               ]}
        >
            <div className={"row"}>
                <div className={"col-md-12 row align-items-center mb-3"}>
                    <div className={"col-md-4"} style={{fontSize: "1.2rem"}}>
                        Denumire:<span style={{color: "red"}}>*</span>
                    </div>
                    <div className={"col-md-8"}>
                        <Input size={"large"}
                               placeholder={"Introdu numele..."}
                               value={name}
                               onChange={(e) => setName(e.currentTarget.value)}
                        />
                    </div>
                </div>

                <div className={"col-md-12 row align-items-center mb-2"}>
                    <div className={"col-md-4"} style={{fontSize: "1.2rem"}}>
                        Pret:
                    </div>
                    <div className={"col-md-8"}>
                        <InputNumber size={"large"}
                                     value={price}
                                     style={{width: "100%"}}
                                     placeholder={"Seteaza pretul"}
                                     onChange={(e) => setPrice(e!)}
                        />
                    </div>
                </div>

                <div className={"col-md-12 row align-items-center"}>
                    <div className={"col-md-4"} style={{fontSize: "1.2rem"}}>
                        Unitate de masura:
                    </div>
                    <div className={"col-md-8"}>
                        <Select size={"large"}
                                style={{width: "100%"}}
                                placeholder={"Alege unitatea de masura"}
                                options={[...QuantityTypeArray.map(el => {
                                    return {
                                        label: el,
                                        value: el
                                    }
                                })]}
                                value={measurement}
                                onChange={(val) => setMeasurement(val)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}