import {MenuProps} from "antd";
import React from "react";
import {CONTACT_LINK, DASHBOARD_LINK, ITEMS_LINK, LOGIN_LINK, MENU_PRODUCTS_LINK, MENUS_LINK, ORDERS_LINK, RECEIPTS_LINK} from "../../Enums/pagesUrls";

export type MenuItem = Required<MenuProps>['items'][number];
export type MenuItem2 = {
    icon: React.ReactNode;
    label: string;
    link: string;
    index: number;
    regex?: RegExp;
    disabled?: boolean;
    children?: MenuItem2[];
}

export let navItems: Map<string, MenuItem2> = new Map<string, MenuItem2>();
navItems.set("dashboard", {
    icon: <i className="fa-solid fa-gauge"></i>,
    label: "Dashboard",
    link: DASHBOARD_LINK,
    index: 0
})
navItems.set("produse", {
    icon: <span className="material-symbols-outlined">inventory</span>,
    label: "Produse",
    link: ITEMS_LINK,
    index: 1
})
navItems.set("meniuri", {
    icon: <span className="material-symbols-outlined">restaurant_menu</span>,
    label: "Meniuri",
    link: "",
    index: 2,
    children: [
        {
            icon: <span className="material-symbols-outlined">menu_book</span>,
            label: "Meniu",
            link: MENUS_LINK,
            index: 7
        },
        {
            icon: <span className="material-symbols-outlined">skillet</span>,
            label: "Fel principal",
            link: MENU_PRODUCTS_LINK,
            index: 8
        }
    ]
})
navItems.set("comenzi", {
    icon: <span className="material-symbols-outlined">description</span>,
    label: "Comenzi",
    link: `${ORDERS_LINK}/view`,
    regex: new RegExp(`(${ORDERS_LINK}/view)|(${ORDERS_LINK}/create)|(${ORDERS_LINK}/create-menu)`),
    index: 3
})
navItems.set("receptii", {
    icon: <span className="material-symbols-outlined">receipt_long</span>,
    label: "Receptii",
    link: RECEIPTS_LINK,
    index: 4
})
navItems.set("contact", {
    icon: <span className="material-symbols-outlined">contact_mail</span>,
    label: "Contact",
    link: CONTACT_LINK,
    index: 5
})
navItems.set("deconectare", {
    icon: <span className="material-symbols-outlined">logout</span>,
    label: "Deconectare",
    link: LOGIN_LINK,
    index: 6
})
export function getNavItem(
    item: MenuItem2
): MenuItem {
    return {
        key: item.index + "",
        icon: item.icon,
        children: item.children === undefined ?
            undefined :
            [...item.children.map(el => {
                return getNavItem(el)
            })],
        label: item.label,
        disabled: item.disabled,
    } as MenuItem;
}