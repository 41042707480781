import './styles.scss'
import React, {useEffect, useState} from "react";
import {DatePicker, Input} from 'antd';
import {mergeStyles} from "@fluentui/react";
import {ReceiptGlobalModelProps} from "./receiptGlobalModel.types";
import {ExcelTableRow} from "./excelTableRow";
import {ReceiptProduct} from "../../../../../Models/model/ReceiptProduct";
import {equalsTemplates, ReceiptTemplate} from "../../../../../Models/backend/ReceiptTemplate";
import {twoDecimals} from "../../../../../Library/utils/mathUtil";

let dateFormat = "DD.MM.YYYY";
const textareaStyle: string = mergeStyles({
    border: "none",
    height: "100%",
    width: "100%",
    selectors: {
        ":focus": {
            border: "none",
            outline: "none"
        },
        ":hover": {
            userFocus: "none",
        }
    }
})
export const ReceiptGlobalModel = (props: ReceiptGlobalModelProps): JSX.Element => {

    const [gestiunea, setGestiunea] = useState<string>();
    const [unitatea, setUnitatea] = useState<string>()
    const [excess, setExcess] = useState<number>()
    const [committee, setCommittee] = useState<string>()
    const [manager, setManager] = useState<string>()

    const [totalBuy, setTotalBuy] = useState<number>()
    const [totalExcess, setTotalExcess] = useState<number>()
    const [totalSellWithoutVat, setTotalSellWithoutVat] = useState<number>()
    const [totalSellWithVat, setTotalSellWithVat] = useState<number>()

    const [map, setMap] = useState<Map<number, ReceiptProduct>>()

    useEffect(() => {
        let r = props.receiptTemplate;
        setGestiunea(r ? r.managementName : "")
        setManager(r ? r.managerName : "")
        setUnitatea(r ? r.businessName : "")
        setUnitatea(r ? r.managementName : "")
        setCommittee(r ? r.receiptCommitteeNames : "")
        setExcess(r ? r.commercialExcess : 0)
    }, [props.receiptTemplate])

    function refreshTable() {
        let number = props.receiptProducts
            .reduce((total, product) => total + product.field6, 0);
        setTotalBuy(twoDecimals(number))

        const totalField10 = props.receiptProducts
            .reduce((total, product) => total + product.field10, 0);
        setTotalExcess(twoDecimals(totalField10))

        const totalField12 = props.receiptProducts
            .reduce((total, product) => total + product.field12, 0);
        setTotalSellWithoutVat(twoDecimals(totalField12))

        const totalField14 = props.receiptProducts
            .reduce((total, product) => total + product.field14, 0);
        setTotalSellWithVat(twoDecimals(totalField14))
    }

    useEffect(() => {
        refreshTable();
    }, [props.receiptProducts, map])

    useEffect(() => {
        const growers = document.querySelectorAll(".grow-wrap");

        growers.forEach((grower) => {
            const textarea = grower.querySelector("textarea")!;
            textarea.addEventListener("input", () => {
                // @ts-ignore
                grower.dataset.replicatedValue = textarea.value;
            });
        });
    }, [])

    function handleReceiptProductChange(product: ReceiptProduct) {
        if (map === undefined) {
            setMap(new Map<number, ReceiptProduct>())
            return
        }
        map.set(product.field0, product)
        let iterableIterator = map.values();
        let newProds = Array.from(iterableIterator);
        props.setReceiptProducts(newProds)
    }

    useEffect(() => {
        const newTemplate: ReceiptTemplate = {
            templateName: "<custom>",
            businessName: unitatea,
            managementName: gestiunea,
            commercialExcess: excess,
            receiptCommitteeNames: committee,
            managerName: manager,
        }
        let templateName = props.receiptTemplate
        if (!equalsTemplates(newTemplate, templateName)) {
            props.setCustomReceiptTemplate(newTemplate)
        }
    }, [unitatea, gestiunea, excess, committee, manager])


    return (
        <div id={"global-table"}>
            <table cellPadding="0" cellSpacing="0" id="sheet0" className="sheet0 gridlines">

                <tbody>
                <tr className="row1">
                    <td className="column0 style5 null"></td>
                </tr>
                <tr className="row2">
                    <td className="column0 style42 s style42"
                        colSpan={18}>
                        Nota de receptie si constatare diferente
                    </td>
                </tr>
                <tr className="row3">
                    <td className="column0 style5 null"></td>
                </tr>
                <tr className="row4">
                    <td className="column0 style5 null"></td>
                    <td className="column1 style6 s">Unitatea:</td>
                    <td className="column2 style43 s style43"
                        colSpan={5}>
                        <Input value={unitatea} onInput={(e) => {
                            setUnitatea(e.currentTarget.value)
                            props.setHasChanged(true)
                        }}/>
                    </td>
                    <td className="column5 style5 null"></td>
                    <td className="column6 style5 null"></td>
                    <td className="column7 style6 s"
                        colSpan={2}>Nr. NIR:
                    </td>
                    <td className="column8 style43 s style43"
                        colSpan={2}>
                        {props.nir}
                    </td>
                    <td className="column10 style5 null"></td>
                    <td className="column11 style5 null"></td>
                    <td className="column12 style5 null"></td>
                    <td className="column13 style5 null"></td>
                    <td className="column14 style5 null"></td>
                    <td className="column15 style5 null"></td>
                    <td className="column16 style5 null"></td>
                    <td className="column17 style5 null"></td>

                </tr>
                <tr className="row5">
                    <td className="column0 style5 null"></td>
                    <td className="column1 style6 s">Gestiunea:</td>
                    <td className="column2 style43 s style43"
                        colSpan={5}>
                        <Input value={gestiunea} onInput={(e) => {
                            setGestiunea(e.currentTarget.value)
                            props.setHasChanged(true)
                        }}/>
                    </td>
                    <td className="column5 style5 null"></td>
                    <td className="column6 style5 null"></td>
                    <td className="column7 style6 s"
                        colSpan={2}>Data:
                    </td>
                    <td className="column8 style43 s style43"
                        colSpan={2}>
                        <DatePicker format={dateFormat}
                                    defaultValue={props.date}
                                    onChange={(e) => {
                                        props.setDate(e!)
                                        props.setHasChanged(true)
                                    }}
                        />
                    </td>
                    <td className="column10 style5 null"></td>
                    <td className="column11 style5 null"></td>
                    <td className="column12 style5 null"></td>
                    <td className="column13 style5 null"></td>
                    <td className="column14 style5 null"></td>
                    <td className="column15 style5 null"></td>
                    <td className="column16 style5 null"></td>
                    <td className="column17 style5 null"></td>
                </tr>
                <tr className="row6">
                    <td className="column0 style5 null"></td>
                </tr>
                <tr className="row7">
                    <td className="column0 style5 null"></td>
                    <td className="column1 style40 s style41"
                        colSpan={16}
                        rowSpan={2}>
                        Subsemnatii, membrii ai comisiei de receptie, am receptionat valorile materiale furnizate de:
                        ….{props.provider}…, delegat: ……………….., auto nr.: .......……, pe baza documentelor insotitoare:
                        …..............., constatand:
                    </td>
                    <td className="column17 style5 null"></td>
                </tr>
                <tr className="row8">
                    <td className="column0 style5 null"></td>
                    <td className="column17 style5 null"></td>
                </tr>
                <tr className="row9">
                    <td className="column0 style5 null"></td>
                </tr>
                <tr className="row10">
                    <td className="column0 style45 s style45"
                        rowSpan={2}>Nr. Crt.
                    </td>
                    <td className="column1 style46 s style51"
                        colSpan={3} rowSpan={2}>Denumire produse sau a servicii / Cod
                    </td>
                    <td className="column4 style55 s style55"
                        rowSpan={2}>U.M.
                    </td>
                    <td className="column5 style56 s style57"
                        rowSpan={2}>Cant. doc.
                    </td>
                    <td className="column6 style58 s style58"
                        //@ts-ignore
                        rowSpan={2}>Cant. primita
                    </td>
                    <td className="column7 style31 s style44"
                        //@ts-ignore
                        colSpan={3}>Achizitie
                    </td>
                    <td className="column10 style31 s style32"
                        //@ts-ignore
                        colSpan={3}>Adaos
                    </td>
                    <td className="column13 style31 s style44"
                        //@ts-ignore
                        colSpan={5}>Vanzare
                    </td>
                    <td className="column18 style12 null"></td>
                    <td className="column19 style12 null"></td>
                    <td className="column20 style12 null"></td>
                    <td className="column21 style12 null"></td>
                    <td className="column22 style12 null"></td>
                    <td className="column23 style12 null"></td>
                    <td className="column24 style12 null"></td>
                    <td className="column25 style12 null"></td>
                    <td className="column26 style12 null"></td>
                    <td className="column27 style12 null"></td>
                    <td className="column28 style12 null"></td>
                    <td className="column29 style12 null"></td>
                    <td className="column30 style12 null"></td>
                    <td className="column31 style12 null"></td>
                    <td className="column32 style12 null"></td>
                    <td className="column33 style12 null"></td>
                    <td className="column34 style12 null"></td>
                    <td className="column35 style12 null"></td>
                    <td className="column36 style12 null"></td>
                    <td className="column37 style12 null"></td>
                    <td className="column38 style12 null"></td>
                    <td className="column39 style12 null"></td>
                    <td className="column40 style12 null"></td>
                    <td className="column41 style12 null"></td>
                    <td className="column42 style12 null"></td>
                    <td className="column43 style12 null"></td>
                    <td className="column44 style12 null"></td>
                    <td className="column45 style12 null"></td>
                    <td className="column46 style12 null"></td>
                    <td className="column47 style12 null"></td>
                    <td className="column48 style12 null"></td>
                    <td className="column49 style12 null"></td>
                </tr>
                <tr className="row11">
                    <td className="column7 style27 s">P.U. (fara TVA)</td>
                    <td className="column8 style27 s">Valoare (fara TVA)</td>
                    <td className="column9 style27 s">TVA aferent</td>
                    <td className="column10 style27 s">%</td>
                    <td className="column11 style27 s">P.U. (fara TVA)</td>
                    <td className="column12 style27 s">TVA aferent P.U.</td>
                    <td className="column13 style27 s">P.U. (fara TVA)</td>
                    <td className="column14 style27 s">Valoare (fara TVA)</td>
                    <td className="column15 style27 s">P.U. cu TVA</td>
                    <td className="column16 style27 s">Valoare (cu TVA)</td>
                    <td className="column17 style27 s">TVA aferent</td>
                    <td className="column18 style12 null"></td>
                    <td className="column19 style12 null"></td>
                    <td className="column20 style12 null"></td>
                    <td className="column21 style12 null"></td>
                    <td className="column22 style12 null"></td>
                    <td className="column23 style12 null"></td>
                    <td className="column24 style12 null"></td>
                    <td className="column25 style12 null"></td>
                    <td className="column26 style12 null"></td>
                    <td className="column27 style12 null"></td>
                    <td className="column28 style12 null"></td>
                    <td className="column29 style12 null"></td>
                    <td className="column30 style12 null"></td>
                    <td className="column31 style12 null"></td>
                    <td className="column32 style12 null"></td>
                    <td className="column33 style12 null"></td>
                    <td className="column34 style12 null"></td>
                    <td className="column35 style12 null"></td>
                    <td className="column36 style12 null"></td>
                    <td className="column37 style12 null"></td>
                    <td className="column38 style12 null"></td>
                    <td className="column39 style12 null"></td>
                    <td className="column40 style12 null"></td>
                    <td className="column41 style12 null"></td>
                    <td className="column42 style12 null"></td>
                    <td className="column43 style12 null"></td>
                    <td className="column44 style12 null"></td>
                    <td className="column45 style12 null"></td>
                    <td className="column46 style12 null"></td>
                    <td className="column47 style12 null"></td>
                    <td className="column48 style12 null"></td>
                    <td className="column49 style12 null"></td>
                </tr>
                <tr className="row12">
                    <td className="column0 style23 n">0</td>
                    <td className="column1 style54 n style54"
                        colSpan={3}>1
                    </td>
                    <td className="column4 style23 n">2</td>
                    <td className="column5 style23 n">3</td>
                    <td className="column6 style23 n">4</td>
                    <td className="column7 style23 n">5</td>
                    <td className="column8 style23 s">6(4x5)</td>
                    <td className="column9 style23 n">7</td>
                    <td className="column10 style23 n">8</td>
                    <td className="column11 style23 s">9(5x8)</td>
                    <td className="column12 style23 n">10</td>
                    <td className="column13 style23 s">11(5+9)</td>
                    <td className="column14 style23 s">12(6+10)</td>
                    <td className="column15 style23 n">13</td>
                    <td className="column16 style23 s">14(4x13)</td>
                    <td className="column17 style23 s">15(14-12)</td>
                    <td className="column18 style22 null"></td>
                    <td className="column19 style22 null"></td>
                    <td className="column20 style22 null"></td>
                    <td className="column21 style22 null"></td>
                    <td className="column22 style22 null"></td>
                    <td className="column23 style22 null"></td>
                    <td className="column24 style22 null"></td>
                    <td className="column25 style22 null"></td>
                    <td className="column26 style22 null"></td>
                    <td className="column27 style22 null"></td>
                    <td className="column28 style22 null"></td>
                    <td className="column29 style22 null"></td>
                    <td className="column30 style22 null"></td>
                    <td className="column31 style22 null"></td>
                    <td className="column32 style22 null"></td>
                    <td className="column33 style22 null"></td>
                    <td className="column34 style22 null"></td>
                    <td className="column35 style22 null"></td>
                    <td className="column36 style22 null"></td>
                    <td className="column37 style22 null"></td>
                    <td className="column38 style22 null"></td>
                    <td className="column39 style22 null"></td>
                    <td className="column40 style22 null"></td>
                    <td className="column41 style22 null"></td>
                    <td className="column42 style22 null"></td>
                    <td className="column43 style22 null"></td>
                    <td className="column44 style22 null"></td>
                    <td className="column45 style22 null"></td>
                    <td className="column46 style22 null"></td>
                    <td className="column47 style22 null"></td>
                    <td className="column48 style22 null"></td>
                    <td className="column49 style22 null"></td>
                </tr>
                {
                    props.products.map((p, index) => (
                        <ExcelTableRow product={p}
                                       key={index}
                                       index={index}
                                       commercialExcess={excess}
                                       handleReceiptProductChange={handleReceiptProductChange}
                                       setHasChanged={props.setHasChanged}
                        />
                    ))
                }
                <tr className="row14">
                    <td className="column0 style4 null"></td>
                    <td className="column1 style5 null"></td>
                    <td className="column2 style5 null"></td>
                    <td className="column3 style5 null"></td>
                    <td className="column4 style5 null"></td>
                    <td className="column5 style26 s">Total</td>
                    <td className="column6 style29 null style39"
                        colSpan={3}>
                        {totalBuy}
                    </td>
                    <td className="column9 style25 null">

                    </td>
                    <td className="column10 style29 null style39"
                        colSpan={3}>
                        {totalExcess}
                    </td>
                    <td className="column13 style29 null style30"
                        colSpan={2}>
                        {totalSellWithoutVat}
                    </td>
                    <td className="column15 style29 null style30"
                        colSpan={2}>
                        {totalSellWithVat}
                    </td>
                    <td className="column17 style25 null"></td>
                </tr>
                <tr className="row15">
                    <td className="column0 style4 null"></td>
                </tr>
                <tr className="row16">
                    <td className="column0 style4 null"></td>
                    <td className="column1 style21 s">Observatii:</td>
                    <td className="column2 style62 null style70"
                        colSpan={16}
                        rowSpan={3}>
                        <div className={"grow-wrap"}>
                            <textarea className={textareaStyle}
                                      value={props.observations || ""}
                                      onChange={(e) => {
                                          props.setObservations(e.currentTarget.value)
                                          props.setHasChanged(true)
                                      }}/>
                        </div>
                    </td>
                </tr>
                <tr className="row17">
                    <td className="column0 style4 null"></td>
                </tr>
                <tr className="row18">
                    <td className="column1 style5 null"></td>
                </tr>
                <tr className="row19">
                    <td className="column17 style5 null"></td>
                </tr>
                <tr className="row20">
                    <td className="column0 style5 null"></td>
                    <td className="column1 style17 s"
                        colSpan={7}>Numele si prenumele membrilor comisiei de receptie
                    </td>
                    <td className="column8 style33 s style34"
                        colSpan={2}>Semnatura
                    </td>
                    <td className="column10 style5 null"></td>
                    <td className="column11 style19 s"
                        colSpan={5}>Numele si prenumele gestionarului
                    </td>
                    <td className="column16 style33 s style34"
                        colSpan={2}>Semnatura
                    </td>
                </tr>
                <tr className="row21">
                    <td className="column0 style5 null"></td>
                    <td className="column1 style78 null style86"
                        colSpan={7}
                        rowSpan={3}>
                        <div className={"grow-wrap"}>
                            <textarea className={textareaStyle}
                                      value={committee}
                                      onChange={(e) => {
                                          setCommittee(e.currentTarget.value)
                                          props.setHasChanged(true)
                                      }}/>
                        </div>
                    </td>
                    <td className="column8 style35 null style36"
                        colSpan={2}></td>
                    <td className="column10 style5 null"></td>
                    <td className="column11 style78 null style86"
                        colSpan={5}
                        rowSpan={3}>
                        <div className={"grow-wrap"}>
                            <textarea className={textareaStyle}
                                      value={manager}
                                      onChange={(e) => {
                                          setManager(e.currentTarget.value)
                                          props.setHasChanged(true)
                                      }}/>
                        </div>
                    </td>
                    <td className="column16 style72 null style77"
                        colSpan={2}
                        rowSpan={3}></td>
                </tr>
                <tr className="row22">
                    <td className="column0 style5 null"></td>
                    <td className="column8 style37 null style38"
                        colSpan={2}></td>
                    <td className="column10 style5 null"></td>
                </tr>
                <tr className="row23">
                    <td className="column0 style5 null"></td>
                    <td className="column8 style52 null style53"
                        colSpan={2}></td>
                    <td className="column10 style5 null"></td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}