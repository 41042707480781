import {ServiceContext, ServiceContextInstance} from "../../../Core/serviceContext";
import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {Menu} from "../../../Models/backend/Menu";
import {IFetchResult} from "../../../Hooks/useFetch.types";
import {useFetch} from "../../../Hooks/useFetch";
import {Empty, Input, message, Select, Skeleton} from "antd";
import {MenuCard} from "./menuCard";
import {MenuModalOperation} from "./menuModalOperation";
import {MenuProductsSortOrder} from "../../../Enums/MenuProductsSortOrder";
import {MenuSortOrder} from "../../../Enums/MenuSortOrder";


export const MenuPage = (props: {
    setTitle: Dispatch<SetStateAction<string>>,
    setBtnText: Dispatch<SetStateAction<string>>,
    setBtnAction: Dispatch<SetStateAction<() => void>>
}): JSX.Element => {

    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [areMenusLoaded, setAreMenusLoaded] = useState<boolean>(false);

    const [sorted, setSorted] = useState<MenuSortOrder>();
    const [refreshMenus, setRefreshMenus] = useState<boolean>(false);
    const menusData: IFetchResult<Menu[]> = useFetch<Menu[]>(() => services.MenuService.getAll(sorted), [refreshMenus, sorted]);
    const [menus, setMenus] = useState<Menu[]>([])

    const [selectedMenuToUpdate, setSelectedMenuToUpdate] = useState<Menu | undefined>(undefined)
    const [searchValue, setSearchValue] = useState<string>();

    const [showModal, setShowModal] = useState<boolean>(false)
    const [modalType, setModalType] = useState<"create" | "update">("create")
    const [operation, setOperation] = useState<(menu: Menu) => Promise<boolean>>(() => {
        return async () => {
            return false
        }
    })

    useEffect(() => {
        props.setBtnAction(() => {
            return () => {
                setSelectedMenuToUpdate(undefined)
                setOperation(
                    () => {
                        return (menu: Menu): Promise<boolean> => {
                            return createMenu(menu)
                        }
                    }
                )
                setModalType("create")
                setShowModal(true)
            }
        })
        props.setTitle("Meniu")
        props.setBtnText("+ Creează meniu")
    }, [])


    useEffect(() => {
        if (menusData.isLoading) {
            return;
        }
        if (menusData.errors !== '' ||
            menusData.data === null ||
            menusData.data?.Error !== undefined ||
            menusData.data?.Data === undefined) {
            return;
        }
        let data = menusData.data.Data;
        setMenus(data)
        setAreMenusLoaded(true)
    }, [menusData])

    async function createMenu(menu: Menu): Promise<boolean> {
        let response = await services.MenuService.create(menu);
        if (response.Status === 209) {
            message.warning("Există deja un meniu cu această denumire! Încercați alt nume!")
            return false
        }
        if (response.Error !== undefined || response.Data === undefined) {
            message.error("Nu s-a putut crea meniul")
            return false
        }
        message.success("Meniul a fost creat!")
        return true
    }

    const sortOptions = [
        {
            label: "Sortează dupa nume",
            value: MenuProductsSortOrder.NAME
        },
        {
            label: "Sortează după preț crescător",
            value: MenuProductsSortOrder.PRICE_ASC
        },
        {
            label: "Sortează după preț descrescător",
            value: MenuProductsSortOrder.PRICE_DESC
        }
    ]
    return (
        <div id={"menu-p1-c1"}>
            <div className={"mb-3"}>
                <Input placeholder={"Caută dupa denumire"}
                       style={{width: "40%"}}
                       className={"me-3"}
                       value={searchValue}
                       onChange={(e) => {
                           setSearchValue(e.currentTarget.value)
                       }}
                />
                <Select placeholder={"Sortează după"}
                        style={{width: "20%"}}
                        options={sortOptions}
                        onChange={(value) => {
                            setSorted(value)
                            setAreMenusLoaded(false)
                        }}
                />
            </div>
            <div className={"row"}>
                {
                    areMenusLoaded ? (
                            menus.map((el, index) => (
                                <div key={index}
                                     className={"py-1 px-2 col-lg-6"}
                                >
                                    <MenuCard menu={el}
                                              index={index}
                                              setShowModal={setShowModal}
                                              setModalType={setModalType}
                                              setOperation={setOperation}
                                              setSelectedMenuToUpdate={setSelectedMenuToUpdate}
                                              refreshMenus={() => setRefreshMenus(!refreshMenus)}
                                    />
                                </div>
                            ))
                        ) :
                        (
                            <>
                                <div className={"col-lg-6 mb-4"}
                                >
                                    <Skeleton active
                                              className={"px-4 py-3 border rounded"}
                                              style={{backgroundColor: "white"}}
                                    />
                                </div>
                                <div className={"col-lg-6 mb-4"}>
                                    <Skeleton active
                                              className={"px-4 py-3 border rounded"}
                                              style={{backgroundColor: "white"}}
                                    />
                                </div>
                                <div className={"col-lg-6 mb-4"}>
                                    <Skeleton active
                                              className={"px-4 py-3 border rounded"}
                                              style={{backgroundColor: "white"}}
                                    />
                                </div>
                                <div className={"col-lg-6 mb-4"}>
                                    <Skeleton active
                                              className={"px-4 py-3 border rounded"}
                                              style={{backgroundColor: "white"}}
                                    />
                                </div>
                            </>
                        )

                }
                {
                    areMenusLoaded && menus.length === 0 && <Empty description={"Nu sunt meniuri create"}/>
                }
            </div>
            <MenuModalOperation showModal={showModal}
                                setShowModal={setShowModal}
                                operation={operation}
                                type={modalType}
                                menu={selectedMenuToUpdate}
                                setMenu={setSelectedMenuToUpdate}
                                refreshMenus={() => {
                                    setRefreshMenus(!refreshMenus)
                                }}
            />
        </div>
    )
}





