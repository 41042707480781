import React, {useContext, useEffect, useState} from "react";
import {ServiceContext, ServiceContextInstance} from "../../../Core/serviceContext";
import {IFetchResult} from "../../../Hooks/useFetch.types";
import {useFetch} from "../../../Hooks/useFetch";
import {PrimeProduct} from "../../../Models/backend/PrimeProduct";
import {useParams} from "react-router-dom";
import {Button, Divider, Dropdown, message, Modal, Spin, Typography} from "antd";
import {AddProductModalForm} from "./addProductModalForm";
import {IResponse} from "../../../Models/IResponse";
import {PrimeProductsTable} from "./ProductsTable/primeProductsTable";
import {ImportProductsForm} from "./importProductsForm";
import {ExportProductsForm} from "./exportProductsForm";
import {Provider} from "../../../Models/backend/Provider";
import {DeletionsForProductResponse} from "../../../Models/model/DeletionsForProductResponse";
import {ShowProviderDetailsModal} from "../showProviderDetailsModal";

const {Text} = Typography;
export const PrimeProducts = (): React.JSX.Element => {
    const params = useParams()
    const {providerId} = params

    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [refreshProducts, setRefreshProducts] = useState<boolean>(false)
    const productsData: IFetchResult<PrimeProduct[]> = useFetch<PrimeProduct[]>(() => services.ProviderService.getProviderProducts(providerId!), [refreshProducts]);
    const [products, setProducts] = useState<PrimeProduct[]>([])
    const providerData: IFetchResult<Provider> = useFetch<Provider>(() => services.ProviderService.getById(providerId!), []);
    const [provider, setProvider] = useState<Provider>()
    //modal for adding one product
    const [isProductModalOpen, setIsProductModalOpen] = useState<boolean>(false);
    const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
    const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
    const [isExportModal2Open, setIsExportModal2Open] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [checkedProducts, setCheckedProducts] = useState<string[]>([])

    const [loadedData, setLoadedData] = useState<boolean>(false)

    const [isDeleteProductModalButtonLoading, setIsDeleteProductModalButtonLoading] = useState<boolean>(false)
    const [possibleDeletions, setPossibleDeletions] = useState<DeletionsForProductResponse | undefined>();
    const [isProviderModalOpen, setIsProviderModalOpen] = useState<boolean>(false)

    useEffect(() => {
        if (productsData.isLoading) {
            return;
        }
        if (productsData.errors !== '' ||
            productsData.data === null ||
            productsData.data?.Error !== undefined ||
            productsData.data?.Data === undefined) {
            return;
        }
        let data = productsData.data.Data;
        setProducts(data)
        setLoadedData(true)
    }, [productsData])

    useEffect(() => {
        if (providerData.isLoading) {
            return;
        }
        if (providerData.errors !== '' ||
            providerData.data === null ||
            providerData.data?.Error !== undefined ||
            providerData.data?.Data === undefined) {
            return;
        }
        let data = providerData.data.Data;
        setProvider(data)
    }, [providerData])

    const openAddProductModal = () => {
        setIsProductModalOpen(true);
    };

    const openImportModal = () => {
        setIsImportModalOpen(true)
    }

    const openExportModal = () => {
        setIsExportModalOpen(true)
    }

    async function saveProduct(product: PrimeProduct): Promise<IResponse<PrimeProduct>> {
        return await services.PrimeProductService.update(product);
    }

    async function deleteProduct(id: string) {
        return services.PrimeProductService.delete([id]);
    }

    async function deleteSelectedProducts() {
        setIsDeleteProductModalButtonLoading(true)
        let response = await services.PrimeProductService.delete(checkedProducts);
        setPossibleDeletions(undefined)
        setIsDeleteProductModalButtonLoading(false)
        if (response.Error !== undefined) {
            message.error("Eroare la stergerea produselor selectate")
            return
        }
        message.success("Produsele selectate au fost eliminate cu succes")
        setRefreshProducts(!refreshProducts)
        setCheckedProducts([])
        setIsDeleteModalOpen(false)
    }

    async function exportProducts() {
        setIsExportModal2Open(true)
    }

    async function findPossibleDeletions(ids: string[]): Promise<void> {
        let response = await services.PrimeProductService.getPossibleMenusDeletions(ids.join(","));
        if (response.Error !== undefined) {
            message.warning("Produsul nu a putut fi gasit!")
            return
        }
        setPossibleDeletions(response.Data)
    }

    function openDeletionModal() {
        setIsDeleteModalOpen(true)
        findPossibleDeletions(checkedProducts)
    }

    function showProviderDetails() {
        setIsProviderModalOpen(true)
    }

    return (
        <div id={"prime-products"}>
            <div className={"padding-responsive"}>
                <div className={"d-flex justify-content-between align-items-center"}>
                    <div className={"h2"}>
                        Produse - {provider?.name} <i className="fa-solid fa-arrow-up-right-from-square pointer"
                                                      onClick={showProviderDetails}
                                                      style={{fontSize:"1.1rem"}}
                                                      title={"Informații furnizor"}></i>
                        <ShowProviderDetailsModal setIsModalOpen={setIsProviderModalOpen}
                                                  isModalOpen={isProviderModalOpen}
                                                  providers={[]}
                                                  provider={provider}
                        />
                    </div>
                    <div>
                        <Button size={"large"} onClick={openAddProductModal}>
                            Adaugă +
                        </Button>
                        <Button size={"large"} className={"ms-2"} onClick={openImportModal}>
                            Importă produse <i className="fa-solid fa-upload ms-2"></i>
                        </Button>
                        <Button size={"large"} className={"ms-2"} onClick={openExportModal}>
                            Exportă produse <i className="fa-solid fa-download ms-2"></i>
                        </Button>
                        <AddProductModalForm providerId={providerId!}
                                             showModal={isProductModalOpen}
                                             setShowModal={setIsProductModalOpen}
                                             products={products}
                                             setProducts={setProducts}
                        />
                        <ImportProductsForm showModal={isImportModalOpen}
                                            setShowModal={setIsImportModalOpen}
                                            providerId={providerId!}
                                            products={products}
                                            setProducts={setProducts}
                        />
                        {/* this is the modal from right up to export all products -- maybe delete it */}
                        <ExportProductsForm showModal={isExportModalOpen}
                                            setShowModal={setIsExportModalOpen}
                                            providerId={providerId!}
                                            productsIds={products.map(product => product.id!)}
                        />
                        {/* this is the modal to export only selected products */}
                        <ExportProductsForm showModal={isExportModal2Open}
                                            setShowModal={setIsExportModal2Open}
                                            providerId={providerId!}
                                            productsIds={checkedProducts}
                        />
                    </div>
                </div>
                <Divider className={"mt-3"}/>
                <div>
                    <OperationWithSelectedProducts className={"mb-2 ps-5"}
                                                   delete={openDeletionModal}
                                                   export={exportProducts}
                    />
                    <PrimeProductsTable products={products}
                                        loaded={loadedData}
                                        setProducts={setProducts}
                                        saveProduct={saveProduct}
                                        deleteProduct={deleteProduct}
                                        setCheckedProducts={setCheckedProducts}
                                        checkedProducts={checkedProducts}
                    />
                    <OperationWithSelectedProducts className={"mt-2 ps-5"}
                                                   delete={openDeletionModal}
                                                   export={exportProducts}
                    />
                </div>
            </div>
            <div className={"modalls"}>
                <Modal title="Atentie"
                       open={isDeleteModalOpen}
                       onCancel={() => {
                           setIsDeleteModalOpen(false)
                           setPossibleDeletions(undefined)
                       }}
                       footer={null}>
                    <div>
                        <span>Urmeaza sa fie sterse </span>
                        <Text type={"danger"} strong
                              style={{color: "black !important"}}>{checkedProducts.length}</Text>
                        <span> produse.</span>
                    </div>

                    <div>
                        {possibleDeletions === undefined && <Spin/>}
                        {possibleDeletions !== undefined && (possibleDeletions.menuProducts.length > 0 || possibleDeletions.menus.length > 0) && (
                            <div>
                                <div>
                                    Meniurile și preparatele care conțin produsele respective vor fi și ele șterse!!!
                                </div>
                                <div>
                                    Preparatele
                                </div>
                                <div>
                                    <ul>
                                        {possibleDeletions.menuProducts.map((menuProduct, index) => (
                                            <li key={index}>{menuProduct}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div>
                                    Meniurile
                                </div>
                                <div>
                                    <ul>
                                        {possibleDeletions.menus.map((menu, index) => (
                                            <li key={index}>{menu}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                    <Button type={"primary"} loading={isDeleteProductModalButtonLoading} danger
                            onClick={deleteSelectedProducts}>Sterge</Button>
                </Modal>
            </div>
        </div>
    )
}

const OperationWithSelectedProducts = (props: {
    className?: string,
    delete: () => void,
    export: () => void
}): JSX.Element => {
    return (
        <div className={props.className}>
            <Dropdown menu={{
                items: [
                    {key: '1', label: 'Sterge produsele', onClick: props.delete},
                    {key: '2', label: 'Exporta produsele', onClick: props.export},
                ]
            }}
                      arrow={{pointAtCenter: true}}>
                <Button>cu produsele selectate:</Button>
            </Dropdown>
        </div>
    )
}