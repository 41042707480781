import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {Button, Form, Input, message, Modal} from "antd";
import {ServiceContext, ServiceContextInstance} from "../../Core/serviceContext";
import {Provider} from "../../Models/backend/Provider";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

export const ShowProviderDetailsModal = (props: {
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    isModalOpen: boolean;
    providers: Provider[];
    provider?: Provider; // provider which is updated
}): JSX.Element => {
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);
    const [loadingModal, setLoadingModal] = useState<boolean>(false)

    const [name, setName] = useState<string>("")
    useEffect(() => {
        setName(props.provider?.name ?? "")
    }, [props.provider])

    const [form] = Form.useForm();

    useEffect(() => {
        if (props.isModalOpen) {
            form.setFieldValue("name", props.provider?.name ?? "")
            form.setFieldValue("address", props.provider?.address ?? "")
            const emails = props.provider?.emails ?? []
            if (emails.length > 0) {
                form.setFieldValue("email0", emails[0])
                const restOfEmails = emails.filter(e => e !== emails[0])
                form.setFieldsValue({emails: [...restOfEmails ?? []]})
            }
            const phones = props.provider?.phones ?? []
            if (phones.length > 0) {
                form.setFieldValue("phone0", phones[0])
                const restOfPhones = phones.filter(e => e !== phones[0])
                form.setFieldsValue({phones: [...restOfPhones ?? []]})
            }
        }
    }, [props.isModalOpen])

    const handleCancel = () => {
        props.setIsModalOpen(false);
        form.resetFields();
    };
    const onFinish = async (values: any) => {
        const name: string = values.name
        if (name === undefined || name.trim() === "") {
            message.warning("Numele trebuie sa fie completat!")
            return
        }
        const emails: string[] = [values.email0, ...values.emails ?? []].filter(x => x !== undefined)
        const phones: string[] = [values.phone0, ...values.phones ?? []].filter(x => x !== undefined)
        const address: string | undefined = values.address

        setLoadingModal(true)
        let action: "UPDATE" | "CREATE" = props.provider ? "UPDATE" : "CREATE";
        let response = await services.ProviderService.addOrUpdate(props.provider?.id, name, emails, phones, address, action);
        setLoadingModal(false)
        let data = response.Data;
        if (data === undefined || response.Error !== undefined) {
            if (response.Message !== undefined) {
                message.warning(response.Message)
                return
            }
            if (action === "UPDATE") {
                message.error("Furnizorul nu a putut fi actualizat!")
                return
            }
            message.error("Furnizorul nu a putut fi adaugat!")
            return
        }
        if (action === "CREATE") {
            props.providers.splice(0, 0, data)
            message.success("Furnizorul a fost adaugat cu succes!")
        }
        if (action === "UPDATE") {
            let index = props.providers.findIndex(p => p.id === data!.id)
            props.providers[index] = data
            message.success("Furnizorul a fost actualizat cu succes!")
        }
        props.setIsModalOpen(false);
        form.resetFields();
    };

    return (
        <Modal title={props.provider ? "Modificare furnizor" : "Adăugare furnizor nou"}
               open={props.isModalOpen}
               style={{minWidth: "40%", maxWidth: "80%"}}
               onCancel={handleCancel}
               footer={null}
        >
            <Form
                form={form}
                name="dynamic_form_item"
                {...formItemLayoutWithOutLabel}
                onFinish={onFinish}
                validateMessages={{
                    required: '${label} trebuie completat',
                    types: {
                        number: 'Introdu un numar valid',
                    },
                }}
            >
                <Form.Item label={"Numele"}
                           className={"mb-2"}
                           {...formItemLayout}
                           rules={[{
                               required: true
                           }]}
                           name={'name'}
                >
                    <Input placeholder={"Numele furnizorului"}/>
                </Form.Item>
                <Form.Item label={"Adresa"} name={"address"} {...formItemLayout} className={"mb-2"}>
                    <Input placeholder={"Adresa"}/>
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label={'Email'}
                    required={false}
                    className={"mb-2"}
                >
                    <Form.Item
                        name={"email0"}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[{
                            type: "email",
                            message: "Adresa de email nu este valida."
                        }]}
                        noStyle
                    >
                        <Input placeholder="Adresa de email"/>
                    </Form.Item>
                </Form.Item>
                <Form.List
                    name="emails"
                >
                    {(fields, {add, remove}, {errors}) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    {...formItemLayout}
                                    label={
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                        />
                                    }
                                    required={false}
                                    key={field.key}
                                    className={"mb-2"}
                                >
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[{
                                            type: "email",
                                            message: "Adresa de email nu este valida."
                                        }]}
                                        noStyle
                                    >
                                        <Input placeholder="Adresa de email"/>
                                    </Form.Item>
                                </Form.Item>
                            ))}
                            <Form.Item className={"mb-2"}>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    icon={<PlusOutlined/>}
                                >
                                    Adaugă email
                                </Button>
                                <Form.ErrorList errors={errors}/>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item
                    {...formItemLayout}
                    label={'Telefon'}
                    required={false}
                    className={"mb-2"}
                >
                    <Form.Item
                        validateTrigger={['onChange', 'onBlur']}
                        noStyle
                        name={"phone0"}
                    >
                        <Input placeholder="Numarul de telefon"
                               type={"number"}
                        />
                    </Form.Item>
                </Form.Item>
                <Form.List
                    name="phones"
                >
                    {([...fields], {add, remove}, {errors}) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    {...formItemLayout}
                                    label={
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                        />}
                                    required={false}
                                    key={field.key}
                                    className={"mb-2"}
                                >
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        noStyle
                                    >
                                        <Input placeholder="Numarul de telefon"
                                               type={"number"}
                                        />
                                    </Form.Item>
                                </Form.Item>
                            ))}
                            <Form.Item className={"mb-2"}>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    icon={<PlusOutlined/>}
                                >
                                    Adaugă telefon
                                </Button>
                                <Form.ErrorList errors={errors}/>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item>
                    <Button htmlType={"submit"}
                            type="primary"
                            loading={loadingModal}
                    >
                        {
                            props.provider ? "Actualizează" : "Adaugă"
                        }
                    </Button>
                    <Button type="primary"
                            danger
                            onClick={handleCancel}
                            className={"ms-3"}
                    >
                        Renunță
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

const formItemLayout = {
    labelCol: {
        xs: {span: 4},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 20},
        sm: {span: 20},
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: {span: 20, offset: 4},
        sm: {span: 20, offset: 4},
    },
};
