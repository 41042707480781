import {TableItem} from "./tableItem";
import React from "react";
import {ColumnStructure} from "./columns";

export function getOnCell(
    col: ColumnStructure,
    isEditing: (record: TableItem) => boolean,
    calcIndex: (record: TableItem, col: any) => number,
    save: (key: React.Key) => Promise<void>,
    edit: (record: (Partial<TableItem> & {
        key: React.Key
    })) => void,
    cancelEdit: () => void) {
    return (record: TableItem) => ({
        record,
        inputType: (col.dataIndex === 'price' || col.dataIndex === "totalPrice") ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        save: save,
        index: calcIndex(record, col),
        edit: edit,
        cancelEdit: cancelEdit
    });
}