import {ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {MenuProduct} from "../../../Models/backend/MenuProduct";
import {Button, Divider, Input, InputNumber, Modal, Tooltip} from "antd";
import {ServiceContext, ServiceContextInstance} from "../../../Core/serviceContext";
import {IFetchResult} from "../../../Hooks/useFetch.types";
import {useFetch} from "../../../Hooks/useFetch";
import {PrimeProduct} from "../../../Models/backend/PrimeProduct";
import {arrayBufferToString, computeMediaFileContent} from "../../../Library/utils/arrayBuffer";
import {MenuProductComputationCard} from "./MenuProductComputation/menuProductComputationCard";
import {PrimeProductWithQuantity} from "../../../Models/PrimeProductWithQuantity";
import {extractMinQuantity} from "../../../Library/utils/quantityDetector";
import {readImageSrc} from "../../../Library/utils/imageHandling";
import {borderStyle, imageHover} from "./styles";

const {TextArea} = Input;

export const MenuProductModalOperation = (props: {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    operation: (menuProduct: MenuProduct) => Promise<boolean>;
    type: "create" | "update";
    menuProduct?: MenuProduct;
    setMenuProduct?: Dispatch<SetStateAction<MenuProduct | undefined>>;
    refreshProducts: () => void;
}): JSX.Element => {
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [enabledButtonCreate, setEnabledButtonCreate] = useState<boolean>(false)

    const [productPrice, setProductPrice] = useState<number>()
    const [productName, setProductName] = useState<string>()
    const [productDescription, setProductDescription] = useState<string>()

    const primeProductsData: IFetchResult<PrimeProduct[]> = useFetch<PrimeProduct[]>(() => services.PrimeProductService.getAll(), []);
    const [primeProducts, setPrimeProducts] = useState<PrimeProduct[]>([])
    const [usedPrimeProducts, setUsedPrimeProducts] = useState<PrimeProduct[]>([])

    const imageInputRef = useRef(null)
    const [imageSrc, setImageSrc] = useState<ArrayBuffer>()

    const [composition, setComposition] = useState<PrimeProductWithQuantity[]>([])

    useEffect(() => {
        if (primeProductsData.isLoading) {
            return;
        }
        if (primeProductsData.errors !== '' ||
            primeProductsData.data === null ||
            primeProductsData.data?.Error !== undefined ||
            primeProductsData.data?.Data === undefined) {
            return;
        }
        let data = primeProductsData.data.Data;
        setPrimeProducts(data)
        for (let datum of data) {
            extractMinQuantity(datum)
        }
    }, [primeProductsData])

    useEffect(() => {
        if (productName === undefined || productDescription === undefined || productPrice === undefined || composition === undefined || composition.length === 0) {
            setEnabledButtonCreate(false)
            return
        }
        let mapIsEmpty = !compositionIsEmpty();
        setEnabledButtonCreate(productName.trim() !== "" && productDescription.trim() !== "" && productPrice > 0 && mapIsEmpty)
    }, [productName, productDescription, productPrice, composition])

    useEffect(() => {
        if (props.menuProduct === undefined) {
            return
        }
        setProductName(props.menuProduct.name)
        setProductDescription(props.menuProduct.description)
        setProductPrice(props.menuProduct.price)
        let pp = props.menuProduct.primeProducts;
        setComposition(pp)
        setUsedPrimeProducts(pp.map(p => p.orderProduct!))
        let media = props.menuProduct.media;
        if (media !== undefined && media.bytes !== undefined && media.bytes !== "") {
            setImageSrc(media.bytes as ArrayBuffer)
        }
    }, [props.menuProduct])

    function resetAndClose() {
        setProductName(undefined)
        setProductDescription(undefined)
        setProductPrice(undefined)
        setImageSrc(undefined)
        setComposition([])
        setUsedPrimeProducts([])
        props.setShowModal(false)
        if (props.setMenuProduct) {
            props.setMenuProduct(undefined)
        }
    }


    async function imageInputHandleChange(e: ChangeEvent<HTMLInputElement>) {
        await readImageSrc(e, setImageSrc);
    }

    const addItemToMap = () => {
        if (composition.map(p => p.orderProduct?.id).includes("0")) {
            return
        }
        setComposition([...composition, {orderProduct: {id: "0"} as PrimeProduct} as PrimeProductWithQuantity]);
    };

    const removeItemFromMap = (key: number) => {
        let strings = composition.map(p => p.orderProduct?.id!);
        setUsedPrimeProducts([...usedPrimeProducts.filter(p => strings.includes(p.id!))])
        setComposition([...composition.filter(p => p.orderProduct!.id! + "" !== key + "")]);
    };

    const updateItemInMap = (key: number, value: PrimeProductWithQuantity) => {
        setUsedPrimeProducts([...usedPrimeProducts, value.orderProduct!])
        setComposition([...composition.map(p => p.orderProduct!.id! + "" === key + "" ? value : p)]);
    }

    function compositionIsEmpty(): boolean {
        for (let value of composition) {
            if (value.orderProduct !== undefined && value.quantity !== undefined) {
                return false
            }
        }
        return true
    }

    function computeMenuProduct() {
        let imageType = "image/png";
        let source = "";
        if (imageSrc !== undefined) {
            let arrayBufferAsString = arrayBufferToString(imageSrc)
            if (arrayBufferAsString.startsWith("data:image/")) {
                let index = arrayBufferAsString.indexOf(";base64,");
                // data:image/
                let start = "data:image/".length;
                let type = arrayBufferAsString.substring(start, index)
                imageType = type === "*" ? "image/png" : "image/" + type
                source = arrayBufferAsString.substring(index).replace(";base64,", "")
            }
        }
        const menuProduct: MenuProduct = {
            id: props.menuProduct?.id,
            name: productName!,
            description: productDescription!,
            price: productPrice!,
            primeProducts: composition,
            media: {
                name: "file",
                type: imageType,
                bytes: source
            }
        }
        return menuProduct
    }

    function getPrimeProducts(value: PrimeProductWithQuantity) {
        return primeProducts.filter(p => {
            if (p.id === value.orderProduct?.id) {
                return true; // keep the one that is in the menu
            } else {
                return usedPrimeProducts.find(p1 => p1.id === p.id) === undefined;
            }
        });
    }

    return (
        <Modal title={`${props.type === "create" ? "Creare preparat" : "Actualizare preparat"}`}
               open={props.showModal}
               onOk={() => resetAndClose()}
               onCancel={() => resetAndClose()}
               footer={null}
               width={"85%"}
        >
            <div className={"row"}>
                <div className={"col-lg-5"}>
                    <Tooltip title={"Denumirea preparatului"} placement={"topRight"} className={"mb-3"}>
                        <Input placeholder={"Denumire preparat"}
                               value={productName}
                               onChange={(e) => setProductName(e.target.value)}
                        />
                    </Tooltip>
                    <Tooltip title={"Pretul preparatului"} placement={"topRight"} className={"mb-3"}>
                        <InputNumber placeholder={"Pretul preparatului"}
                                     value={productPrice}
                                     type={"number"}
                                     min={0}
                                     onChange={(value) => setProductPrice(value!)}
                                     style={{width: "100%"}}
                                     suffix={"RON"}
                        />
                    </Tooltip>
                    <Tooltip placement={"topRight"} title={"Descrierea preparatului"} className={"mb-3"}>
                        <TextArea placeholder={"Descrie compozitia preparatului, enumera elementele care il alcatuiesc"}
                                  value={productDescription}
                                  onChange={(e) => setProductDescription(e.target.value)}
                        />
                    </Tooltip>
                </div>
                <div className={"col-lg-7 ps-3"}>
                    <div>
                        <b>Selecteaza o poza reprezantativa a preparatului</b>
                        <input type="file" accept={"image/*"} ref={imageInputRef} style={{display: "none"}}
                               onChange={imageInputHandleChange}/>
                    </div>
                    <div className={`${borderStyle} pointer`}
                         onClick={() => {
                             if (imageInputRef !== null) {
                                 //@ts-ignore
                                 imageInputRef.current.click()
                             }
                         }}
                    >
                        {
                            (imageSrc === undefined) ?
                                (
                                    <div className={"d-flex text-center flex-column"}>
                                        <i className="fa-solid fa-image fa-4x" style={{opacity: "0.5"}}></i>
                                        <div>
                                            Incarca o imagine...
                                        </div>
                                    </div>
                                ) :
                                (
                                    <div style={{position: "relative"}} className={`${imageHover}`}>
                                        <img
                                            style={{maxWidth: "100%", maxHeight: "700px"}}
                                            src={computeMediaFileContent(imageSrc, "image")}
                                            alt={"text"}/>
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
            <Divider className={"m-0 mb-3"}/>
            <div>
                <div>
                    <b> Compoziție:</b>
                </div>
                <div className={"row"}>
                    {
                        composition.map((value, index) => (
                            <MenuProductComputationCard
                                primeProducts={getPrimeProducts(value)}
                                index={index + 1}
                                itemKey={Number(value.orderProduct!.id!)}
                                updateItem={updateItemInMap}
                                removeItem={removeItemFromMap}
                                key={index}
                                value={value}
                            />
                        ))
                    }
                </div>
                <div className={`${borderStyle} pointer`}
                     onClick={addItemToMap}
                >
                    Adauga produs +
                </div>
            </div>
            <div className={"mt-4"}>
                <Tooltip title={
                    !enabledButtonCreate &&
                    "Completeaza toate datele, iar preparatul trebuie sa aiba in compozitie cel putin un produs!"
                }
                >
                    <Button type={"primary"}
                            disabled={!enabledButtonCreate}
                            loading={btnLoading}
                            onClick={async () => {
                                setBtnLoading(true);
                                let success = await props.operation(computeMenuProduct());
                                setBtnLoading(false);
                                if (success) {
                                    props.refreshProducts()
                                    resetAndClose()
                                }
                            }}
                    >
                        {props.type === "create" ? "Creare" : "Actualizare"} preparat
                    </Button>
                    <Button onClick={resetAndClose} className={"ms-2"} danger>
                        Renunță
                    </Button>
                </Tooltip>
            </div>
        </Modal>
    )
}