import {Button, Divider, Empty, FloatButton, Input, Select, Spin} from "antd";
import {ServiceContext, ServiceContextInstance} from "../../Core/serviceContext";
import {useContext, useEffect, useState} from "react";
import {IFetchResult} from "../../Hooks/useFetch.types";
import {useFetch} from "../../Hooks/useFetch";
import {Provider} from "../../Models/backend/Provider";
import {ReceiptCard} from "./Card/receiptCard";
import {ReceiptTemplate} from "../../Models/backend/ReceiptTemplate";
import {Receipt} from "../../Models/backend/Receipt";
import {ReceiptsWithDates} from "../../Models/model/ReceiptsWithDates";
import './styles.scss'
import {CreateReceiptModal} from "./createReceiptModal";
import {mergeStyles} from "@fluentui/react";

export const ViewReceipt = ({receiptTemplates}: {
    receiptTemplates: ReceiptTemplate[];
}): JSX.Element => {
    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [receiptsChanged, setReceiptsChanged] = useState<boolean>()
    const receiptsData: IFetchResult<Receipt[]> = useFetch<Receipt[]>(() => services.ReceiptService.getAll(), [receiptsChanged]);
    const [receipts, setReceipts] = useState<Receipt[]>()
    const [receiptsWithDates, setReceiptsWithDates] = useState<ReceiptsWithDates[]>()

    const providersData: IFetchResult<Provider[]> = useFetch<Provider[]>(() => services.ProviderService.getAll(), []);
    const [providersOptions, setProvidersOptions] = useState<{
        value: string;
        label: string;
        provider: Provider | undefined
    }[]>()
    const [selectedProvider, setSelectedProvider] = useState<Provider>()

    const [searchReceiptName, setSearchReceiptName] = useState<string>()
    const [loadedData, setLoadedData] = useState<boolean>(false)

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (receiptsData.isLoading) {
            return;
        }
        if (receiptsData.errors !== '' ||
            receiptsData.data === null ||
            receiptsData.data?.Error !== undefined ||
            receiptsData.data?.Data === undefined) {
            return;
        }
        let data = receiptsData.data.Data;
        setReceipts(data)
        setLoadedData(true)
    }, [receiptsData])


    useEffect(() => {
        if (providersData.isLoading) {
            return;
        }
        if (providersData.errors !== '' ||
            providersData.data === null ||
            providersData.data?.Error !== undefined ||
            providersData.data?.Data === undefined) {
            return;
        }
        let data = providersData.data.Data;
        const options: {
            label: string;
            value: string;
            provider: Provider | undefined
        }[] = [{label: "toți", value: "all", provider: undefined}]
        data.forEach(d => options.push({label: d.name!, value: d.id!, provider: d}))
        setProvidersOptions(options)
    }, [providersData])

    useEffect(() => {
        if (receipts === undefined) {
            return
        }
        if (receipts.length === 0) {
            setReceiptsWithDates([])
            return
        }

        let map: ReceiptsWithDates[] = []
        let list: Receipt[] = []
        let lastDate = getDate(receipts[0].date!)
        for (let receipt of receipts) {
            //if provider is selected skip the receipts that does not have that provider
            if (selectedProvider !== undefined) {
                if (receipt.providerName !== selectedProvider.name) {
                    continue
                }
            }
            const receiptName = receipt.name.toLowerCase().trim()
            if (searchReceiptName === undefined || searchReceiptName.trim() === "") {

            } else {
                if (!receiptName.includes(searchReceiptName.toLowerCase().trim())) {
                    continue
                }
            }
            let date = getDate(receipt.date!)
            if (date !== lastDate) {
                const newEntry: ReceiptsWithDates = {
                    date: lastDate,
                    receipts: list.sort((a, b) => {
                        return b.nir - a.nir
                    })
                }
                lastDate = date
                map.push(newEntry)
                list = [receipt]
            } else {
                list.push(receipt)
            }
        }
        if (list.length !== 0) {
            const newEntry: ReceiptsWithDates = {
                date: lastDate,
                receipts: list.sort((a, b) => {
                    return b.nir - a.nir
                })
            }
            map.push(newEntry)
        }
        setReceiptsWithDates(map)
    }, [receipts, selectedProvider, searchReceiptName])

    function getDate(dateString: string): string {
        return dateString.split("T")[0]
    }

    return (
        <>
            <div style={{position: "relative"}} id={"view-receipts"}>
                <FloatButton.BackTop/>
                <div className={"inputs-wrapper"}>
                    <div>
                        <div className={"input-div"}>
                            <div>Vezi recepțiile de la:</div>
                            <Select
                                options={providersOptions}
                                onChange={(val, opt) => {
                                    // @ts-ignore
                                    setSelectedProvider(opt.provider)
                                }}
                                defaultValue={{
                                    label: "toți",
                                    value: "all",
                                    provider: undefined
                                }}
                            />
                        </div>
                        <div className={"input-div"}>
                            <div>
                                Caută după denumire:
                            </div>
                            <Input placeholder={"Caută recepții după nume"}
                                   value={searchReceiptName}
                                   onChange={(e) => setSearchReceiptName(e.currentTarget.value)}
                            />
                        </div>
                    </div>
                    <Divider className={styleDividerByScreenWidth}/>
                    <div>
                        <Button type={"primary"}
                                onClick={() => setIsModalOpen(true)}
                        >
                            Creează recepții
                        </Button>
                    </div>
                </div>

                {
                    (!loadedData || receiptsWithDates === undefined) ?
                        (
                            <div className={"pt-5"} style={{marginLeft: "35%"}}>
                                <Spin size={"large"} style={{fontSize: "50px", transform: "scale(2.1)"}}/>
                            </div>
                        ) :
                        (
                            <div>
                                {
                                    receiptsWithDates.map((el, index) => (
                                        <div key={index}>
                                            <Divider orientation={"left"} style={{fontSize: "20px"}}>
                                                {el.date}
                                            </Divider>
                                            <div className={"receipt-cards-wrapper"}>
                                                {
                                                    el.receipts.map((receipt, index) => (
                                                        <ReceiptCard
                                                            setReceiptsChanged={() => setReceiptsChanged(!receiptsChanged)}
                                                            receipt={receipt}
                                                            receiptsTemplates={receiptTemplates}
                                                            key={index}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    receiptsWithDates.length === 0 && (
                                        <Empty description={"Nu sunt recepții generate"}/>
                                    )
                                }
                            </div>
                        )
                }
            </div>
            <CreateReceiptModal setIsModalOpen={setIsModalOpen}
                                isModalOpen={isModalOpen}
                                setLoadedData={setLoadedData}
                                setReceiptsChanged={() => setReceiptsChanged(!receiptsChanged)}
            />
        </>

    )
}

const styleDividerByScreenWidth = mergeStyles({
    display: "none",
    "@media screen and (max-width: 768px)": {
        display: "block",
        marginTop: "0.5rem"
    }
})