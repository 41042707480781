import React, {useEffect, useState} from 'react';
import {Form, Table} from 'antd';
import {computePrimeProductsTableColumns} from "./columns";
import './styles.scss'
import {EditableCell} from "./editableCell";
import {PrimeProductsTableProps} from "./primeProductsTableProps";
import {TableItem} from "./tableItem";
import {getOnCell} from "./cell";


export const PrimeProductsTable = ({
                                       products,
                                       updateProduct,
                                   }: PrimeProductsTableProps): React.JSX.Element => {
    const [form] = Form.useForm();
    const [data, setData] = useState<TableItem[]>([]);
    const [editingKey, setEditingKey] = useState('');

    useEffect(() => {
        let tableItems = products.map(
            p => {
                let newVar: TableItem = {
                    key: p.orderProduct!.id!,
                    price: p.orderProduct!.price!,
                    quantityType: p.orderProduct!.quantityType!,
                    name: p.orderProduct!.name!,
                    quantity: p.quantity!,
                    totalPrice: Number((p.quantity! * (p.orderProduct!.price ?? 0)).toFixed(2))
                };
                return newVar
            }
        );
        let tableItems1 = tableItems.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
        setData([...tableItems1])
    }, [products])

    const isEditing = (record: TableItem) => record.key === editingKey;

    const edit = (record: Partial<TableItem> & { key: React.Key }) => {
        form.setFieldsValue({name: '', price: 0, quantityType: '', ...record});
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key: React.Key) => {
        try {
            const row = (await form.validateFields()) as TableItem;
            const newData: TableItem[] = [...data];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                updateProduct(item.key, row.quantity)
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                    totalPrice: Number((Number(row.quantity) * Number(item.price)).toFixed(2))
                });
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };


    const mergedColumns = computePrimeProductsTableColumns(
        isEditing,
        save,
        cancel,
        editingKey !== '',
        edit
    ).map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: getOnCell(col, isEditing, calcIndex, save, edit, cancel),
        };
    });

    function calcIndex(record: TableItem, col: any): number {
        for (let i = 0; i < products.length; i++) {
            if (products[i].orderProduct!.id === record.key) {
                return i;
            }
        }
        return -1;
    }

    return (
        <Form form={form} component={false}>
            <Table
                id={"editable-table-prime-products"}
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={false}
            />
        </Form>
    );
};