import {Button, Form, Input, message} from "antd";
import TextArea from "antd/es/input/TextArea";
import {blue, red} from "@mui/material/colors";
import {ServiceContext, ServiceContextInstance} from "../../Core/serviceContext";
import {useContext, useEffect, useRef, useState} from "react";
import './styles.scss';
import {UserDb} from "../../Models/backend/UserDb";
import {accountExpiredLS} from "../../Enums/localStorageItems";

export const Contact = (props: {
    message?: string
}): React.JSX.Element => {

    const defaultBorderColor: string = "#d9d9d9"

    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const [email, setEmail] = useState<string>()
    const [name, setName] = useState<string>()
    const [phone, setPhone] = useState<string>()
    const [content, setContent] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)

    const [user, setUser] = useState<UserDb>()

    const nameInputRef = useRef(null)
    const emailInputRef = useRef(null)
    const contentInputRef = useRef(null)

    useEffect(() => {
        if (props.message !== undefined) {
            setContent(props.message)
            setBorderColor(contentInputRef, defaultBorderColor)
        }
    }, [props.message])

    useEffect(() => {
        let pathname = window.location.pathname;
        let item = localStorage.getItem(accountExpiredLS);
        if (pathname === "/" || pathname === "" || item === "true") {
            return;
        }
        let username = localStorage.getItem("username");
        if (username === null) {
            return
        }
        services.UserService.getUser()
            .then(r => {
                let usr = r.Data;
                if (usr) {
                    setUser(usr)
                }
            })

    }, [])

    useEffect(() => {
        if (user?.email !== undefined) {
            setEmail(user.email)
        }
        if (user?.phone !== undefined) {
            setPhone(user.phone)
        }
    }, [user])


    async function sendEmail() {
        let pass = true;
        if (email === undefined) {
            pass = false;
            setBorderColor(emailInputRef, red[500])
        }
        if (content === undefined) {
            pass = false
            setBorderColor(contentInputRef, red[500])
        }
        let thisName = name
        if (thisName === undefined) {
            if (user === undefined) {
                pass = false
                setBorderColor(nameInputRef, red[500])
            }
        }
        if (user !== undefined) {
            thisName = "Client: id=" + user.id
        }

        if (!pass) {
            return
        }
        setLoading(true)
        let response = await services.ContactService
            .sendEmailFromCustomer(thisName!, email!, phone, content!);
        setLoading(false)
        if (response.Error !== undefined) {
            let message1 = response.Message;
            message.error(message1)
            return
        }
        message.success("Mesajul a fost trimis")
        if (user === undefined) {
            setPhone(undefined)
            setEmail(undefined)
        }
        setName(undefined)
        setContent(undefined)
    }

    function setBorderColor(inputRef: any, color: string) {
        //@ts-ignore
        let current = inputRef.current
        let input
        //@ts-ignore
        if (current.input !== undefined) {
            //@ts-ignore
            input = current.input

        } else
            //@ts-ignore
        if (current.resizableTextArea !== undefined && current.resizableTextArea.textArea !== undefined) {
            //@ts-ignore
            input = current.resizableTextArea.textArea
        }
        let styles = input.style as CSSStyleDeclaration;
        styles.borderColor = color;
    }

    return (
        <div id={"contact"}
        >
            <div className={"shadow bg-white border py-4 ps-4 message-input"}
            >
                <div className={"h3 m-0 mb-3"}>
                    <b>Contactați-ne!</b>
                </div>
                <Form className={"row"}
                      style={{rowGap: "17px"}}
                      onFinish={sendEmail}
                >
                    <Form.Item className={"m-0 col-lg-12"} hidden={user !== undefined}>
                        <Input placeholder={"Numele și prenumele*"}
                               size={"large"}
                               ref={nameInputRef}
                               type={"text"}
                               name={"name"}
                               className={"bg-whitesmoke m-0"}
                               style={{marginBottom: "15px"}}
                               value={name}
                               onChange={(e) => {
                                   setName(e.currentTarget.value)
                                   setBorderColor(nameInputRef, defaultBorderColor)
                               }}
                        />
                    </Form.Item>
                    <Form.Item className={"m-0 col-lg-6"}>
                        <Input placeholder={"Email*"}
                               className={"bg-whitesmoke"}
                               ref={emailInputRef}
                               size={"large"}
                               type={"email"}
                               name={"email"}
                               value={email}
                               onChange={(e) => {
                                   setEmail(e.currentTarget.value)
                                   setBorderColor(emailInputRef, defaultBorderColor)
                               }}
                        />
                    </Form.Item>
                    <Form.Item className={"m-0 col-lg-6"}>
                        <Input placeholder={"Telefon"}
                               className={"bg-whitesmoke"}
                               size={"large"}
                               type={"tel"}
                               name={"tel"}
                               value={phone}
                               onChange={(e) => setPhone(e.currentTarget.value)}
                        />
                    </Form.Item>
                    <Form.Item className={"m-0 col-lg-12"}>
                        <TextArea size={"large"}
                                  placeholder={"Mesajul dumneavoastră*"}
                                  ref={contentInputRef}
                                  className={"bg-whitesmoke"}
                                  rows={4}
                                  value={content}
                                  onChange={(e) => {
                                      setContent(e.currentTarget.value)
                                      setBorderColor(contentInputRef, defaultBorderColor)
                                  }}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button size={"large"}
                                className={"w-100"}
                                type={"primary"}
                                loading={loading}
                                htmlType={"submit"}
                        >
                            Trimite mesajul
                        </Button>
                    </Form.Item>
                </Form>
            </div>

            <div className={"contact-info-div"}>
                <div style={{backgroundColor: `${blue[800]}`,}}
                     className={"rounded shadow p-4 contact-inner-div"}
                >
                    <div style={{color: "white", fontSize: "1.2rem"}}>
                        Date de contact:
                    </div>
                    <div className={"my-3"} style={{color: "white"}}
                    >
                        Pentru orice informații nu ezitați să ne contactați! În intervalul orelor de program de lucru,
                        vă răspundem în cel mai scurt timp posibil.
                    </div>
                    <div style={{color: "white"}}>
                        <i>Email: suport-clienti@menuorders.ro</i>
                    </div>
                    <div style={{color: "white"}}>
                        <i>Tel: 0750 762 765</i>
                    </div>
                    <div className={"mt-4"} style={{color: "white"}}>
                        Program de lucru:<br/>
                        <b>Luni-Vineri 9:00-17:00</b>
                    </div>
                    <div className={"d-flex"} style={{gap: "10px"}}>
                        <div className={"rounded rounded-circle border mt-3"}
                             style={{width: "20px", height: "20px", backgroundColor: "white"}}
                        ></div>
                        <div className={"rounded rounded-circle border mt-3"}
                             style={{width: "20px", height: "20px", backgroundColor: "white"}}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    )
}