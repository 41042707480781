export function computeMediaFileContent(data: ArrayBuffer | string, mediaType: string): string {
    if (data === undefined) {
        return ""
    }
    let base64String: string = ""
    if (data instanceof ArrayBuffer) {
        base64String = arrayBufferToString(data)
    } else {
        base64String = data
    }

    if (base64String.startsWith("data:")) {
        return base64String
    }

    if (mediaType.startsWith("image")) {
        return "data:image/*;base64," + base64String;
    }
    if (mediaType.startsWith("video")) {
        return "data:video/mp4;base64," + base64String;
    }
    return "data:" + mediaType + ";base64," + base64String
}

export function arrayBufferToString(data: ArrayBuffer): string {
    if (typeof data === "string") {
        return data
    }
    const uint8Array = new Uint8Array(data);
    let str = "";
    for (let i = 0; i < uint8Array.length; i++) {
        str += String.fromCharCode(uint8Array[i]);
    }
    return btoa(str);
}