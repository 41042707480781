import React from "react";
import {TableItem} from "./tableItem";


export interface ColumnStructure {
    title?: string,
    dataIndex?: string,
    editable?: boolean,
    sorter?: (a: any, b: any) => number,
    render?: (_: any, record: TableItem) => JSX.Element
}

export function computePrimeProductsTableColumns(
    isEditing: (record: TableItem) => boolean,
    save: (key: React.Key) => Promise<void>,
    cancel: () => void,
    enabledEdit: boolean,
    edit: (record: Partial<TableItem> & { key: React.Key }) => void
): ColumnStructure[] {
    return [
        {
            title: 'Denumire',
            dataIndex: 'name',
            editable: false,
            sorter: (a: any, b: any) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
        },
        {
            title: 'UM',
            dataIndex: 'quantityType',
            editable: false,
        },
        {
            title: 'Pret',
            dataIndex: 'price',
            editable: false,
            sorter: (a: any, b: any) => a.price - b.price,
        },
        {
            title: 'Cantitate',
            dataIndex: 'quantity',
            editable: true,
            sorter: (a: any, b: any) => a.quantity - b.quantity,
        },
        {
            title: 'Pret total',
            dataIndex: 'totalPrice',
            editable: false,
            sorter: (a: any, b: any) => a.quantity * a.price - b.quantity * b.price,
        }
    ];
}