import {Tag} from "antd";

export const FeatureCard = (props: {
    icon: React.ReactNode,
    title: string,
    features: string[],
}): JSX.Element => {


    return (
        <div className="feature is-revealing" style={{position: "relative", zIndex: "1"}}>
            <div className="feature-inner">
                <div className="feature-icon">
                    {props.icon}
                </div>
                <h4 className="feature-title h3-mobile text-center">{props.title}</h4>
                <div className="text-sm">
                    <ul>
                        {
                            props.features.map((feature, index) => (
                                items.includes(feature) ? (
                                        <li key={index} style={{textDecoration: "line-through"}}>{feature} <Tag
                                            color="#87d068">în curând</Tag></li>
                                    ) :
                                    (
                                        <li key={index}>{feature}</li>
                                    )
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

const items: string[] = ["trimitere e-mail-uri direct din sistem", "avize", "facturi", "rapoarte", "e-factura"]