import {Button, Empty, InputNumber, Select} from "antd";
import {ServiceContext, ServiceContextInstance} from "../../../../Core/serviceContext";
import {useContext, useEffect, useState} from "react";
import {IFetchResult} from "../../../../Hooks/useFetch.types";
import {useFetch} from "../../../../Hooks/useFetch";
import {Menu} from "../../../../Models/backend/Menu";
import {CreateMenuOrderProcess} from "./createMenuOrderProcess";
//@ts-ignore
import icon from '../../../../Assets/Images/bar.png';

export const CreateMenuOrder = (): JSX.Element => {

    const services: ServiceContext = useContext<ServiceContext>(ServiceContextInstance);

    const menusData: IFetchResult<Menu[]> = useFetch<Menu[]>(() => services.MenuService.getAll(), []);
    const [menus, setMenus] = useState<Menu[]>([])
    const [selectedMenuId, setSelectedMenuId] = useState<string>()
    const [people, setPeople] = useState<number>()
    const [create, setCreate] = useState<number>()

    useEffect(() => {
        if (menusData.isLoading) {
            return;
        }
        if (menusData.errors !== '' ||
            menusData.data === null ||
            menusData.data?.Error !== undefined ||
            menusData.data?.Data === undefined) {
            return;
        }
        let data = menusData.data.Data;
        setMenus(data)
    }, [menusData])

    return (
        <div id={"create-menu-order-div"} className={"pb-5"}>
            <div className={"d-flex align-items-center mb-3"}>
                <Select options={menus.map(p => {
                    return {
                        label: p.name,
                        value: p.id
                    }
                })}
                        className={"me-2"}
                        placeholder={"Selecteaza meniul"}
                        style={{width: "500px"}}
                        onChange={(value, option) => {
                            setSelectedMenuId(value)
                        }}
                />
                <InputNumber type={"number"}
                             min={0}
                             placeholder={"Numar de invitati"}
                             style={{width: "140px"}}
                             className={"me-2"}
                             value={people}
                             onChange={(value) => {
                                 setPeople(value == null ? undefined : value)
                             }}
                />
                <Button onClick={() => setCreate(1 - (create ?? 0))}
                        disabled={selectedMenuId === undefined || people === undefined}
                >
                    Creează comanda
                </Button>
            </div>
            {
                (create !== undefined) ? (
                    <CreateMenuOrderProcess menuId={selectedMenuId!}
                                            people={people!}
                                            dependency={[create.toString()]}
                    />
                ) : (
                    <Empty className={"mt-5"}
                           image={icon}
                           style={{marginRight: "auto", marginLeft: "auto"}}
                           description={"Selecteaza meniul si numarul de participanti"}
                    />
                )
            }
        </div>
    )
}