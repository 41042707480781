import React, {useEffect, useState} from "react";
import {twoDecimals} from "../../../../../Library/utils/mathUtil";
import {ReceiptProduct} from "../../../../../Models/model/ReceiptProduct";
import {InputNumber} from "antd";

export interface ExcelTableRowProps {
    product: ReceiptProduct;
    index: number;
    commercialExcess?: number;
    handleReceiptProductChange: (receiptProduct: ReceiptProduct) => void;
    setHasChanged: (hasChanged: boolean) => void;
}

export const ExcelTableRow = (props: ExcelTableRowProps): JSX.Element => {

    const [row, setRow] = useState<ReceiptProduct>(extracted());

    function extracted(): ReceiptProduct {
        let product = props.product;
        return {
            field0: props.index + 1,
            field1: product.field1,
            field2: product.field2,
            field3: twoDecimals(product.field3),
            field4: twoDecimals(product.field4),
            field5: twoDecimals(product.field5),
            field6: twoDecimals(product.field4! * (product.field5 || 0)),
            field7: 0,
            field8: 0,
            field9: 0,
            field10: 0,
            field11: 0,
            field12: 0,
            field13: 0,
            field14: 0,
            field15: 0
        };
    }

    useEffect(() => {
        setRow(extracted())
    }, [props.commercialExcess, props.index])

    useEffect(() => {
        props.handleReceiptProductChange({...row})
    }, [row])

    useEffect(() => {
        setRow({
            ...row,
            field6: twoDecimals(row.field4 * (row.field5 || 0))
        })
    }, [row.field4])

    return (
        <tr className="row13">
            <td className="column0 style2 n">{row.field0}</td>
            <td className="column1 style29 null style31"
                colSpan={3}>
                {row.field1}
            </td>
            <td className="column4 style3 null">
                {row.field2}
            </td>
            <td className="column5 style4 null">{row.field3}</td>
            <td className="column6 style4 null">
                <InputNumber value={row.field4}
                             min={0}
                             onChange={(e) => {
                                 setRow({
                                     ...row,
                                     field4: twoDecimals(e!)
                                 })
                                 props.setHasChanged(true)
                             }}
                />
            </td>
            <td className="column7 style5 null">{row.field5}</td>
            <td className="column8 style5 null">{row.field6}</td>
            <td className="column9 style5 null">
                <InputNumber value={row.field7}
                             min={0}
                             onChange={(e) => {
                                 setRow({
                                     ...row,
                                     field7: twoDecimals(e!)
                                 })
                                 props.setHasChanged(true)
                             }}
                />
            </td>
            <td className="column10 style5 null"></td>
            <td className="column11 style7 null"></td>
        </tr>
    )
}